import React, { memo } from "react";
import classNames from "classnames";

import TableComponent from "components/partials/table-component/table-component";

import { useFilesThunk } from "state/files/files.thunk";

import { uploadedFilesTableSettings } from "pages/list-plus/batch/uploaded-files/uploaded-files-table/uploaded-files-table-settings";

import { renderConfirmationModal } from "helpers/render-modal";

import Icons from "assets/icons";

const UploadedFilesTable = ({
  uploadedFiles,
  batchListSources,
  segmentGroup,
  isReadOnlyView,
  totalRecords,
  pageSize,
  pageIndex,
  setPageIndex,
}) => {
  const { removeFile } = useFilesThunk();

  const onFileRemove = (file) => {
    renderConfirmationModal({
      headerText: `Are you sure you want to remove ${file.name}?`,
      text: "This action will permanently remove the file from the batch's files list.",
      onConfirmCallback: () => {
        removeFile(file);
      },
      onConfirmButtonLabel: "Remove file",
      confirmButtonVariant: "danger",
      confirmButtonIcon: (
        <Icons.Delete
          customClass="text-white"
          aria-label="Delete file"
          height="100%"
          width="25"
        />
      ),
    });
  };
  const isPaginationShown = totalRecords > 50;

  return (
    <div
      className={classNames("flex flex-1 flex-col gap-x-12", {
        "mt-11": !isPaginationShown,
      })}
    >
      <TableComponent
        columns={uploadedFilesTableSettings(
          batchListSources,
          segmentGroup,
          onFileRemove,
          isReadOnlyView
        )}
        customHeight={isPaginationShown && "h-full"}
        data={uploadedFiles}
        viewByAmountSelectIsOn={false}
        pageIndex={pageIndex}
        pageSize={pageSize}
        totalRecords={totalRecords}
        paginationOnTheBottom={true}
        setPagination={setPageIndex}
        pagination={isPaginationShown}
      />
    </div>
  );
};

export default memo(UploadedFilesTable);
