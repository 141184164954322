import React from "react";

const Heading = React.forwardRef(
  ({ variant, customClass = "", children }, ref) => {
    const headingClass = `text-blue-03 font-family ${customClass}`;

    switch (variant) {
      case "h1":
        return (
          <h1 ref={ref} className={`${headingClass} text-2xl`}>
            {children}
          </h1>
        );
      case "h2":
        return (
          <h2 ref={ref} className={`${headingClass} text-xl`}>
            {children}
          </h2>
        );
      case "h3":
        return (
          <h3 ref={ref} className={`${headingClass} text-base`}>
            {children}
          </h3>
        );
      default:
        return (
          <h3 ref={ref} className={`${headingClass} text-base`}>
            {children}
          </h3>
        );
    }
  }
);

export default Heading;
