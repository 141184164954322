import appRoles from "config/appRoles";
import React, { createContext, useState, useEffect, useContext } from "react";
import apiRoot from "../api/apiRoot";
import { AuthContext } from "./AuthContext";
import { ClientContext } from "./ClientContext";
import { toast } from "react-toastify";

export const FileMasksContext = createContext();

export const FileMaskStore = ({ children }) => {
  const [fileMasks, setfileMasks] = useState([]);
  const { currentClientId, currentClientData } = useContext(ClientContext);
  const { user, isAuthorized } = useContext(AuthContext);
  const [isDeleting, setIsDeleting] = useState(false);

  useEffect(() => {
    if (currentClientId && currentClientData && isAuthorized(appRoles.IsFileSetupReader)) {
      fetchFileMasks();
    }
  }, [currentClientId, currentClientData]);

  const fetchFileMasks = async () => {
    apiRoot.get(`/filesetup/filemasks?clientId=${currentClientId}`).then((res) => {
      setfileMasks(
        res.data.map((item) => {
          return { blobContainer: currentClientData.blobContainer, schoolKey: currentClientData.schoolKey, ...item };
        })
      );
    });
  };

  const handleFileMaskCreate = async (selectedFPT, selectedFPST, selectedMappingVersion) => {
    const newFileMask = {
      fileMask: "%REPLACE_ME%",
      delimiter: ",",
      extension: "csv",
      fileProcessType: selectedFPT,
      fileProcessSubType: selectedFPST,
      expectedFileCount: 1,
      mappingId: selectedMappingVersion,
    };

    const toastMessages = {
      pending: `Creating New File Mask`,
      success: `SUCCESS - New File Mask Created`,
      error: "FAILED - Please Reach out to Support for Assistance",
    };

    const promise = apiRoot.post(`/filesetup/filemasks/create`, {
      clientId: currentClientId,
      userId: user.id,
      ...newFileMask,
    });
    const res = await toast.promise(promise, toastMessages);

    setfileMasks(
      res.data.map((item) => {
        return { blobContainer: currentClientData.blobContainer, schoolKey: currentClientData.schoolKey, ...item };
      })
    );
  };

  const handleRemoveMask = () => {
    setIsDeleting(!isDeleting);
  };

  const handleFileMaskChange = async (row, v, header) => {
    let updatedFileMask;
    const newMasks = fileMasks.map((fileMask) => {
      if (fileMask.id === row.id) {
        updatedFileMask = { ...fileMask, [header]: v };
        return updatedFileMask;
      } else {
        return fileMask;
      }
    });

    const toastMessages = {
      pending: `Updating File Mask`,
      success: `SUCCESS - File Mask Updated`,
      error: "FAILED - Please Reach out to Support for Assistance",
    };

    const promise = apiRoot.post(`/filesetup/filemasks/update/${row.id}`, {
      clientId: currentClientId,
      userId: user.id,
      ...updatedFileMask,
    });
    const res = await toast.promise(promise, toastMessages);

    setfileMasks(newMasks);
  };

  const handleFileMaskRemove = async (row) => {
    const newMasks = fileMasks.filter((fileMask) => {
      return fileMask.id !== row.id;
    });

    setfileMasks(newMasks);

    const toastMessages = {
      pending: `Removing File Mask`,
      success: `SUCCESS - File Mask Removed`,
      error: "FAILED - Please Reach out to Support for Assistance",
    };

    const promise = apiRoot.post(`/filesetup/filemasks/delete/${row.id}`);
    const res = await toast.promise(promise, toastMessages);

    setfileMasks(
      res.data.map((item) => {
        return { blobContainer: currentClientData.blobContainer, schoolKey: currentClientData.schoolKey, ...item };
      })
    );

    setIsDeleting(false);
  };
  const value = {
    handleFileMaskCreate: handleFileMaskCreate,
    fileMasks: fileMasks,
    handleFileMaskChange: handleFileMaskChange,
    handleFileMaskRemove: handleFileMaskRemove,
    isDeleting: isDeleting,
    setIsDeleting: setIsDeleting,
    handleRemoveMask: handleRemoveMask,
    fetchFileMasks: fetchFileMasks,
  };
  return <FileMasksContext.Provider value={value}>{children}</FileMasksContext.Provider>;
};
