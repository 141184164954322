import React, { forwardRef } from "react";

const CustomRadioInput = forwardRef(
  (
    {
      id,
      label,
      radioValue,
      valueForEdit,
      setAction,
      onChange,
      customStylesLabel = "",
      ...rest
    },
    ref
  ) => {
    return (
      <label
        htmlFor={id}
        className={`mb-3 mr-2 flex cursor-pointer items-center text-gray-04 md:mr-4 ${customStylesLabel}`}
      >
        <input
          className="peer mr-2 h-5 w-5 cursor-pointer accent-blue-03"
          onChange={() => {
            setAction(radioValue);
            onChange(radioValue);
          }}
          type="radio"
          value={radioValue}
          id={id}
          checked={rest.isChecked}
          ref={ref}
          {...rest}
        />
        <span className="text-sm text-gray-03 peer-checked:font-semibold md:text-base">
          {label}
        </span>
      </label>
    );
  }
);

export default CustomRadioInput;
