import uniqid from "uniqid";
import classNames from "classnames";

import { bytesToMb, fomatToOptions } from "helpers/fomat-data";

import UploadedFilesFormCell from "pages/list-plus/batch/uploaded-files/uploaded-files-table/uploaded-files-form-cell/uploaded-files-form-cell";

import Icons from "assets/icons";

const commonStyles = "border-r-2 border-white font-semibold";

export const uploadedFilesTableSettings = (
  batchListSources,
  segmentGroup,
  onRemove,
  isReadOnlyView
) =>
  [
    {
      id: "status",
      header: () => <span></span>,
      cell: () => (
        <div className="flex justify-center">
          <Icons.Success
            aria-label="Susccess saved changes status"
            customClass="w-5 h-full text-green-01"
          />
        </div>
      ),
      isCellCentered: true,
      headerClassName: `${commonStyles} rounded-tl-md rounded-bl-md`,
      className: "w-[4%]",
      enableSorting: false,
    },
    {
      id: "name",
      accessorKey: "name",
      header: () => <span>FILE NAME</span>,
      cell: (info) => <span>{info.renderValue()}</span>,
      headerClassName: commonStyles,
      className: "pl-4 w-[24%]",
      enableSorting: false,
    },
    {
      id: "select-cell",
      header: () => <span className="font-semibold">LIST SOURCE</span>,
      cell: ({ row }) => {
        const options = fomatToOptions(batchListSources);

        const readOnlyItem = options.find(
          (option) => option.value === row.original.listSource
        )?.label;

        return isReadOnlyView ? (
          <span className="pl-4">{readOnlyItem}</span>
        ) : (
          <UploadedFilesFormCell
            cellName="list-source"
            options={options}
            row={row}
          />
        );
      },
      isCellCentered: !isReadOnlyView,
      headerClassName: `${commonStyles} pl-4`,
      className: "w-[20%]",
      enableSorting: false,
    },
    {
      id: "input-cell",
      header: () => <span className="font-semibold">CODE</span>,
      cell: ({ row }) =>
        isReadOnlyView ? (
          row?.original?.code || "-"
        ) : (
          <UploadedFilesFormCell cellName="code" row={row} />
        ),
      headerClassName: commonStyles,
      className: "pl-4 w-[7%]",
      enableSorting: false,
    },
    {
      id: "select-cell",
      header: () => <span className="font-semibold">SEGMENT GROUP</span>,
      cell: ({ row }) => {
        let options = fomatToOptions(segmentGroup);

        options.unshift({
          id: "unselected...",
          isChecked: false,
          key: uniqid(),
          label: "Select...",
          radioValue: "",
          value: "",
        });

        const readOnlyItem = options.find(
          (option) => option.value === row.original.segmentGroup
        )?.label;

        return isReadOnlyView ? (
          <span className="pl-4">{readOnlyItem || "-"}</span>
        ) : (
          <UploadedFilesFormCell
            cellName="segment-group"
            options={options}
            row={row}
          />
        );
      },
      isCellCentered: !isReadOnlyView,
      headerClassName: `${commonStyles} pl-4`,
      className: "w-[20%]",
      enableSorting: false,
    },
    {
      id: "fileSize",
      accessorKey: "fileSize",
      header: () => <span>FILE SIZE</span>,
      cell: (info) => bytesToMb(info?.row?.original?.fileSize),
      isHeaderCentered: true,
      isCellCentered: true,
      headerClassName: commonStyles,
      className: "w-[7%]",
      enableSorting: false,
    },
    {
      id: "records",
      accessorKey: "records",
      header: () => <span>RECORDS</span>,
      cell: (info) => info?.row?.original?.records || "-",
      isHeaderCentered: true,
      isCellCentered: true,
      headerClassName: classNames(commonStyles, {
        "rounded-tr-md rounded-br-md": isReadOnlyView,
      }),
      className: "py-4 w-[7%]",
      enableSorting: false,
    },
    !isReadOnlyView && {
      id: "button-cell",
      header: () => <span className="font-semibold">ACTIONS</span>,
      cell: (info) => {
        return (
          <Icons.Delete
            customClass="text-blue-03 mr-2 cursor-pointer"
            onClick={() => onRemove(info?.row?.original)}
          />
        );
      },
      isCellCentered: true,
      isHeaderCentered: true,
      headerClassName: `${commonStyles} rounded-tr-md rounded-br-md`,
      className: "w-[7%]",
      enableSorting: false,
    },
  ].filter((item) => item);
