import React, { useContext, useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import AssignAnalyst from "components/AssignAnalyst";
import AssignBlobContainer from "components/AssignBlobContainer";
import { ClientContext } from "context/ClientContext";
import { AuthContext } from "context/AuthContext";
import apiRoot from "api/apiRoot";
import RadioGroup from "components/partials/RadioGroup";
import ToggleSwitch from "components/partials/ToggleSwitch";
import TagDisplay from "components/partials/TagDisplay";
import Icons from "assets/icons";
import FtpFolderName from "./ftp-folder-name";

const SchoolSettings = () => {
  const { currentClientData, currentClientId } = useContext(ClientContext);
  const { user, setUser } = useContext(AuthContext);
  const [analyst, setAnalyst] = useState("");
  const [isBookmarked, setIsBookedmarked] = useState(false);
  const [productOfferings, setProductOfferings] = useState([]);
  const [newProductOfferings, setNewProductOfferings] = useState([]);
  const [editProductOfferings, setEditProductOfferings] = useState(false);

  useEffect(() => {
    apiRoot
      .get(`/client/bookmarked?userId=${user.id}&clientId=${currentClientId}`)
      .then((res) => {
        setIsBookedmarked(res.data);
      });

    apiRoot.get(`/client/tags?clientId=${currentClientId}`).then((res) => {
      setProductOfferings(res.data);
      setNewProductOfferings(res.data);
    });
    /*
    This is a little weird but there is a timing delay with when then data is loaded vs when this useEffect fires off on page refresh. 
    The Analyst gets updated in the below useeffect which will cause these to update to the correct state. Probably a better way to do this
   */
    // eslint-disable-next-line
  }, [analyst]);

  useEffect(() => {
    if (currentClientData?.analyst) {
      apiRoot.get(`/user?userId=${currentClientData.analyst}`).then((res) => {
        setAnalyst(res?.data?.name);
      });
    }
  }, [currentClientData?.analyst]);

  const handleBookmarkClick = async (toggleOption) => {
    let toastMessages = {};

    if (!toggleOption) {
      toastMessages = {
        pending:
          "Removing " +
          currentClientData.institutionName +
          " from bookmarks...",
        success:
          "Success - " +
          currentClientData.institutionName +
          " has been removed",
        error:
          "Failed to remove bookmark for " + currentClientData.institutionName,
      };
    } else {
      toastMessages = {
        pending: "Bookmarking " + currentClientData.institutionName + "...",
        success:
          "Success! " +
          currentClientData.institutionName +
          " is now bookmarked to the homepage!",
        error:
          "Failed to bookmark " +
          currentClientData.institutionName +
          " to the homepage!",
      };
    }

    const promise = apiRoot.post(
      `/user/bookmark?userId=${
        user.id
      }&clientId=${currentClientId}&remove=${!toggleOption}`
    );
    const res = await toast.promise(promise, toastMessages);
    setUser(res.data);
    setIsBookedmarked(toggleOption);
  };

  const onCheckboxClick = (item, e) => {
    const updatedProductOfferings = newProductOfferings.map(
      (productOffering) => {
        if (productOffering.id === item.id) {
          return {
            ...productOffering,
            isChecked: Math.abs(productOffering.isChecked - 1),
          };
        } else {
          return productOffering;
        }
      }
    );

    setNewProductOfferings(updatedProductOfferings);
  };

  const onProductOfferingsSave = async (cancel) => {
    setEditProductOfferings(false);
    if (!cancel) {
      setProductOfferings(newProductOfferings);
      apiRoot.post(
        `/client/tags?clientId=${currentClientId}`,
        newProductOfferings
      );
    } else {
      setNewProductOfferings(productOfferings);
    }
  };

  const renderProductOfferings = () => {
    if (editProductOfferings) {
      return (
        <div className="flex flex-col gap-2 ">
          <p className="font-semibold text-gray-05">Client Tags</p>
          <RadioGroup
            htmlFor="client-tags"
            options={newProductOfferings}
            onCheckboxClick={onCheckboxClick}
          />
          <div className="flex items-center justify-end gap-2">
            <button
              className="font-semibold text-blue-02"
              onClick={() => onProductOfferingsSave(true)}
            >
              Cancel
            </button>
            <button
              className="rounded-md bg-yellow-01 px-3 py-1 font-semibold text-blue-03"
              onClick={() => onProductOfferingsSave(false)}
            >
              Save
            </button>
          </div>
        </div>
      );
    } else {
      return (
        <div className="flex flex-col gap-2 ">
          <div className="flex gap-7">
            <p className="font-semibold text-gray-05">Client Tags</p>
            <div
              className="flex cursor-pointer items-center gap-1"
              onClick={() => setEditProductOfferings(true)}
            >
              <Icons.Edit />
              <div className="font-semibold text-blue-03">Edit</div>
            </div>
          </div>
          <TagDisplay
            options={productOfferings.filter((item) => item.isChecked === 1)}
          />
        </div>
      );
    }
  };

  return (
    <>
      <ToastContainer
        style={{ width: "500px", fontSize: "16px" }}
        progressStyle={{ backgroundColor: "#b6de3a" }}
      />
      <div className="flex w-64 flex-col gap-6">
        <div className="flex flex-col gap-1">
          <AssignAnalyst defaultValue={analyst} setAnalyst={setAnalyst} />
        </div>
        <div className="flex flex-col gap-1">
          {
            <AssignBlobContainer
              defaultValue={currentClientData?.blobContainer}
            />
          }
        </div>
        <FtpFolderName
          currentFtpFolderName={currentClientData?.ftpFolderName}
          currentClientId={currentClientId}
        />
        <div className="flex flex-col gap-1">
          <p className="font-semibold text-gray-05">SchoolKey</p>
          <p>{currentClientData?.schoolKey}</p>
        </div>
        <div className="flex flex-col gap-1">
          <p className="font-semibold text-gray-05">Homepage Display</p>
          <div className="flex gap-1">
            <ToggleSwitch
              label="Bookmark client"
              checked={isBookmarked}
              onChange={handleBookmarkClick}
            />
          </div>
        </div>
        {renderProductOfferings()}
      </div>
    </>
  );
};

export default SchoolSettings;
