import React from "react";
import Select from "react-select";

const SchoolSelectList = ({ onChange, options, defaultValue }) => {
  let newOptions = [];
  if (options) {
    newOptions = [...options, defaultValue];
  }

  // Styles for the search and select
  const customStyles = {
    input: (provided, state) => ({
      ...provided,
    }),
    option: (provided, state) => ({
      ...provided,
      borderBottom: "1px solid black",
      padding: 15,
    }),
    control: (provided, state) => ({
      ...provided,
      border: "1px solid black",
      // match with the menu
      borderRadius: state.isFocused ? "3px 3px 0 0" : 3,
      // Overwrittes the different states of border
      borderColor: "white",
      "&:hover": {
        // Overwrittes the different states of border
        borderColor: "white",
      },
    }),
    singleValue: (provided, state) => ({
      ...provided,
      opacity: 0.9,
      padding: 15,
    }),
  };

  function handleChange(selectedOption) {
    if (onChange) {
      onChange(selectedOption);
    }
  }

  return (
    <Select
      styles={customStyles}
      options={newOptions.map((item) => ({
        value: item,
        label: item,
      }))}
      onChange={(selectedOption) => handleChange(selectedOption.value)}
      value={{ value: defaultValue, label: defaultValue }}
    />
  );
};

export default SchoolSelectList;
