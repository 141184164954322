import React, { forwardRef } from "react";

const EmptySpaceContent = forwardRef(({ image, text, linkShow = null, linkText = null, onClick }, ref) => (
  <div className="font-large bg-gray-00 flex min-h-[650px] items-center justify-center rounded-md text-center">
    <div className="font-family-[Barlow] flex flex-col items-center font-semibold">
      {image}

      <span className="text-gray-04 mt-[50px]">{text}</span>

      {linkShow && (
        <div className="mt-6 flex cursor-pointer items-center" onClick={onClick}>
          <span className="text-indigo-02">{linkText}</span>
        </div>
      )}
    </div>
  </div>
));

export default EmptySpaceContent;
