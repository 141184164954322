import appRoles from "config/appRoles";
import React, { createContext, useState, useContext, useEffect } from "react";
import apiRoot from "../api/apiRoot";
import { AuthContext } from "./AuthContext";
import { ClientContext } from "./ClientContext";
import { toast } from "react-toastify";
import { FileMasksContext } from "./FileMasksContext";
import { InstitutionContext } from "./InstitutionContext";

export const FileMappingsContext = createContext();

export const FileMappingsStore = ({ children }) => {
  const { user, isAuthorized } = useContext(AuthContext);
  const { currentClientId } = useContext(ClientContext);
  const { fetchFileMasks } = useContext(FileMasksContext);
  const { fetchQueuedConfigs } = useContext(InstitutionContext);
  const [clientMappings, setClientMappings] = useState([]);
  const [mappingVersionId, setMappingVersionId] = useState();
  const [parentConfigId, setParentConfigId] = useState();
  const [isEditing, setIsEditing] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [jsonMapping, setJsonMapping] = useState({});
  const [newJsonMapping, setNewJsonMapping] = useState({});
  const [isPublished, setIsPublished] = useState(false);
  const [currentFPT, setCurrentFPT] = useState();
  const [currentFPST, setCurrentFPST] = useState();
  const [standardizedMappingHeaders, setStandardizedMappingHeaders] = useState([]);

  useEffect(() => {
    if (currentClientId && isAuthorized(appRoles.IsFileSetupReader)) {
      fetchMappings();
    }
  }, [currentClientId]);

  useEffect(() => {
    if (clientMappings.length === 0) {
      fetchMappings();
    }
  }, [parentConfigId]);

  useEffect(() => {
    if (mappingVersionId && isAuthorized(appRoles.IsFileSetupReader)) {
      fetchMappingVersion();
    }
  }, [mappingVersionId]);

  useEffect(() => {
    const parentMappingObj = clientMappings.find((item) => {
      return item.id === parentConfigId;
    });
    if (parentMappingObj) {
      setCurrentFPT(parentMappingObj.fileProcessType);
      setCurrentFPST(parentMappingObj.fileProcessSubType);
    }
  }, [clientMappings, parentConfigId, mappingVersionId]);

  useEffect(() => {
    if (currentFPT) {
      fetchAvailableStandardizedMappings();
    }
  }, [currentFPT, currentFPST]);

  const fetchMappings = async () => {
    apiRoot.get(`/filesetup/filemappings?clientId=${currentClientId}`).then((res) => {
      setClientMappings(res.data);
    });
  };

  const fetchMappingVersion = async () => {
    const toastMessages = {
      pending: `Fetching Mapping Version`,
      success: `SUCCESS - Mapping Version Found`,
      error: "FAILED - Please Reach out to Support for Assistance",
    };
    const promise = apiRoot.get(`/filesetup/filemappings/version/${mappingVersionId}`);
    const res = await toast.promise(promise, toastMessages);
    setIsEditable(!["PUBLISHED", "ARCHIVED", "QUEUED"].includes(res.data.status));
    setIsPublished(res.data.status === "PUBLISHED");
    setJsonMapping(JSON.parse(res.data.jsonObject));
    setNewJsonMapping(JSON.parse(res.data.jsonObject));
    setParentConfigId(res.data.parentConfigId);
  };

  const fetchAvailableStandardizedMappings = async () => {
    apiRoot.get(`/filesetup/filemappings/availablemappings?fileProcessType=${currentFPT}&fileProcessSubType=${currentFPST}`).then((res) => {
      setStandardizedMappingHeaders(res.data);
    });
  };

  const handleMappingAdd = () => {
    const notUsedMappings = standardizedMappingHeaders.filter((item) => {
      return !Object.keys(jsonMapping).includes(item);
    });
    if (notUsedMappings.length === 0) {
      alert("No New Mappings to Add");
    } else {
      setJsonMapping({ ...{ [notUsedMappings[0]]: "CLIENT_COLUMN_NAME" }, ...jsonMapping });
    }
  };

  const handleMappingCancel = () => {
    setJsonMapping(newJsonMapping);
    setIsEditing(false);
  };

  const handleMappingSave = async () => {
    setIsEditing(false);
    const toastMessages = {
      pending: `Saving Mapping`,
      success: `SUCCESS - Mapping Saved`,
      error: "FAILED - Please Reach out to Support for Assistance",
    };
    const promise = apiRoot.post(`/filesetup/filemappings/version/${mappingVersionId}/${user.id}`, jsonMapping);
    const res = await toast.promise(promise, toastMessages);

    setJsonMapping(JSON.parse(res.data.jsonObject));
    setNewJsonMapping(JSON.parse(res.data.jsonObject));
  };

  const handleMappingPublish = async () => {
    const toastMessages = {
      pending: `Publishing Mapping`,
      success: `SUCCESS - Mapping Published`,
      error: "FAILED - Please Reach out to Support for Assistance",
    };
    const promise = apiRoot.post(`/filesetup/filemappings/version/${mappingVersionId}/publish/${user.id}`);
    const res = await toast.promise(promise, toastMessages);

    setJsonMapping(JSON.parse(res.data.jsonObject));
    setNewJsonMapping(JSON.parse(res.data.jsonObject));
    setIsEditable(false);
    setIsPublished(res.data.status === "PUBLISHED");
    fetchFileMasks();
    fetchQueuedConfigs();
  };

  const handleMappingPublishQueue = async () => {
    const toastMessages = {
      pending: `Adding Mapping to Approval Queue`,
      success: `SUCCESS - Mapping Added to Approval Queue`,
      error: "FAILED - Please Reach out to Support for Assistance",
    };
    const promise = apiRoot.post(`filesetup/filemappings/version/${mappingVersionId}/queue/${user.id}`);
    const res = await toast.promise(promise, toastMessages);

    setJsonMapping(JSON.parse(res.data.jsonObject));
    setNewJsonMapping(JSON.parse(res.data.jsonObject));
    setIsEditable(false);
    setIsPublished(res.data.status === "PUBLISHED");
  };

  const handleMappingDelete = () => {
    setIsDeleting(!isDeleting);
  };

  const handleRemoveMapping = (key) => {
    const updatedJsonMappings = { ...jsonMapping };
    delete updatedJsonMappings[key];
    setJsonMapping(updatedJsonMappings);
    setIsDeleting(false);
  };

  const handleMappingChange = (key, value, header) => {
    const updatedMappings = { ...jsonMapping };
    if (header === "key" && value !== key) {
      updatedMappings[value] = updatedMappings[key];
      delete updatedMappings[key];
    } else if (header === "value") {
      updatedMappings[key] = value;
    }
    setJsonMapping(updatedMappings);
  };
  const handleMappingEdit = () => {
    setIsEditing(true);
  };

  const value = {
    clientMappings: clientMappings,
    jsonMapping: jsonMapping,
    isEditable: isEditable,
    isEditing: isEditing,
    isDeleting: isDeleting,
    mappingVersionId: mappingVersionId,
    isPublished: isPublished,
    setIsEditable: setIsEditable,
    setNewJsonMapping: setNewJsonMapping,
    setJsonMapping: setJsonMapping,
    handleMappingAdd: handleMappingAdd,
    handleMappingEdit: handleMappingEdit,
    handleMappingDelete: handleMappingDelete,
    handleMappingChange: handleMappingChange,
    handleMappingSave: handleMappingSave,
    handleMappingCancel: handleMappingCancel,
    handleRemoveMapping: handleRemoveMapping,
    handleMappingPublish: handleMappingPublish,
    handleMappingPublishQueue: handleMappingPublishQueue,
    setMappingVersionId: setMappingVersionId,
    fetchMappings: fetchMappings,
    currentFPT: currentFPT,
    setCurrentFPT: setCurrentFPT,
    currentFPST: currentFPST,
    setCurrentFPST: setCurrentFPST,
    setIsEditing: setIsEditing,
    standardizedMappingHeaders: standardizedMappingHeaders,
  };
  return <FileMappingsContext.Provider value={value}>{children}</FileMappingsContext.Provider>;
};
