import { BATCHES_STATUSES } from "pages/list-plus/batch/batch.constants";

import Icons from "assets/icons";

export const getActionMenuSettings = (status) => {
  switch (status) {
    case BATCHES_STATUSES.CONFIGURING:
      return {
        btnName: "Process Batch",
        icon: <Icons.ProcessBatch customClass="mr-1" />,
        statusToSend: BATCHES_STATUSES.PROCESSING,
      };
    case BATCHES_STATUSES.RECONFIGURING:
      return {
        btnName: "Re-process Batch",
        icon: <Icons.ProcessBatch customClass="mr-1" />,
        statusToSend: BATCHES_STATUSES.PROCESSING,
      };
    case BATCHES_STATUSES.PROCESSING:
      return {
        btnName: "Publish to Client",
        icon: <Icons.PublishToClient customClass="mr-1" />,
        isActionBtnDisabled: true,
        statusToSend: BATCHES_STATUSES.PUBLISHING,
      };
    case BATCHES_STATUSES.READY_FOR_QA:
      return {
        btnName: "Publish to Client",
        icon: <Icons.PublishToClient customClass="mr-1" />,
        isActionBtnDisabled: false,
        statusToSend: BATCHES_STATUSES.PUBLISHING,
      };
    case BATCHES_STATUSES.PROCESSING_FAILED:
    case BATCHES_STATUSES.PUBLISHING:
    case BATCHES_STATUSES.PUBLISHING_FAILED:
    default:
      return {
        btnName: "",
        icon: null,
        statusToSend: BATCHES_STATUSES.PUBLISHING,
      };
  }
};

export const getIsActionMenuShown = (status) => {
  return ![
    BATCHES_STATUSES.PUBLISHING,
    BATCHES_STATUSES.PUBLISHED,
    BATCHES_STATUSES.PUBLISHING_FAILED,
  ].includes(status);
};

export const getIsReconfiguringAvailable = (status) => {
  return ![
    BATCHES_STATUSES.CONFIGURING,
    BATCHES_STATUSES.RECONFIGURING,
  ].includes(status);
};

export const getIsActionMenuDisabled = ({
  uploadedFiles,
  batchData,
  actionMenuSettings,
  tableHasUnsavedChanges,
}) => {
  if (batchData.status === BATCHES_STATUSES.CONFIGURING) {
    return tableHasUnsavedChanges;
  } else {
    return actionMenuSettings.isActionBtnDisabled;
  }
};

export const getIsBatchProcessingOrPublishing = (status) => {
  return [BATCHES_STATUSES.PROCESSING, BATCHES_STATUSES.PUBLISHING].includes(
    status
  );
};

export const getIsFilesTableShown = (status) => {
  return [
    BATCHES_STATUSES.READY_FOR_QA,
    BATCHES_STATUSES.PUBLISHING,
    BATCHES_STATUSES.PUBLISHING_FAILED,
    BATCHES_STATUSES.PUBLISHED,
  ].includes(status);
};
