import React, { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { ToastContainer } from "react-toastify";

import Heading from "components/partials/heading/heading";
import Loader from "components/partials/loader/loader";
import ActionMenu from "components/partials/action-menu/action-menu";
import BatchDetails from "pages/list-plus/batch/batch-details/batch-details";
import UploadedFiles from "pages/list-plus/batch/uploaded-files/uploaded-files";
import ProcessedFiles from "pages/list-plus/batch/processed-files/processed-files";

import store from "state/store";

import { useBatchThunk } from "state/batch/batch.thunk";

import { setBatchData } from "state/batch/batch.slice";
import {
  setIsUploadAreaShown,
  setTableHasUnsavedChanges,
} from "state/files/files.slice";

import { getBatchDetailsActionMenuItems } from "pages/list-plus/batch/batch-details/batch-action-menu-items/batch-action-menu-items";
import {
  getActionMenuSettings,
  getIsBatchProcessingOrPublishing,
  getIsActionMenuShown,
  getIsActionMenuDisabled,
  getIsFilesTableShown,
} from "pages/list-plus/batch/batch.helper";
import { formatDate } from "helpers/dates-formatter";

const Batch = () => {
  const { batchId, clientid } = useParams();
  const navigate = useNavigate();

  const { isBatchLoading, batchData, batchListSources } = useSelector(
    (state) => state?.batch
  );
  const { isActionAppliedOnFile, tableHasUnsavedChanges, uploadedFiles } =
    useSelector((state) => state?.files);

  const interval = useRef();

  const { getBatchDetails, getSummary, processOrPublishBatch, removeBatch } =
    useBatchThunk();

  useEffect(() => {
    const shouldSummaryUpdate =
      !!batchListSources.length && !!Object.keys(batchData).length;

    shouldSummaryUpdate && getSummary();

    // eslint-disable-next-line
  }, [batchData, isActionAppliedOnFile]);

  useEffect(() => {
    if (getIsBatchProcessingOrPublishing(batchData.status)) {
      interval.current = setInterval(() => {
        getBatchDetails(batchId, clientid);
      }, 60000);
    } else {
      clearInterval(interval.current);
    }
    // eslint-disable-next-line
  }, [batchData.status]);

  useEffect(() => {
    getBatchDetails(batchId, clientid);

    //reset data not to cache it
    return () => {
      store.dispatch(setBatchData({}));
      store.dispatch(setIsUploadAreaShown(false));
      store.dispatch(setTableHasUnsavedChanges(false));
      clearInterval(interval.current);
    };
    // eslint-disable-next-line
  }, []);

  const getUploadedFilesSettings = () => {
    return {
      heading: isFilesTableShown ? "Configured file(s)" : "Uploaded file(s)",
      subHeading: isFilesTableShown
        ? `The following files were submitted ${formatDate(
            batchData?.processedFiles?.timestamp,
            "MMM D, YYYY"
          )} at ${formatDate(batchData?.processedFiles?.timestamp, "h:mm A")}`
        : "Configure files’ parameters",
    };
  };

  const actionMenuSettings = getActionMenuSettings(batchData?.status);
  const isFilesTableShown = getIsFilesTableShown(batchData.status);

  return !!isBatchLoading ? (
    <Loader />
  ) : (
    <div className="flex flex-1 flex-col">
      <ToastContainer
        style={{ width: "500px", fontSize: "16px" }}
        progressStyle={{ backgroundColor: "#b6de3a" }}
      />
      <div className="flex-column mb-5 flex items-center justify-between">
        <Heading variant="h1" customClass="font-semibold">
          {batchData?.name}
        </Heading>
        {getIsActionMenuShown(batchData.status) && (
          <ActionMenu
            buttonName={actionMenuSettings.btnName}
            options={getBatchDetailsActionMenuItems({
              processOrPublishBatch,
              removeBatch,
              status: batchData.status,
              navigate,
            })}
            iconBefore={actionMenuSettings.icon}
            isActionBtnDisabled={!uploadedFiles.length}
            isActionMenuDisabled={getIsActionMenuDisabled({
              uploadedFiles,
              batchData,
              actionMenuSettings,
              tableHasUnsavedChanges,
            })}
            onAction={() => {
              processOrPublishBatch(actionMenuSettings.statusToSend);
            }}
          />
        )}
      </div>
      <BatchDetails />
      {isFilesTableShown && <ProcessedFiles />}
      <UploadedFiles {...getUploadedFilesSettings()} />
    </div>
  );
};

export default Batch;
