import classNames from "classnames";

const defaultItemCss = "flex items-center leading-5 no-underline gap-2";

//main items
export const getNavItemStyles = (isActive) => {
  const basicCss = `${defaultItemCss} h-12 text-lg`;

  return classNames(basicCss, {
    "text-gray-03 pl-7": !isActive,
    "border-l-4 border-solid border-l-yellow-01 font-semibold text-blue-03 pl-6":
      isActive,
  });
};

export const getSubNavItemStyles = (isActive) => {
  const basicCss = `${defaultItemCss} text-base mx-7 py-2.5`;

  return classNames(basicCss, {
    "text-gray-03": !isActive,
    "font-semibold text-blue-03 bg-gray-06 rounded-md": isActive,
  });
};

//school settings item
export const getSchoolSettingsStyles = (isActive, borderTop) => {
  const basicCss = `${defaultItemCss} h-12 text-lg mt-6`;

  return classNames(basicCss, {
    "text-gray-03 pl-7": !isActive,
    "border-l-4 border-solid border-l-yellow-01 text-blue-03 font-semibold pl-6":
      isActive,
    "border-t border-solid border-gray-02": borderTop,
  });
};
