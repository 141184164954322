import React from "react";

const TagDisplay = ({ options, additionalCssNames }) => {
  const renderTags = () => {
    return options.map((option) => {
      return (
        <div
          className={`text-blue-03 bg-gray-01 flex justify-center self-start rounded-md px-3 py-1 font-semibold ${additionalCssNames}`}
          key={option.name}
        >
          {option.name}
        </div>
      );
    });
  };

  return renderTags();
};

export default TagDisplay;
