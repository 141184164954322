import uniqid from "uniqid";

import { getCheckedFiles } from "pages/list-plus/batch/uploaded-files/uploaded-files.helper";

export const fomatToOptions = (batchListSources) => {
  return batchListSources?.map((el, ind) => {
    return {
      label: el.description,
      id: el.description,
      value: el.name,
      radioValue: el.name,
      isChecked: !!ind ? false : true,
      key: uniqid(),
    };
  });
};

export const getTouchedFieldsWithIds = ({
  data,
  uploadedFiles,
  getFieldState,
}) => {
  //finding only those files that were changed to make update only for them
  let touchedFieldsWithIds = [];

  for (let i = 0; i < data.filesData.length; i++) {
    //setting null instead of empty string to send - as BE expects null or values from const
    const filesToSend = getCheckedFiles(data.filesData);

    if (
      getFieldState(`filesData[${i}].code`).isDirty ||
      getFieldState(`filesData[${i}].listSource`).isDirty ||
      getFieldState(`filesData[${i}].segmentGroup`).isDirty
    ) {
      touchedFieldsWithIds.push({
        id: uploadedFiles[i].id,
        data: filesToSend[i],
      });
    }
  }

  return touchedFieldsWithIds;
};

export const bytesToMb = (bytes) => {
  return `${(bytes / 1024 / 1024).toFixed(2)} MB`;
};
