import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isBatchLoading: true,
  batchData: {},
  batchListSources: [],
  segmentGroup: [],
  summaryData: [],
};

const batchSlice = createSlice({
  name: "batch",
  initialState,
  reducers: {
    setIsBatchLoading: (state, action) => {
      state.isBatchLoading = action.payload;
    },
    setBatchData: (state, action) => {
      state.batchData = action.payload;
    },
    setBatchListSources: (state, action) => {
      state.batchListSources = action.payload;
    },
    setSegmentGroup: (state, action) => {
      state.segmentGroup = action.payload;
    },
    setSummaryData: (state, action) => {
      state.summaryData = action.payload;
    },
  },
});

export default batchSlice.reducer;
export const {
  setIsBatchLoading,
  setBatchData,
  setBatchListSources,
  setSegmentGroup,
  setSummaryData,
} = batchSlice.actions;
