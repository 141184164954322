import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

export const getDefaultValues = (files = []) => {
  const defaultValues = {
    filesData: [],
  };

  for (let i = 0; i < files.length; i++) {
    const el = files[i];

    defaultValues.filesData.push({
      code: el.code || null,
      listSource: el.listSource,
      segmentGroup: el.segmentGroup || null,
    });
  }

  return defaultValues;
};

export const getFilesFormValidationSchema = () =>
  yupResolver(
    yup.object({
      filesData: yup
        .array()
        .of(
          yup.object({
            code: yup.string().max(5, "Code must be max 5 characters long"),
          })
        )
        .required("Required"),
    })
  );
