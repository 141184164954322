import React, { useContext } from "react";
import { NavLink, Routes, Route, Outlet } from "react-router-dom";
import { AuthContext } from "context/AuthContext";
import { uniqueId } from "lodash";
import appRoles from "config/appRoles";
import CreateConfig from "./create-config";
import PrivateRoutes from "components/PrivateRoutes";
import ConfigVersions from "./config-versions";
import DisplayConfigs from "pages/config/display-configs";
import { ConfigContext } from "context/ConfigContext";

const Config = () => {
  const { isAuthorized } = useContext(AuthContext);
  const { clientConfigs } = useContext(ConfigContext);

  const defaultCssName =
    "cursor-pointer rounded-md border border-solid border-gray-02 bg-white px-4 py-2 text-base leading-6 text-gray-04 no-underline shadow-bs-secondary ";
  const activeCssStyles =
    "font-semibold border-2 border-solid border-yellow-01";

  const renderConfigs = () => {
    if (clientConfigs && clientConfigs.length > 0) {
      return clientConfigs.map((item) => {
        if (item.fileProcessType !== "supplementaldemographic") {
          return (
            <div className="flex flex-col justify-start" key={uniqueId()}>
              <div className="flex w-max gap-2">
                <NavLink
                  to={`${item.id}/versions`}
                  className={(navData) =>
                    navData.isActive
                      ? `${activeCssStyles} ${defaultCssName}`
                      : defaultCssName
                  }
                  key={uniqueId()}
                >
                  {item.fileProcessType}
                </NavLink>
              </div>
            </div>
          );
        }
      });
    } else {
      return <div>No Configs</div>;
    }
  };

  const renderCreateConfig = () => {
    if (isAuthorized(appRoles.IsConfigWriter)) {
      return (
        <div className="flex flex-col">
          <p className="text-blue-03 pb-3 text-base font-semibold leading-5">
            Create Config
          </p>
          <NavLink
            to={`create`}
            className={(navData) =>
              navData.isActive
                ? `${activeCssStyles} ${defaultCssName}`
                : defaultCssName
            }
            key={uniqueId()}
          >
            Create New Config
          </NavLink>
        </div>
      );
    }
  };

  return (
    <>
      <div className="flex flex-col">
        <div className="flex gap-5">
          {renderCreateConfig()}
          <div className="flex flex-col">
            <p className="text-blue-03 pb-3 text-base font-semibold leading-5">
              View Configs
            </p>
            <div className="flex gap-5">{renderConfigs()}</div>
          </div>
        </div>
      </div>
      <div className="pt-3">
        <Routes>
          <Route
            element={
              <PrivateRoutes allowedAppRoles={appRoles.IsConfigReader} />
            }
          >
            <Route
              exact
              path=":configId/versions/*"
              element={<ConfigVersions />}
            />
            <Route
              element={
                <PrivateRoutes allowedAppRoles={appRoles.IsConfigReader} />
              }
            >
              <Route
                exact
                path=":configId/versions/:versionId/*"
                element={<DisplayConfigs />}
              />
            </Route>
          </Route>
          <Route
            element={
              <PrivateRoutes allowedAppRoles={appRoles.IsConfigWriter} />
            }
          >
            <Route exact path="create" element={<CreateConfig />}></Route>
          </Route>
        </Routes>
        <Outlet />
      </div>
    </>
  );
};

export default Config;
