import React, { useState, useEffect, useContext } from "react";
import SchoolSelect from "./partials/SchoolSelect";
import { ClientContext } from "../context/ClientContext";
import apiRoot from "../api/apiRoot";

const AssignAnalyst = ({ defaultValue, setAnalyst }) => {
  const [availableAnalysts, setAvailableAnalysts] = useState([]);
  const { updateAnalyst } = useContext(ClientContext);

  useEffect(() => {
    apiRoot.get("/user/all").then((res) => {
      const analysts = res.data.map((item) => {
        return { label: item.name, value: item.id };
      });

      setAvailableAnalysts(analysts);
    });
  }, []);

  function onSchoolSelect(value) {
    setAnalyst(value);
    updateAnalyst(value);
  }

  return (
    <div>
      <p className="text-gray-05 font-semibold">Primary Analyst</p>
      <div className="onboard-select-container">
        <SchoolSelect
          options={availableAnalysts}
          onChange={onSchoolSelect}
          defaultValue={defaultValue}
        />
      </div>
    </div>
  );
};

export default AssignAnalyst;
