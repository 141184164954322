import React from "react";

import CustomSelect from "components/partials/custom-select/custom-select";

const pageSizes = [
  { value: 10, label: 10 },
  { value: 20, label: 20 },
];

const getPaginationSelectStyles = (color, disabledColor) => ({
  control: (styles, { isDisabled, selectProps }) => ({
    ...styles,
    boxShadow: "none",
    borderColor: isDisabled
      ? "rgb(217, 217, 217)"
      : selectProps.menuIsOpen
      ? "rgb(0 45 93)"
      : color,
    outline: "none",
    backgroundColor: isDisabled ? disabledColor : "transparent",
    borderRadius: 6,
    minHeight: "2rem",
    height: "2rem",

    "&:hover": {
      backgroundColor: "rgb(245, 248, 250)",
    },
  }),
  singleValue: (styles, { isDisabled }) => ({
    ...styles,
    color: isDisabled ? "rgb(140, 140, 140)" : "inherit",
  }),
  dropdownIndicator: (styles, { isDisabled, selectProps }) => ({
    ...styles,
    paddingTop: 4,
    color: isDisabled ? "rgb(140, 140, 140)" : "rgb(0 45 93)",
    transition: "all .5s ease",
    transform: selectProps.menuIsOpen ? "rotate(180deg)" : null,

    "&:hover": {
      color: "rgb(0 45 93)",
    },
  }),

  option: (styles, { isFocused, isSelected }) => ({
    ...styles,
    backgroundColor: isFocused ? "rgb(245, 248, 250)" : "transparent",
    borderRadius: isFocused ? "6px" : "inherit",
    color: isSelected ? "rgb(66, 66, 66)" : "rgb(102, 102, 102)",
    fontWeight: isSelected ? "600" : "400",
    marginLeft: "4px",
    marginRight: "4px",
    maxWidth: "90%",

    ":after": isSelected
      ? {
          content: '""',
          display: "inline-block",
          marginLeft: 8,
          height: 15,
          width: 15,
          backgroundImage: `url('assets/icons/check-3.svg')`,
          backgroundSize: "15px 15px",
        }
      : null,
  }),
});

export const ViewByAmountSelect = ({ table, pageSize, setAmount }) => (
  <div className="ml-2 flex items-center md:ml-8">
    <span className="pr-2">View</span>
    <CustomSelect
      isSearchable={false}
      customSelectClass="w-20"
      options={pageSizes}
      value={pageSizes.filter((option) => option.value === pageSize)}
      onChange={(e) => {
        setAmount((state) => ({
          ...state,
          pageSize: e.value,
        }));
        table.setPageSize(e.value);
      }}
      extraStyles={getPaginationSelectStyles}
    />
  </div>
);
