import React, { useState, useEffect, useMemo } from "react";
import classNames from "classnames";

import CustomButton from "components/partials/custom-button/custom-button";

import Icons from "assets/icons";

export const Pagination = ({ table, pagesAmount, pageIndex, totalRecords }) => {
  //set range of pages to show
  const pagesNumberToShow = 10;

  const showTableFeature = pagesAmount > 1;

  //set default start and end pages
  const [{ startPage, lastPage }, setStartAndLastPages] = useState({
    startPage: 0,
    lastPage: pagesNumberToShow,
  });

  //define array with all pages list
  const allPagesList = useMemo(() => {
    if (pagesAmount)
      return Array(pagesAmount)
        .fill()
        .map((_, y) => y + 1);
  }, [pagesAmount]);

  //define array with last pages range
  const lastPagesRange = allPagesList?.slice(
    allPagesList.length - pagesNumberToShow,
    allPagesList[allPagesList.length - 1]
  );

  //set array with concrete range to display in pagination
  const pagesToShow = useMemo(
    () => allPagesList?.slice(startPage, lastPage),
    [allPagesList, startPage, lastPage]
  );

  //check whether array with range contains page to be redirected
  useEffect(() => {
    //if there is no such page in current array with range - we set new range
    if (!pagesToShow?.includes(pageIndex + 1)) {
      //but before we check whether lastPagesRange array contains page to set appropriate start and end pages
      if (lastPagesRange?.includes(pageIndex + 1)) {
        //lastPagesRange array contains page
        setStartAndLastPages({
          startPage: lastPagesRange[0] - 1,
          lastPage: lastPagesRange[lastPagesRange.length - 1],
        });
      } else {
        //lastPagesRange array does not contain page
        setStartAndLastPages({
          startPage: pageIndex,
          lastPage: pageIndex + pagesNumberToShow,
        });
      }
    }
    // eslint-disable-next-line
  }, [pageIndex]);

  const leftArrowsDisabled = !showTableFeature || pageIndex === 0;
  const rightArrowsDisabled =
    !showTableFeature || pageIndex + 1 === table.getPageCount();

  return (
    <div className="flex justify-center gap-4">
      <CustomButton
        variant="secondaryWithoutBorder"
        size="smSquare"
        iconBefore={
          <Icons.DoubleArrow
            customClass="rotate-180"
            aria-label="Go the first page"
            height="100%"
            width="14"
          />
        }
        customClass={classNames("ml-1 md:ml-6 font-semibold", {
          "text-indigo-02": !leftArrowsDisabled,
          "text-gray-02": leftArrowsDisabled,
        })}
        iconBeforeCustomClass="!pr-0"
        disabled={leftArrowsDisabled}
        onClick={() => table.setPageIndex(0)}
      />

      <CustomButton
        variant="secondaryWithoutBorder"
        size="smSquare"
        iconBefore={
          <Icons.Arrow
            customClass="rotate-180"
            aria-label="Go the previous page"
            height="100%"
            width="16"
          />
        }
        customClass={classNames("ml-1 md:ml-2 font-semibold", {
          "text-indigo-02": !leftArrowsDisabled,
          "text-gray-02": leftArrowsDisabled,
        })}
        iconBeforeCustomClass="!pr-0"
        disabled={leftArrowsDisabled}
        onClick={() => table.previousPage()}
      />

      <CustomButton
        variant="secondaryWithoutBorder"
        size="smSquare"
        iconBefore={
          <Icons.Arrow aria-label="Go the next page" height="100%" width="16" />
        }
        customClass={classNames("ml-1 md:ml-2 font-semibold", {
          "text-indigo-02": !rightArrowsDisabled,
          "text-gray-02": rightArrowsDisabled,
        })}
        iconBeforeCustomClass="!pr-0"
        disabled={rightArrowsDisabled}
        onClick={() => table.nextPage()}
      />

      <CustomButton
        variant="secondaryWithoutBorder"
        size="smSquare"
        iconBefore={
          <Icons.DoubleArrow
            aria-label="Go the last page"
            height="100%"
            width="14"
          />
        }
        customClass={classNames("ml-1 md:ml-2 font-semibold", {
          "text-indigo-02": !rightArrowsDisabled,
          "text-gray-02": rightArrowsDisabled,
        })}
        iconBeforeCustomClass="!pr-0"
        disabled={rightArrowsDisabled}
        onClick={() => table.setPageIndex(totalRecords)}
      />
    </div>
  );
};
