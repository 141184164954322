import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  filesLoading: false,
  uploadedFiles: [],
  radioOptions: [],
  isActionAppliedOnFile: false,
  isUploadAreaShown: true,
  tableHasUnsavedChanges: false,
  pageSize: 50,
  totalRecords: 0,
  selectedRadioOption: "COLLEGE_BOARD",
};

const filesSlice = createSlice({
  name: "files",
  initialState,
  reducers: {
    setFilesLoading: (state, action) => {
      state.filesLoading = action.payload;
    },
    //files
    setUploadedFiles: (state, action) => {
      state.uploadedFiles = action.payload;
    },
    setIsActionAppliedOnFile: (state, action) => {
      state.isActionAppliedOnFile = action.payload;
    },
    setIsUploadAreaShown: (state, action) => {
      state.isUploadAreaShown = action.payload;
    },
    setTableHasUnsavedChanges: (state, action) => {
      state.tableHasUnsavedChanges = action.payload;
    },
    //pagination
    setTotalRecords: (state, action) => {
      state.totalRecords = action.payload;
    },
    //radio options
    setRadioOptions: (state, action) => {
      state.radioOptions = action.payload;
    },
    setSelectedRadioOption: (state, action) => {
      state.selectedRadioOption = action.payload;
    },
  },
});

export default filesSlice.reducer;

export const {
  setFilesLoading,
  setUploadedFiles,
  setIsActionAppliedOnFile,
  setIsUploadAreaShown,
  setTableHasUnsavedChanges,
  setRadioOptions,
  setSelectedRadioOption,
  setTotalRecords,
} = filesSlice.actions;
