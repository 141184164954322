import React, { useState, useRef, useEffect, forwardRef } from "react";
import { useSelector } from "react-redux";
import classNames from "classnames";

import RadioGroup from "components/partials/RadioGroup";
import CustomButton from "components/partials/custom-button/custom-button";

import store from "state/store";

import {
  setRadioOptions,
  setSelectedRadioOption,
  setIsUploadAreaShown,
} from "state/files/files.slice";

import { fomatToOptions } from "helpers/fomat-data";

import Icons from "assets/icons";

const FileUploader = forwardRef(
  ({ label, multiple = true, isCloseBtnShown, onChange, helperText }, ref) => {
    const { batchListSources } = useSelector((state) => state?.batch);
    const { radioOptions } = useSelector((state) => state?.files);

    // eslint-disable-next-line
    const [errorText, setErrorText] = useState("");

    const fileInputRef = useRef(ref);

    const onFileChange = (e) => {
      setErrorText("");
      !!onChange && onChange(e.target.files);
    };

    let handleFileUploadClick = (e) => {
      e.preventDefault();
      fileInputRef.current.click();
    };

    //handle radio options
    const handleRadioChange = (radioItem) => {
      const newListPlusRadioOptions = radioOptions?.map((item) => {
        if (item.radioValue === radioItem) {
          store.dispatch(setSelectedRadioOption(radioItem));
          return { ...item, isChecked: true };
        } else {
          return { ...item, isChecked: false };
        }
      });
      store.dispatch(setRadioOptions(newListPlusRadioOptions));
    };

    //set list sources radio options
    useEffect(() => {
      if (!!batchListSources.length) {
        const listSourceFilterData = fomatToOptions(batchListSources);

        store.dispatch(setRadioOptions(listSourceFilterData));
      }
    }, [batchListSources]);

    return (
      <div className="mb-2 mt-2.5 flex w-full flex-col rounded-md border border-gray-02 p-3 text-gray-05 shadow-md">
        {label && (
          <div className="flex justify-between">
            <label className="mb-2 text-lg font-semibold text-blue-03">
              {label}
            </label>
            {isCloseBtnShown && (
              <CustomButton
                variant="text"
                customClass="p-0"
                size="sm"
                onClick={() => {
                  store.dispatch(setIsUploadAreaShown(false));
                }}
                iconBefore={
                  <Icons.Plus className="h-4 w-4 rotate-45 cursor-pointer text-blue-01" />
                }
              />
            )}
          </div>
        )}

        <RadioGroup
          htmlFor="list-source-filter"
          label="List source"
          name="listSource"
          options={radioOptions}
          onCheckboxClick={handleRadioChange}
        />
        <input
          ref={fileInputRef}
          type="file"
          multiple={multiple}
          className="hidden"
          onChange={onFileChange}
        />
        <div
          className={classNames(
            "flex h-24 cursor-pointer flex-col items-center justify-center rounded-md border border-dashed",
            {
              "!border-solid border-red-03": errorText,
              "bg-gray-01": !errorText,
            }
          )}
          onDrop={(e) => {
            e.preventDefault();
            onFileChange(e.dataTransfer.files);
          }}
          onDragOver={(e) => e.preventDefault()}
          onClick={handleFileUploadClick}
        >
          <Icons.File className="h-9 w-7" />
          <span>
            <span className="font-bold">Click to upload</span> or drag and drop
            file(s).
          </span>
        </div>
        {helperText && <span className="mt-2 text-sm">{helperText}</span>}
        {errorText && (
          <span className="mt-1 text-sm text-red-600">{errorText}</span>
        )}
      </div>
    );
  }
);

export default FileUploader;
