import React, { createContext, useState, useEffect, useContext } from "react";
import { useSelector } from "react-redux";

import { AuthContext } from "context/AuthContext";
import { ClientContext } from "context/ClientContext";

import apiRoot from "api/apiRoot";
import appRoles from "config/appRoles";

import { getSortingParamsQuery } from "helpers/get-query";

export const ListPlusContext = createContext();

export const ListPlusStore = ({ children }) => {
  const { batchData } = useSelector((state) => state?.batch);

  const { currentClientId } = useContext(ClientContext);
  const { isAuthorized } = useContext(AuthContext);

  const [listUploadsData, setListUploadsData] = useState([]);
  const [batchStatuses, setBatchStatuses] = useState([]);
  const [isListUploadsLoading, setIsListUploadsLoading] = useState(false);

  //pagination
  const [{ pageSize, totalRecords }, setAmount] = useState({
    pageSize: 10,
    totalRecords: 0,
  });

  //view per page
  const [pageIndex, setPageIndex] = useState(0);

  //sorting
  const [sortingParams, setSortingParams] = useState([
    { id: "createdDate", desc: true },
  ]);

  //get uploads list data for table
  useEffect(() => {
    setIsListUploadsLoading(true);
    const sortingQuery = getSortingParamsQuery(sortingParams);

    if (currentClientId && isAuthorized(appRoles.IsListPlusReader)) {
      apiRoot
        .get(
          `/api/clients/${currentClientId}/file-batches?size=${pageSize}&page=${pageIndex}${sortingQuery}`
        )
        .then((res) => {
          setListUploadsData(res.data.content);
          setAmount({
            pageSize,
            totalRecords: res.data.totalElements,
          });
        })
        .catch((err) => console.error("listUploadsData request failed", err))
        .finally(() => setIsListUploadsLoading(false));
    }
    // eslint-disable-next-line
  }, [currentClientId, pageIndex, pageSize, sortingParams, batchData]);

  //get batch statuses dictionary
  useEffect(() => {
    apiRoot
      .get("/api/dictionary/FILE_BATCH_STATUSES")
      .then((res) => {
        setBatchStatuses(res.data);
      })
      .catch((err) =>
        console.error("batchStatuses dictionary request failed", err)
      );
  }, []);

  const value = {
    batchStatuses,
    listUploadsData,
    totalRecords,
    pageIndex,
    setPageIndex,
    pageSize,
    setAmount,
    sortingParams,
    setSortingParams,
    isListUploadsLoading,
  };

  return (
    <ListPlusContext.Provider value={value}>
      {children}
    </ListPlusContext.Provider>
  );
};
