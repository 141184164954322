import React, { useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import MappingViewer from "./mapping-viewer";
import { FileMappingsContext } from "context/FileMappingsContext";
import { AuthContext } from "context/AuthContext";
import appRoles from "config/appRoles";
import Button from "components/partials/Button";
import { ToastContainer } from "react-toastify";

const DisplayMappings = () => {
  const { versionId } = useParams();
  const {
    jsonMapping,
    isEditing,
    isEditable,
    handleMappingEdit,
    handleMappingAdd,
    handleMappingSave,
    handleMappingCancel,
    handleMappingDelete,
    handleRemoveMapping,
    handleMappingChange,
    handleMappingPublish,
    handleMappingPublishQueue,
    isDeleting,
    setMappingVersionId,
    isPublished,
    currentFPT,
    currentFPST,
    setJsonMapping,
    setIsEditing,
  } = useContext(FileMappingsContext);
  const { isAuthorized } = useContext(AuthContext);

  useEffect(() => {
    if (versionId) {
      setMappingVersionId(versionId);
    }
    return () => {
      setJsonMapping({});
      setMappingVersionId();
      setIsEditing(false);
    };
    // eslint-disable-next-line
  }, [versionId]);

  const renderMappingCRUDButtons = () => {
    if (isAuthorized(appRoles.IsFileSetupWriter) && isEditing) {
      return (
        <div className="mb-2 flex gap-3">
          <Button
            label={"Add Mappings"}
            className="rounded-md bg-yellow-01 px-4 py-1 text-blue-03"
            onClick={() => handleMappingAdd()}
          />
          <Button
            label={"Remove Mapping"}
            className="rounded-md bg-yellow-01 px-4 py-1 text-blue-03"
            onClick={() => handleMappingDelete()}
          />
        </div>
      );
    } else {
      return <></>;
    }
  };

  const renderMappingPublish = () => {
    if (
      isAuthorized(appRoles.IsFileSetupApprover) &&
      !isEditing &&
      !isPublished
    ) {
      return (
        <Button
          label={"Publish Mappings"}
          className="mb-2 rounded-md bg-yellow-01 px-4 py-1 text-blue-03"
          onClick={handleMappingPublish}
        />
      );
    }
    if (
      isAuthorized(appRoles.IsFileSetupWriter) &&
      !isEditing &&
      !isAuthorized(appRoles.IsFileSetupApprover) &&
      isEditable
    ) {
      return (
        <Button
          label={"Add to Publish Queue"}
          className="mb-2 rounded-md bg-yellow-01 px-4 py-1 text-blue-03"
          onClick={handleMappingPublishQueue}
        />
      );
    }
    return <></>;
  };

  const renderEditSaveButtons = () => {
    if (isAuthorized(appRoles.IsFileSetupWriter) && !isEditing && isEditable)
      return (
        <Button
          label={"Edit Mapping"}
          className="mb-2 rounded-md bg-yellow-01 px-4 py-1 text-blue-03"
          onClick={handleMappingEdit}
        />
      );
    else if (isEditing) {
      return (
        <>
          <Button
            label={"Cancel"}
            className="mb-2 rounded-md bg-red-400 px-4 py-1 text-black"
            onClick={handleMappingCancel}
          />
          <Button
            label={"Save Mappings"}
            className="mb-2 rounded-md bg-yellow-01 px-4 py-1 text-blue-03"
            onClick={handleMappingSave}
          />
        </>
      );
    } else {
      return <></>;
    }
  };

  return (
    <>
      <ToastContainer
        style={{ width: "500px", fontSize: "16px" }}
        progressStyle={{ backgroundColor: "#b6de3a" }}
      />
      <div className="mb-5">
        <div className="flex items-end gap-5 pt-3">
          {renderMappingPublish()}
          {renderEditSaveButtons()}
          {renderMappingCRUDButtons()}
        </div>
        {jsonMapping ? (
          <MappingViewer
            srcJson={jsonMapping}
            contenteditable={
              isAuthorized(appRoles.IsFileSetupWriter) && isEditing
            }
            handleEditChange={handleMappingChange}
            handleDelete={handleRemoveMapping}
            isDelete={
              isDeleting &&
              isAuthorized(appRoles.IsFileSetupWriter) &&
              isEditing
            }
            fileProcessType={currentFPT}
            fileProcessSubType={currentFPST}
          />
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

export default DisplayMappings;
