import React, { useContext, useEffect, useState } from "react";
import { InstitutionContext } from "../context/InstitutionContext";
import { ClientContext } from "../context/ClientContext";
import ClientCard from "components/ClientCard";
import { AuthContext } from "../context/AuthContext";
import apiRoot from "../api/apiRoot";
import { ToastContainer, toast } from "react-toastify";
import EmptySpaceContent from "components/partials/empty-space-content/empty-space-content";
import Icons from "assets/icons";

const Home = () => {
  const { user } = useContext(AuthContext);
  const {
    bookmarkedInstitutions,
    retrieveInstitutionsByTags,
    retrieveBookmarkedInstitutions,
    institutions,
    setInstitutions,
  } = useContext(InstitutionContext);
  const { setCurrentClientId, setCurrentClientData } =
    useContext(ClientContext);
  const [productFilters, setProductFilters] = useState([]);

  useEffect(() => {
    setCurrentClientId(null);
    setCurrentClientData(null);
    setInstitutions([]);
    retrieveBookmarkedInstitutions();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (user && productFilters.length === 0) {
      fetchTags();
    }
    // eslint-disable-next-line
  }, [user]);

  useEffect(() => {
    handleProductOfferingsSearch();
    // eslint-disable-next-line
  }, [bookmarkedInstitutions, productFilters]);

  function handleProductOfferingsSearch() {
    if (productFilters && productFilters.length > 0) {
      const searchTags = productFilters.filter((productFilter) => {
        return productFilter.isChecked;
      });
      if (searchTags.length > 0) {
        retrieveInstitutionsByTags(searchTags);
      }
    }
  }

  async function fetchTags() {
    apiRoot.get("/tags?tagType=client").then((res) => {
      setProductFilters(res.data.map((tag) => ({ ...tag, isChecked: false })));
    });
  }

  const handleProductFilterClick = (offering) => {
    const newProductFilters = productFilters.map((productFilter) => {
      if (productFilter.name === offering.name) {
        return { ...productFilter, isChecked: !productFilter.isChecked };
      } else {
        return productFilter;
      }
    });
    setProductFilters(newProductFilters);
  };

  const renderProductOfferings = () => {
    if (productFilters && productFilters.length > 0) {
      const defaultCSSName =
        "cursor-pointer rounded-md border border-solid border-gray-02 bg-white px-4 py-1 text-base leading-6 text-gray-04 no-underline shadow-bs-secondary mt-4";
      const activeCSSName = "border-2 border-yellow-01 font-semibold";

      return productFilters.map((offering) => {
        return (
          <button
            className={
              offering.isChecked
                ? `${activeCSSName} ${defaultCSSName}`
                : `${defaultCSSName}`
            }
            onClick={() => handleProductFilterClick(offering)}
            key={offering.name}
          >
            {offering.name}
          </button>
        );
      });
    }
  };

  const handleBookmarkClick = async (e, bookmarked, item) => {
    e.preventDefault();
    let toastMessages = {};

    if (bookmarked) {
      toastMessages = {
        pending: "Removing " + item.institutionName + " from bookmarks...",
        success: "Success - " + item.institutionName + " has been removed",
        error: "Failed to remove bookmark for " + item.institutionName,
      };
    } else {
      toastMessages = {
        pending: "Bookmarking " + item.institutionName + "...",
        success:
          "Success! " +
          item.institutionName +
          " is now bookmarked to the homepage!",
        error:
          "Failed to bookmark " + item.institutionName + " to the homepage!",
      };
    }

    const promise = apiRoot.post(
      `/user/bookmark?userId=${user.id}&clientId=${item.id}&remove=${bookmarked}`
    );
    const res = await toast.promise(promise, toastMessages);
    if (res) {
      retrieveBookmarkedInstitutions();
    }
  };

  function renderClientCards(clientsToRender) {
    function compare(a, b) {
      if (a.institutionName < b.institutionName) {
        return -1;
      }
      if (a.institutionName > b.institutionName) {
        return 1;
      }
      return 0;
    }

    if (clientsToRender && clientsToRender.length > 0) {
      return clientsToRender.sort(compare).map((item) => {
        return (
          <ClientCard
            client={item}
            handleBookmarkClick={handleBookmarkClick}
            handleCardClick={setCurrentClientId}
            bookmarked={item?.isBookmarked}
            key={item.id}
          />
        );
      });
    } else {
      return (
        <EmptySpaceContent
          image={<Icons.EmptyImage className="h-[210px] w-[210px]" />}
          text="No Clients Found"
        />
      );
    }
  }

  function renderHomePage() {
    if (productFilters.filter((item) => item.isChecked).length > 0) {
      if (institutions.length > 0) {
        return (
          <>
            <div className="flex flex-col gap-2">
              <p>Bookmarked Clients</p>
              <div className="grid grid-cols-4 gap-5">
                {renderClientCards(
                  institutions.filter((item) => item.isBookmarked === true)
                )}
              </div>
            </div>
            <div className="flex flex-col gap-2">
              <p>Not Bookmarked Clients</p>
              <div className="grid grid-cols-4 gap-5">
                {renderClientCards(
                  institutions.filter((item) => item.isBookmarked === false)
                )}
              </div>
            </div>
          </>
        );
      } else {
        return (
          <EmptySpaceContent
            image={<Icons.EmptyImage className="h-[210px] w-[210px]" />}
            text="No Clients Found. Adjust Tag Search"
          />
        );
      }
    } else if (bookmarkedInstitutions.length > 0) {
      return (
        <div className="flex flex-col gap-2">
          <p>Bookmarked Clients</p>
          <div className="grid grid-cols-4 gap-5">
            {renderClientCards(bookmarkedInstitutions)}
          </div>
        </div>
      );
    } else {
      return (
        <EmptySpaceContent
          image={<Icons.EmptyImage className="h-[210px] w-[210px]" />}
          text="No Clients Bookmarked. Search to Add"
        />
      );
    }
  }

  return (
    <div className="px-10">
      <ToastContainer
        style={{ width: "500px", fontSize: "16px" }}
        progressStyle={{ backgroundColor: "#b6de3a" }}
      />
      <div className="flex items-center gap-3 pb-5">
        {renderProductOfferings()}
        {productFilters.filter((item) => {
          return item.isChecked;
        }).length > 0 ? (
          <button
            className="self-end text-base font-semibold leading-5 text-blue-02"
            onClick={() => {
              fetchTags();
              setInstitutions([]);
            }}
          >
            Reset Filters x
          </button>
        ) : (
          ""
        )}
      </div>
      <div className="flex flex-col gap-5 text-xl font-bold text-blue-03">
        {renderHomePage()}
      </div>
    </div>
  );
};

export default Home;
