import React, { useContext, useState } from "react";
import spinimg from "assets/images/spinningimg.png";
import checkimg from "assets/images/check.png";
import failedimg from "assets/images/RedX.png";
import queuedimg from "assets/images/queue.png";
import { FilesplitContext } from "context/FilesplitContext";
import { ClientContext } from "context/ClientContext";
import apiRoot from "api/apiRoot";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import Icons from "assets/icons";
import Table from "components/Table";
import FileSearch from "components/FileSearch";
import appRoles from "config/appRoles";

export default function FileSplit() {
  const types = ["Demographic", "Financials", "Funds", "Term", "Clearinghouse"];

  const { completedFiles, queuedFiles, splittingFile, addFileArray, addFile } =
    useContext(FilesplitContext);
  const { currentClientId } = useContext(ClientContext);

  const [searchResults, setSearchResults] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  // Combo of all 3 file status that are maintained in the context, mainly used for display
  const totalFiles = [...splittingFile, ...queuedFiles, ...completedFiles];

  function renderedFileStatus(file) {
    switch (file.status) {
      case "Success":
        return <img src={checkimg} alt="Success" className="h-10 w-auto" />;
      case "Failed":
        return <img src={failedimg} alt="Failed" className="h-10 w-auto" />;
      case "Splitting":
        return <img src={spinimg} alt="Splitting" className="rotate" />;
      default:
        return <img src={queuedimg} alt="Queued" className="h-10 w-auto" />;
    }
  }

  const renderFileSplitResults = () => {
    if (totalFiles.length > 0) {
      const headers = [
        {
          header: "BLOB CONTAINER",
          dataKey: "blobContainer",
          additionalCssClass: "px-4 text-center",
        },
        {
          header: "FILE PATH",
          dataKey: "filePath",
          additionalCssClass: "px-14 text-left",
        },
        {
          header: "UPLOAD DATETIME",
          dataKey: "insertDateTime",
          additionalCssClass: "px-14 text-left",
        },
        {
          header: "STATUS",
          dataKey: renderedFileStatus,
          additionalCssClass: "px-4 text-center",
        },
        {
          header: "COLUMNS REMOVED",
          dataKey: "colsRemoved",
          additionalCssClass: "px-4 text-center m-w-[1000px] ",
        },
      ];

      return (
        <div className="pt-6">
          <p className="text-blue-03 pb-1 text-base font-bold leading-5">
            Split Files
          </p>

          <Table
            headers={headers}
            data={totalFiles}
            tableAdditionalClassName={"w-[calc(100vw -300px)] max-w-[2000px]"}
          />
        </div>
      );
    } else {
      return "";
    }
  };

  function onLatestFileButtonClick(type) {
    toast.promise(
      apiRoot
        .get(
          `/files/splitLatest?fileProcessType=${type}&clientId=${currentClientId}`
        )
        .then((res) => addFileArray(res.data)),
      {
        pending: "Retrieving Latest Files for " + type + " File Masks",
        success: "Found Latest Files for " + type + " File Masks!",
        error: "Failed to find Latest Files for " + type + " File Masks",
      }
    );
  }

  const renderSearchResults = () => {
    if (searchResults.length > 0) {
      const headers = [
        {
          header: "BLOB CONTAINER",
          dataKey: "blobContainer",
          additionalCssClass: "px-4 text-center",
        },
        {
          header: "FILE PATH",
          dataKey: "filePath",
          additionalCssClass: "px-14 text-left",
        },
        {
          header: "FILE SIZE",
          dataKey: "fileSize",
          additionalCssClass: "px-14 text-left",
        },
        {
          header: "UPLOAD DATETIME",
          dataKey: "insertDateTime",
          additionalCssClass: "px-14 text-left",
        },
        {
          header: "SPLIT",
          dataKey: "button",
          additionalCssClass: "px-4 text-center",
        },
      ];

      return (
        <div className="pt-4">
          <p className="text-blue-03 pb-1 text-base font-bold leading-5">
            {searchTerm
              ? `Search results for "${searchTerm}"`
              : `Recent Uploads`}
          </p>
          <Table
            tableAdditionalClassName={"w-[calc(100vw -300px)] max-w-[1750px]"}
            headers={headers}
            data={searchResults}
            buttonFunction={addFile}
            buttonIconLeft={<Icons.SplitFile />}
            buttonLabel={"Split"}
            buttonLabelClassName={"text-blue-03 font-semibold"}
            buttonClassName="mx-auto my-0 flex cursor-pointer items-center gap-1 rounded-md border border-solid border-blue-01 bg-white px-3 py-2"
            buttonAllowedAppRoles={appRoles.IsFileSplitWriter}
          />
        </div>
      );
    } else {
      return "";
    }
  };

  return (
    <>
      <ToastContainer
        style={{ width: "450px", fontSize: "16px" }}
        progressStyle={{ backgroundColor: "#b6de3a" }}
      />
      <FileSearch
        setSearchResults={setSearchResults}
        searchLabel={"Search for file..."}
        lastestButtonsLabel={"Split Latest"}
        setSearchTerm={setSearchTerm}
        searchTerm={searchTerm}
        latestFileTypes={types}
        onLatestFileButtonClick={onLatestFileButtonClick}
        allowAppRoles={appRoles.IsFileSplitWriter}
      />
      {renderSearchResults()}
      {renderFileSplitResults()}
    </>
  );
}
