import React, { useContext } from "react";
import { Outlet, Navigate } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";

const PrivateRoutes = ({ allowedAppRoles }) => {
  const { isAuthorized } = useContext(AuthContext);

  const renderPrivateRoute = () => {
    if (isAuthorized(allowedAppRoles)) {
      return <Outlet />;
    }

    return "Unauthorized";
  };

  return renderPrivateRoute();
};

export default PrivateRoutes;
