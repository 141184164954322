// Global User Permissions
export const GLOBAL_WRITER = "Global.Writer";
export const GLOBAL_READER = "Global.Reader";
export const GLOBAL_APPROVER = "Global.Approver";

// File Mover Permissions
export const FILEMOVER_WRITER = "FileMover.Writer";
export const FILEMOVER_READER = "FileMover.Reader";

// Filesplit Permissions
export const FILESPLIT_WRITER = "FileSplit.Writer";
export const FILESPLIT_READER = "FileSplit.Reader";

// Client Setup Permissions
export const CONFIG_WRITER = "Config.Writer";
export const CONFIG_READER = "Config.Reader";
export const CONFIG_APPROVER = "Config.Approver";

// These are a combined set of permissions used for FileMasks and FileMappings
export const FILESETUP_WRITER = "FileSetup.Writer";
export const FILESETUP_READER = "FileSetup.Reader";
export const FILESETUP_APPROVER = "FileSetup.Approver";

// List Plus Permissions
export const LISTPLUS_WRITER = "ListPlus.Writer";
export const LISTPLUS_READER = "ListPlus.Reader";
export const LISTPLUS_APPROVER = "ListPlus.Approver";

// AI Permissions
export const AI_TRANSCRIPT_SUMMARIZATION_READER = "AI.Transcript.Summarization.Reader";
export const AI_TRANSCRIPT_SUMMARIZATION_WRITER = "AI.Transcript.Summarization.Writer";

// School Settings Permissions
export const SCHOOL_SETTINGS_ADMIN = "SchoolSettings.Admin";

export const IsAtomUser = [
  GLOBAL_APPROVER,
  GLOBAL_WRITER,
  GLOBAL_READER,
  CONFIG_APPROVER,
  CONFIG_WRITER,
  CONFIG_READER,
  FILESETUP_APPROVER,
  FILESETUP_WRITER,
  FILESETUP_READER,
  FILEMOVER_WRITER,
  FILEMOVER_READER,
  FILESPLIT_WRITER,
  FILESPLIT_READER,
  LISTPLUS_APPROVER,
  LISTPLUS_WRITER,
  LISTPLUS_READER,
  AI_TRANSCRIPT_SUMMARIZATION_READER,
  AI_TRANSCRIPT_SUMMARIZATION_WRITER,
  SCHOOL_SETTINGS_ADMIN,
];

export const IsGlobalApprover = [GLOBAL_APPROVER];

export const IsConfigApprover = [GLOBAL_APPROVER, CONFIG_APPROVER];
export const IsConfigWriter = [...IsConfigApprover, GLOBAL_WRITER, CONFIG_WRITER];
export const IsConfigReader = [...IsConfigWriter, GLOBAL_READER, CONFIG_READER];

export const IsFileMoverWriter = [GLOBAL_APPROVER, GLOBAL_WRITER, FILEMOVER_WRITER];
export const IsFileMoverReader = [...IsFileMoverWriter, GLOBAL_READER, FILEMOVER_READER];

export const IsFileSetupApprover = [GLOBAL_APPROVER, FILESETUP_APPROVER];
export const IsFileSetupWriter = [...IsFileSetupApprover, GLOBAL_WRITER, FILESETUP_WRITER];
export const IsFileSetupReader = [...IsFileSetupWriter, GLOBAL_READER, FILESETUP_READER];

export const IsFileSplitWriter = [GLOBAL_APPROVER, GLOBAL_WRITER, FILESPLIT_WRITER];
export const IsFileSplitReader = [...IsFileSplitWriter, GLOBAL_READER, FILESPLIT_READER];

export const IsListPlusApprover = [GLOBAL_APPROVER, LISTPLUS_APPROVER];
export const IsListPlusWriter = [...IsListPlusApprover, GLOBAL_WRITER, LISTPLUS_WRITER];
export const IsListPlusReader = [...IsListPlusWriter, GLOBAL_READER, LISTPLUS_READER];

export const IsAiTranscriptSummarizationWriter = [GLOBAL_APPROVER, GLOBAL_WRITER, AI_TRANSCRIPT_SUMMARIZATION_WRITER];
export const IsAiTranscriptSummarizationReader = [...IsAiTranscriptSummarizationWriter, AI_TRANSCRIPT_SUMMARIZATION_READER, GLOBAL_READER];

export const IsSchoolSettingsAdmin = [GLOBAL_APPROVER, SCHOOL_SETTINGS_ADMIN];

export default {
  IsAtomUser: IsAtomUser,
  IsGlobalApprover: IsGlobalApprover,
  IsConfigApprover: IsConfigApprover,
  IsConfigWriter: IsConfigWriter,
  IsConfigReader: IsConfigReader,
  IsFileMoverWriter: IsFileMoverWriter,
  IsFileMoverReader: IsFileMoverReader,
  IsFileSetupApprover: IsFileSetupApprover,
  IsFileSetupWriter: IsFileSetupWriter,
  IsFileSetupReader: IsFileSetupReader,
  IsFileSplitWriter: IsFileSplitWriter,
  IsFileSplitReader: IsFileSplitReader,
  IsListPlusApprover: IsListPlusApprover,
  IsListPlusWriter: IsListPlusWriter,
  IsListPlusReader: IsListPlusReader,
  IsAiTranscriptSummarizationWriter: IsAiTranscriptSummarizationWriter,
  IsAiTranscriptSummarizationReader: IsAiTranscriptSummarizationReader,
  IsSchoolSettingsAdmin: IsSchoolSettingsAdmin,
};
