import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import SchoolSelectList from "components/partials/SchoolSelectList";
import Button from "components/partials/Button";
import { ClientContext } from "context/ClientContext";
import { AuthContext } from "context/AuthContext";
import apiRoot from "api/apiRoot";

const CreateConfig = () => {
  const [selectedFPT, setSelectedFPT] = useState("demographic");
  const fileProcessTypes = [
    "demographic",
    "funds",
    "financials",
    "term",
    "clearinghouse",
  ];
  const { currentClientId } = useContext(ClientContext);
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();
  const [configVersions, setConfigVersions] = useState([]);
  const [selectedConfigVersion, setSelectedConfigVersions] = useState("");

  useEffect(() => {
    if (currentClientId) {
      apiRoot
        .get(
          `/config/versionsByType?fileProcessType=${selectedFPT}&clientId=${currentClientId}`
        )
        .then((res) => {
          setConfigVersions(res.data.map((item) => item.id));
          setSelectedConfigVersions(res.data.map((item) => item.id)[0]);
        });
    }
  }, [currentClientId, selectedFPT]);

  const handleFPTSelect = (value) => {
    setSelectedFPT(value);
  };

  const handleCreate = () => {
    apiRoot
      .post(`/config/create`, {
        clientId: currentClientId,
        fileProcessType: selectedFPT,
        userId: user.id,
        versionId: selectedConfigVersion,
      })
      .then((res) => {
        navigate(
          `/clients/${currentClientId}/config/${res.data.parentConfigId}/versions/${res.data.id}`
        );
      });
  };

  const handleVersionSelect = (value) => {
    setSelectedConfigVersions(value);
  };

  return (
    <div className="flex flex-col gap-5">
      <div className="mt-5 flex w-[500px] gap-5">
        <div className="flex flex-col gap-1">
          <p>File Process Type</p>
          <SchoolSelectList
            options={fileProcessTypes.filter((item) => item !== selectedFPT)}
            onChange={handleFPTSelect}
            defaultValue={selectedFPT}
          />
        </div>
        <div className="flex w-[250px] flex-col gap-1">
          <p>Based On Version</p>
          <SchoolSelectList
            options={configVersions.filter(
              (item) => item !== selectedConfigVersion
            )}
            onChange={handleVersionSelect}
            defaultValue={selectedConfigVersion}
          />
        </div>
      </div>
      <Button
        label={"Create"}
        className="rounded-md bg-yellow-01 px-4 py-1 text-blue-03"
        onClick={handleCreate}
      />
    </div>
  );
};

export default CreateConfig;
