import React from "react";
import { useSelector } from "react-redux";

import Heading from "components/partials/heading/heading";
import TableComponent from "components/partials/table-component/table-component";

import { summaryTableSettings } from "pages/list-plus/batch/batch-details/summary/summary-table-settings";

const Summary = () => {
  const { summaryData } = useSelector((state) => state?.batch);

  return (
    <div className="flex w-[292px] flex-col">
      <Heading variant="h4" customClass="font-semibold text-gray-05 mb-2">
        Summary
      </Heading>
      <TableComponent
        columns={summaryTableSettings()}
        data={summaryData}
        pagination={false}
        lastRowBold={true}
      />
    </div>
  );
};

export default Summary;
