import React, { forwardRef, useState, useEffect } from "react";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import classNames from "classnames";

import Icons from "assets/icons";

const getBasicSelectStyles = (color, disabledColor) => ({
  control: (styles, { isDisabled }) => ({
    ...styles,
    boxShadow: "none",
    borderColor: color,
    outline: "none",
    backgroundColor: isDisabled ? disabledColor : "transparent",
    borderRadius: 6,

    "&:hover": {
      borderColor: color,
    },
  }),
  singleValue: (styles) => ({
    ...styles,
    color: "inherit",
  }),
});

const multiSelectOptionStyles = {
  multiValue: (styles) => ({
    ...styles,
    display: "flex",
    backgroundColor: "white",
    border: "1px solid #C0CBD8",
    padding: 2,
    margin: "2px 8px 2px 0",
    fontSize: 18,
  }),
  multiValueRemove: (styles, { isDisabled }) => ({
    ...styles,
    color: "#002D5D",
    display: isDisabled ? "none" : "flex",

    ":hover": {
      cursor: "pointer",
    },
  }),
};

const CustomSelect = forwardRef(
  (
    {
      label,
      id,
      fieldError = {},
      customClass = "",
      customSelectClass = "w-full",
      isSearchable = true,
      isOneRowLable = true,
      helperText = "",
      options,
      isMulti = false,
      onChange,
      value: _value,
      clearInputErr,
      disabled,
      disabledBgColor,
      labelInTop,
      isCreateble,
      extraStyles = () => {},
      required,
      placeholder = "Select...",
      ...rest
    },
    ref
  ) => {
    const [borderColor, setBorderColor] = useState("#C0CBD8");
    const [isNotValid, setIsNotValid] = useState(false);

    const [inputValue, setInputValue] = useState("");
    const [value, setValue] = useState(_value || []);

    useEffect(() => {
      if (_value && typeof _value !== "object") {
        const selected = options.find(
          (option) => option.value === _value || option.label === _value
        );

        setValue(selected);
      } else {
        setValue(_value);
      }
      // eslint-disable-next-line
    }, [_value]);

    const handleChange = (value) => {
      isNotValid && clearInputErr();

      setValue(value || []);
      onChange(value);
    };

    const handleKeyDown = (event) => {
      if (!inputValue) return;

      switch (event.keyCode) {
        case 9: //Tab
        case 13: //Enter
        case 32: //Space
          createValueFromInput(event);
          break;
        default:
      }
    };

    const createValueFromInput = (event) => {
      if (inputValue && inputValue.length > 0) {
        const newValue = [
          ...value,
          {
            label: inputValue,
            value: inputValue,
          },
        ];

        setInputValue("");
        setValue(newValue);

        onChange(newValue);

        event.preventDefault();
      }
    };

    const labelStyles = classNames("font-semibold mt-3", customClass, {
      "mb-5": !labelInTop,
      "h-16": !disabled && helperText && !isOneRowLable,
      "h-11": !disabled && helperText && isOneRowLable && !labelInTop,
      "flex h-6": isOneRowLable,
    });

    useEffect(() => {
      const isError = !!Object.keys(fieldError).length;

      setBorderColor(isError ? "#D91655" : "#C0CBD8");
      setIsNotValid(isError);
    }, [fieldError]);

    const CurrentSelect = isCreateble ? CreatableSelect : Select;

    const formatCreateLabel = (inputValue) => `Use "${inputValue}"`;
    const errorMessage = fieldError && fieldError?.message;

    return (
      <div className="flex w-full flex-col">
        <div
          className={classNames("flex", {
            "flex-col items-start": labelInTop,
            "items-center": !labelInTop,
          })}
        >
          {label && (
            <label htmlFor={id} className={labelStyles}>
              {label}
              {required ? (
                <Icons.RequiredIndicator customClass="w-[7px] h-[7px] ml-1 mt-1" />
              ) : null}
            </label>
          )}
          <div className={`flex flex-col ${customSelectClass}`}>
            {isMulti ? (
              <CreatableSelect
                id={id}
                styles={{
                  ...getBasicSelectStyles(borderColor, disabledBgColor),
                  ...multiSelectOptionStyles,
                }}
                ref={ref}
                components={{
                  DropdownIndicator: () => null,
                  IndicatorSeparator: () => null,
                }}
                placeholder="Type..."
                inputValue={inputValue}
                isClearable={false}
                isMulti
                menuIsOpen={false}
                onChange={(value) => handleChange(value)}
                onBlur={(event) => createValueFromInput(event)}
                onInputChange={(inputValue) => {
                  isNotValid && clearInputErr();
                  setInputValue(inputValue);
                }}
                onKeyDown={handleKeyDown}
                value={value}
                aria-labelledby="custom-select"
                isDisabled={disabled}
                {...rest}
              />
            ) : (
              <>
                <CurrentSelect
                  id={id}
                  styles={{
                    ...getBasicSelectStyles(borderColor, disabledBgColor),
                    ...extraStyles(borderColor, disabledBgColor),
                  }}
                  ref={ref}
                  isSearchable={isSearchable}
                  options={options}
                  value={value}
                  onChange={(value) => handleChange(value)}
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  aria-labelledby="custom-select"
                  placeholder={disabled ? "Not selected" : placeholder}
                  isDisabled={disabled}
                  formatCreateLabel={formatCreateLabel}
                  menuPlacement="auto"
                  menuPortalTarget={document.querySelector("body")}
                  {...rest}
                />
              </>
            )}
            {errorMessage && !disabled && (
              <span
                id="custom-select"
                className="font-sm text-red-00 bg-white pt-1"
                aria-errormessage={errorMessage}
              >
                {errorMessage}
              </span>
            )}
            {helperText && !disabled && !errorMessage && (
              <span id="custom-select" className="font-sm bg-white pt-1">
                {helperText}
              </span>
            )}
          </div>
        </div>
      </div>
    );
  }
);

export default CustomSelect;
