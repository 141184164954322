import React, { forwardRef } from "react";

const CustomButton = forwardRef(
  (
    {
      customClass = "",
      variant,
      size = "md",
      iconBefore,
      iconAfter,
      onClick,
      disabled,
      children,
      type = "button",
    },
    ref
  ) => {
    const sizeClass = {
      xs: "text-sm py-1 px-2",
      sm: "text-sm p-4 h-8",
      md: "text-base py-3 px-5 p-4 h-11",
      lg: "text-lg py-3 px-5 h-12",
    };
    const variantClass = {
      primary:
        "text-blue-03 bg-yellow-01 enabled:hover:bg-yellow-05 focus:bg-yellow-05 disabled:opacity-50",
      danger: "text-white bg-red-01 disabled:opacity-50",
      secondary:
        "border border-blue-01 text-blue-03 enabled:hover:bg-gray-00 focus:border-blue-03 disabled:opacity-50",
      text: "border-none text-blue-03 hover:text-blue-03 disabled:text-gray-05",
      inverse:
        "bg-blue-03 text-white hover:bg-blue-01 focus:bg-white focus:text-blue-03 focus:border-blue-03",
    };

    const defaultClass =
      "flex justify-center font-semibold items-center rounded-md";
    const disabledStyles = disabled ? "cursor-not-allowed" : "";
    const btnClassName = `${defaultClass} ${variantClass[variant]} ${sizeClass[size]} ${customClass} ${disabledStyles}`;

    return (
      <button
        className={btnClassName}
        onClick={onClick}
        disabled={disabled}
        ref={ref}
        type={type}
      >
        {!!iconBefore && <span className="pr-1 pt-px">{iconBefore}</span>}
        {children}
        {!!iconAfter && <span className="pl-1 pt-px">{iconAfter}</span>}
      </button>
    );
  }
);

export default CustomButton;
