import { Link as RouterLink } from "react-router-dom";

import CustomButton from "components/partials/custom-button/custom-button";

import { formatDate } from "helpers/dates-formatter";
import { getStatusLabel } from "components/partials/table-component/helpers/get-status-label";

const commonStyles = "border-r-2 border-white font-semibold";

export const listUploadsTableSettings = (batchStatuses) =>
  [
    {
      id: "name",
      accessorKey: "name",
      header: () => <span>BATCH NAME</span>,
      cell: (info) => (
        <span className="font-semibold">{info.renderValue()}</span>
      ),
      headerClassName: `${commonStyles} rounded-tl-md rounded-bl-md cursor-pointer`,
      className: "text-left pl-4 w-[14%]",
      enableSorting: true,
      sortingFn: "alphanumeric",
    },
    {
      id: "description",
      accessorKey: "description",
      header: () => <span>DESCRIPTION</span>,
      cell: (info) => info.renderValue(),
      headerClassName: `${commonStyles} cursor-context-menu`,
      className: "text-left pl-4 sm:w-[19%] py-4",
      enableSorting: false,
    },
    {
      id: "createdDate",
      accessorKey: "createdDate",
      header: () => <span>CREATED</span>,
      cell: (info) => formatDate(info.row.original.createdDate, "MMM D, YYYY"),
      headerClassName: `${commonStyles} cursor-pointer`,
      className: "pl-4 sm:w-[8%]",
      enableSorting: true,
    },
    {
      id: "publishedDate",
      accessorKey: "publishedDate",
      header: () => <span>PUBLISHED</span>,
      cell: (info) => {
        const date = info?.row?.original?.publishedDate;

        return date ? formatDate(date, "MMM D, YYYY") : "-";
      },
      headerClassName: `${commonStyles} cursor-pointer`,
      className: "pl-4 sm:w-[8%]",
      enableSorting: true,
    },
    {
      id: "fileCount",
      accessorKey: "fileCount",
      header: () => <span>TOTAL FILES</span>,
      cell: (info) => info?.row?.original?.fileCount || "-",
      isCellCentered: true,
      isHeaderCentered: true,
      headerClassName: `${commonStyles} cursor-pointer`,
      className: "text-left pl-4 sm:w-[8%] text-center",
      enableSorting: false,
    },
    {
      id: "recordCount",
      accessorKey: "recordCount",
      header: () => <span>TOTAL RECORDS</span>,
      cell: (info) => info?.row?.original?.recordCount || "-",
      isCellCentered: true,
      isHeaderCentered: true,
      headerClassName: `${commonStyles} cursor-pointer`,
      className: "text-left pl-4 sm:w-[10%] text-center",
      enableSorting: false,
    },
    {
      id: "status",
      accessorKey: "status",
      header: () => <span>STATUS</span>,
      cell: (info) => {
        const label = getStatusLabel(batchStatuses, info.row.original.status);

        return <div className="flex justify-center py-3">{label}</div>;
      },
      headerClassName: `${commonStyles} cursor-pointer`,
      className: "pl-4 sm:w-[17%]",
      isCellCentered: true,
      isHeaderCentered: true,
      enableSorting: true,
      sortingFn: "alphanumeric",
    },
    {
      id: "button-cell",
      header: () => <span className="font-semibold">ACTIONS</span>,
      cell: (info) => {
        return (
          <div className="flex justify-center">
            <RouterLink
              to={`/clients/${info.row.original.clientId}/list-plus/list-uploads/batch/${info.row.original.id}`}
            >
              <CustomButton variant="secondary" size="sm">
                View Details
              </CustomButton>
            </RouterLink>
          </div>
        );
      },
      isCellCentered: true,
      isHeaderCentered: true,
      headerClassName: "rounded-tr-md rounded-br-md",
      className: "py-4",
      enableSorting: false,
    },
  ].filter((item) => item);
