import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { Tooltip } from "react-tooltip";

import CustomSelect from "components/partials/custom-select/custom-select";
import CustomTextInput from "components/partials/custom-text-input/custom-text-input";

import Icons from "assets/icons";

const UploadedFilesFormCell = ({ cellName, options = [], row = {} }) => {
  const { control, errors } = useFormContext();

  switch (cellName) {
    case "list-source":
      return (
        <Controller
          control={control}
          name={`filesData.${row.id}.listSource`}
          defaultValue={row.original.listSource || ""}
          render={({ field }) => (
            <CustomSelect
              customSelectClass="w-full mx-4 py-2.5"
              isSearchable={false}
              options={options}
              value={options.filter(
                (option) => option.value === row.original.listSource
              )}
              {...field}
              onChange={(e) => {
                field.onChange(e.value);
              }}
            />
          )}
        />
      );
    case "code":
      const errMessage = errors?.filesData?.[row.id]?.code?.message;

      return (
        <div className="flex w-full items-center">
          <div className="w-10/12">
            <Controller
              control={control}
              name={`filesData.${row.id}.code`}
              defaultValue={row.original.code || ""}
              render={({ field }) => (
                <CustomTextInput
                  inputCustomClass="py-1.5 w-full"
                  placeholder="Code..."
                  isErrorMessageHidden={true}
                  errorsNames={
                    !!errors.filesData?.length && errors.filesData[row.id]?.code
                  }
                  {...field}
                  onChange={(e) => {
                    field.onChange(e.target.value);
                  }}
                />
              )}
            />
          </div>
          {!!errMessage && (
            <div className="w-1/12">
              <Icons.Info
                customClass="w-5 text-red-04 ml-1"
                data-tooltip-id="err-message-tooltip"
              />
              <Tooltip
                className="br-gray-05 z-50"
                id="err-message-tooltip"
                place="right"
                content={errMessage}
              />
            </div>
          )}
        </div>
      );
    case "segment-group":
      return (
        <Controller
          control={control}
          name={`filesData.${row.id}.segmentGroup`}
          defaultValue={row.original.segmentGroup || null}
          render={({ field }) => (
            <CustomSelect
              customSelectClass="w-full px-4 py-2.5"
              isSearchable={false}
              options={options}
              value={options.filter(
                (option) => option.value === row.original.segmentGroup
              )}
              {...field}
              onChange={(e) => {
                field.onChange(e.value);
              }}
            />
          )}
        />
      );
    default:
      return;
  }
};

export default UploadedFilesFormCell;
