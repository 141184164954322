import React, { useState, useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { ClientContext } from "context/ClientContext";
import Table from "components/Table";
import apiRoot from "api/apiRoot";
import { FileMappingsContext } from "context/FileMappingsContext";

const MappingVersions = () => {
  const { currentClientId } = useContext(ClientContext);
  const { setMappingVersionId, setJsonMapping, setIsEditing } =
    useContext(FileMappingsContext);
  const { configId } = useParams();
  const [mappingVersions, setMappingVersions] = useState([]);

  useEffect(() => {
    if (configId) {
      setJsonMapping({});
      setMappingVersionId();
      fetchMappingVersions();
      setIsEditing(false);
    }
    // eslint-disable-next-line
  }, [configId]);

  const fetchMappingVersions = async () => {
    const toastMessages = {
      pending: `Fetching File Mapping Versions`,
      success: `SUCCESS - File Mappings Versions Found`,
      error: "FAILED - Please Reach out to Support for Assistance",
    };

    const promise = apiRoot.get(
      `/filesetup/filemappings/versions?parentConfigId=${configId}`
    );
    const res = await toast.promise(promise, toastMessages);

    setMappingVersions(res.data);
  };

  const renderMappingVersions = () => {
    if (mappingVersions.length > 0) {
      const headers = [
        { header: "ID", dataKey: "id", additionalCssClass: "px-4 text-left" },
        {
          header: "STATUS",
          dataKey: "versionStatus",
          additionalCssClass: "px-4 text-center",
        },
        {
          header: "CREATED BY",
          dataKey: "versionCreatedBy",
          additionalCssClass: "px-4 text-center",
        },
        {
          header: "CREATE DATETIME",
          dataKey: "versionCreateDateTime",
          additionalCssClass: "px-4 text-center",
        },
        {
          header: "APPROVED BY",
          dataKey: "versionApprovedBy",
          additionalCssClass: "px-4 text-center",
        },
        {
          header: "APPROVED DATETIME",
          dataKey: "versionApprovedDateTime",
          additionalCssClass: "px-4 text-center",
        },
        {
          header: "LAST UPDATED BY",
          dataKey: "versionLastUpdatedBy",
          additionalCssClass: "px-4 text-center",
        },
        {
          header: "LAST UPDATE DATETIME",
          dataKey: "versionLastUpdateDateTime",
          additionalCssClass: "px-4 text-center",
        },
        {
          header: "VIEW",
          dataKey: "button",
          additionalCssClass: "px-4 text-center",
        },
      ];

      return (
        <Table
          tableAdditionalClassName={"w-[calc(100vw -300px)] max-w-[2000px]"}
          headers={headers}
          data={mappingVersions}
          buttonLabel={"VIEW"}
          buttonLabelClassName={"text-blue-03 font-normal"}
          buttonClassName="flex rounded-md bg-yellow-01 py-1 px-4 text-blue-03"
          buttonLinkTo={`/clients/${currentClientId}/file-mappings/${configId}/versions/{id}/`}
        />
      );
    } else {
      return "";
    }
  };

  return (
    <>
      <ToastContainer
        style={{ width: "500px", fontSize: "16px" }}
        progressStyle={{ backgroundColor: "#b6de3a" }}
      />
      <div>{renderMappingVersions()}</div>
    </>
  );
};

export default MappingVersions;
