import { useContext, useEffect } from "react";
import axiosInstance from "api/apiRoot";
import { AuthContext } from "context/AuthContext";

const WithAxios = ({ children }) => {
  const { acquireToken } = useContext(AuthContext);

  useEffect(() => {
    axiosInstance.interceptors.response.use(
      (response) => {
        return response;
      },
      async (error) => {
        acquireToken();
      }
    );
  }, [acquireToken]);

  return children;
};

export default WithAxios;
