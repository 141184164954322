import React, { useEffect, useContext, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";
import apiRoot from "../api/apiRoot";
import AsyncSelect from "react-select/async";
import { ClientContext } from "../context/ClientContext";
import debounce from "lodash.debounce";
import Button from "./partials/Button";
import Icons from "../assets/icons";
import PrivateNavLink from "./PrivateNavLink";
import appRoles, {
  AI_TRANSCRIPT_SUMMARIZATION_READER,
  AI_TRANSCRIPT_SUMMARIZATION_WRITER,
} from "config/appRoles";
import { InstitutionContext } from "context/InstitutionContext";
import NotificationPoint from "./partials/notification-point/notification-point";

const AppHeader = () => {
  const navigate = useNavigate();
  const { logOut, userName, onlyAuthorized } = useContext(AuthContext);
  const { setCurrentClientId } = useContext(ClientContext);
  const { queuedConfigs } = useContext(InstitutionContext);
  const [inputValue, setValue] = useState("");
  const [activePath, setActivePath] = useState("/");

  const location = useLocation();

  useEffect(() => {
    setActivePath(location.pathname);
  }, [location.pathname]);

  const handleChange = (value) => {
    setCurrentClientId(value.id);
    navigate(`clients/${value.id}/file-split`);
    setValue(null);
  };

  const _loadOptions = (queryTerm, callback) => {
    apiRoot
      .get(`/client/search?searchTerm=${queryTerm}`)
      .then((res) => callback(res.data));
  };

  const loadOptions = debounce(_loadOptions, 500);

  const onlyAiTranscript =
    onlyAuthorized(AI_TRANSCRIPT_SUMMARIZATION_READER) ||
    onlyAuthorized(AI_TRANSCRIPT_SUMMARIZATION_WRITER);

  const activeStyles =
    "underline underline-offset-[23px] decoration-[3px] decoration-yellow-01";

  function renderDefaultAppLinks() {
    return (
      <>
        <div className="w-[580px] pl-5">
          <div className="pr-9">
            <AsyncSelect
              placeholder="Search for Institution"
              value={inputValue}
              getOptionLabel={(e) =>
                `${e.institutionName} - ${e.schoolKey} - ${e.blobContainer}`
              }
              getOptionValue={(e) => e.id}
              loadOptions={loadOptions}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="flex gap-5">
          <Link
            to={"/"}
            className={`mr-auto flex ${activePath === "/" ? activeStyles : ""}`}
          >
            <div className="flex items-center gap-1 text-gray-03">
              <Icons.Clients className="w-6" />
              My Clients
            </div>
          </Link>

          <PrivateNavLink
            to={"/approve"}
            className={`mr-auto flex`}
            allowedAppRoles={appRoles.IsConfigApprover}
          >
            <div className="flex items-center gap-1 text-gray-03">
              <Icons.Edit className="w-6" />
              <span
                className={`${activePath === "/approve" ? activeStyles : ""}`}
              >
                Approve Configs
              </span>
              {queuedConfigs.length > 0 ? (
                <NotificationPoint num={queuedConfigs.length} />
              ) : (
                <></>
              )}
            </div>
          </PrivateNavLink>

          <PrivateNavLink
            to={"/summarize"}
            className={`mr-auto flex ${
              activePath === "/summarize" ? activeStyles : ""
            }`}
            allowedAppRoles={appRoles.IsAiTranscriptSummarizationReader}
          >
            <div className="flex items-center gap-1 text-gray-03">
              <div className="pt-1">
                <Icons.AiTranscriptSummary className="w-6" />
              </div>
              <span>Summarize</span>
            </div>
          </PrivateNavLink>
        </div>
      </>
    );
  }

  function renderOnlyAiAppLinks() {
    return (
      <PrivateNavLink
        to={"/"}
        className={`mr-auto flex ${activeStyles}`}
        allowedAppRoles={appRoles.IsAiTranscriptSummarizationReader}
      >
        <div className="flex items-center gap-1 pl-5 text-gray-03">
          <div className="pt-1">
            <Icons.AiTranscriptSummary className="w-6" />
          </div>
          <span>Summarize</span>
        </div>
      </PrivateNavLink>
    );
  }

  return (
    <div className="mb-1 flex h-16 items-center justify-between shadow-bs-primary">
      <div className="flex h-full w-64 items-center bg-blue-03 pl-10 pr-16">
        <Link to={"/"}>
          <Icons.AtomLogo className="h-12 w-24" />
        </Link>
      </div>
      <div className="flex w-full items-center text-base font-medium text-gray-03">
        {onlyAiTranscript ? renderOnlyAiAppLinks() : renderDefaultAppLinks()}
      </div>
      <a
        href="https://app.marketviewedu.com/admin/clients/"
        target="_blank"
        className="ml-auto"
        rel="noreferrer"
      >
        <div className="flex items-center gap-1 text-gray-03">
          <Icons.MarketviewLogo />
        </div>
      </a>

      <div className="flex flex-col whitespace-nowrap px-6 pb-1">
        {userName ? `Wecome, ${userName}` : ""}
        <Button
          label={"Log Out"}
          className="rounded-md bg-yellow-01 px-4 py-1 text-blue-03"
          onClick={() => {
            navigate("/");
            logOut();
          }}
        />
      </div>
    </div>
  );
};

export default AppHeader;
