import React, { createContext, useState, useContext } from "react";
import { readebleFileSize } from "components/partials/file-uploader/file-uploader.helper";
import { AuthContext } from "./AuthContext";
import apiRoot from "../api/apiRoot";
import { toast } from "react-toastify";

export const TranscriptSummarizationContext = createContext();

export const TranscriptSummarizationStore = ({ children }) => {
  const { user } = useContext(AuthContext);
  const [transcriptUploads, setTranscriptUploads] = useState([]);
  const [linkedClient, setLinkedClient] = useState("");
  const [currentTranscriptSummary, setCurrentTranscriptSummary] = useState("");
  const [isSummarizing, setIsSummarizing] = useState(false);

  const onTranscriptFileRemove = () => {
    setTranscriptUploads([]);
    setCurrentTranscriptSummary("");
  };

  const onTranscriptFileUpload = (files) => {
    if (files?.length && files.length >= 1) {
      setTranscriptUploads([
        {
          FileName: files[0].name,
          LinkedClient: linkedClient,
          FileSize: readebleFileSize(files[0].size),
          File: files[0],
        },
      ]);
    }
  };

  const updateLinkedClient = (client) => {
    if (transcriptUploads[0]) {
      setTranscriptUploads([{ ...transcriptUploads[0], LinkedClient: client }]);
    }
    setLinkedClient(client);
  };

  const handleTranscriptFileUpload = async () => {
    const toastId = toast.loading(
      "Uploading, Saving, and Summarizing Transcript"
    );
    setIsSummarizing(true);
    let formData = new FormData();

    // This first entry needs to be the same for each file for this to play well with java backend
    formData.append(
      `transcriptfiles`,
      transcriptUploads[0].File,
      `${transcriptUploads[0].FileName}`
    );

    const res = await apiRoot.post(
      `/summarize/upload?userId=${user.id}&clientId=${linkedClient?.id}`,
      formData
    );
    if (!res?.status) {
      toast.update(toastId, {
        render:
          "FAILED - Unable to summarize transcript. Retry or reach out to support for assistance!",
        type: "error",
        isLoading: false,
        autoClose: 5000,
        progressStyle: { backgroundColor: "#fc0303" },
      });
      setIsSummarizing(false);
      return;
    }

    toast.update(toastId, {
      render:
        "Transcript successfully summarized! If you don't see it reach out to support.",
      type: "success",
      isLoading: false,
      autoClose: 5000,
      progressStyle: { backgroundColor: "#b6de3a" },
    });

    setCurrentTranscriptSummary(res?.data);
    setLinkedClient("");
    setTranscriptUploads([]);
    setIsSummarizing(false);
  };

  const value = {
    transcriptUploads: transcriptUploads,
    currentTranscriptSummary: currentTranscriptSummary,
    handleTranscriptFileUpload: handleTranscriptFileUpload,
    onTranscriptFileUpload: onTranscriptFileUpload,
    onTranscriptFileRemove: onTranscriptFileRemove,
    setLinkedClient: setLinkedClient,
    updateLinkedClient: updateLinkedClient,
    isSummarizing: isSummarizing,
  };

  return (
    <TranscriptSummarizationContext.Provider value={value}>
      {children}
    </TranscriptSummarizationContext.Provider>
  );
};
