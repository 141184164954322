import React, { useContext, useState } from "react";
import { useSelector } from "react-redux";

import Heading from "components/partials/heading/heading";
import Loader from "components/partials/loader/loader";
import Summary from "pages/list-plus/batch/batch-details/summary/summary";
import BatchMessage from "pages/list-plus/batch/batch-message/batch-message";

import { ListPlusContext } from "context/ListPlusContext";

import { getStatusLabel } from "components/partials/table-component/helpers/get-status-label";

import { handleCopyClick } from "helpers/apply-actions";
import { getIsBatchMessageShown } from "pages/list-plus/batch/batch-message/batch-message.helper";

import Icons from "assets/icons";

const BatchDetails = () => {
  const { isBatchLoading, batchData } = useSelector((state) => state?.batch);

  const { batchStatuses } = useContext(ListPlusContext);

  const [isBatchIdCopied, setIsBatchIdCopied] = useState(false);

  const isBatchMessageShown = getIsBatchMessageShown(batchData);

  return !isBatchLoading ? (
    <>
      <Heading variant="h2" customClass="font-semibold">
        Batch Details
      </Heading>
      {isBatchMessageShown && <BatchMessage />}
      <div className="mt-2.5 flex gap-x-12">
        <Summary />
        <div className="flex w-1/3 flex-col text-gray-05">
          <div className="h-[5rem]">
            <Heading variant="h3" customClass="font-semibold mb-2 text-gray-05">
              Status
            </Heading>
            <div className="mb-6">
              {getStatusLabel(batchStatuses, batchData.status)}
            </div>
          </div>
          <Heading variant="h3" customClass="font-semibold mb-2 text-gray-05">
            Description
          </Heading>
          <span>{batchData.description}</span>
          <Heading
            variant="h3"
            customClass="font-semibold mb-2 mt-4 text-gray-05"
          >
            Batch ID
          </Heading>
          <div className="flex">
            <span>{batchData.id}</span>
            <Icons.Copy
              title="Copy batch ID"
              customClass="w-4 h-4 mt-1 ml-2 not-sr-only text-gray-09 cursor-pointer"
              onClick={() => handleCopyClick(batchData.id, setIsBatchIdCopied)}
            />
          </div>
        </div>
        <div className="flex w-1/3 flex-col">
          <div className="h-[5rem]">
            <Heading variant="h3" customClass="font-semibold mb-2 text-gray-05">
              Created by
            </Heading>
            <span>{batchData.createdByUsername}</span>
          </div>
          <Heading variant="h3" customClass="font-semibold text-gray-05 mb-2">
            Files parameters
          </Heading>
          <span className="mb-2 text-gray-08">
            List Source Splitting: <span className="text-gray-05">Yes</span>
          </span>
          <span className="text-gray-08">
            Max File Size: <span className="text-gray-05">16MB</span>
          </span>
        </div>
      </div>
    </>
  ) : (
    <Loader />
  );
};

export default BatchDetails;
