import { configureStore } from "@reduxjs/toolkit";

import batchSlice from "state/batch/batch.slice";
import filesSlice from "state/files/files.slice";

const store = configureStore({
  reducer: {
    files: filesSlice,
    batch: batchSlice,
  },
});

export default store;
