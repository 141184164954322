import { formatDate } from "helpers/dates-formatter";

//please pay attention and DO NOT change tabs and formatting anyhow - this is a string to copy to the clipboard
export const getProcessedFilesTextToCopy = (batchData, client) => {
  let stringToCopy = `
   ${batchData.name} with ID ${batchData.id} on ${formatDate(
    batchData.createdDate,
    "MMM D, YYYY h:mm A"
  )}

    The following files are ready for publishing to ${
      client?.institutionName
    }:`;

  for (let i = 0; i < batchData?.processedFiles?.files.length; i++) {
    const file = batchData.processedFiles.files[i];

    for (let u = 0; u < file.stats.length; u++) {
      const stat = file.stats[u];

      if (!u) {
        stringToCopy += `
  
      ${file.fileName}
      File Count     List Source Provider    Intended Term
      ${stat.fileRowCount}               ${stat.listSourceProvider}              ${stat.intendedTerm}`;
      } else {
        stringToCopy += `
      ${stat.fileRowCount}               ${stat.listSourceProvider}               ${stat.intendedTerm}`;
      }
    }
  }

  return stringToCopy;
};
