import React, { useContext } from "react";
import { Routes, Route, Outlet } from "react-router-dom";
import { uniqueId } from "lodash";
import { AuthContext } from "context/AuthContext";
import { FileMappingsContext } from "context/FileMappingsContext";
import DisplayMappings from "./display-mappings";
import appRoles from "config/appRoles";
import PrivateRoutes from "components/PrivateRoutes";
import CreateMappings from "./create-mappings";
import MappingVersions from "./mappings-versions";

import PrivateNavLink from "components/PrivateNavLink";

const FileMappings = () => {
  const { isAuthorized } = useContext(AuthContext);
  const {
    clientMappings,
    setCurrentFPST,
    setCurrentFPT,
    isEditing,
    currentFPT,
    currentFPST,
  } = useContext(FileMappingsContext);

  const defaultCssName =
    "cursor-pointer rounded-md border border-solid border-gray-02 bg-white px-4 py-2 text-base leading-6 text-gray-04 no-underline shadow-bs-secondary ";
  const activeCssStyles =
    "font-semibold border-2 border-solid border-yellow-01";

  const renderCreateMapping = () => {
    if (isAuthorized(appRoles.IsFileSetupWriter) && !isEditing) {
      return (
        <div className="flex flex-col">
          <p className="pb-3 text-base font-semibold leading-5 text-blue-03">
            Create Mapping
          </p>
          <PrivateNavLink
            to={`create`}
            className={(navData) =>
              navData.isActive
                ? `${activeCssStyles} ${defaultCssName}`
                : defaultCssName
            }
            key={uniqueId()}
            allowedAppRoles={appRoles.IsFileSetupWriter}
          >
            Create New Mapping
          </PrivateNavLink>
        </div>
      );
    }
  };

  const renderMappings = () => {
    if (clientMappings && clientMappings.length > 0 && !isEditing) {
      return clientMappings.map((item) => {
        return (
          <div className="flex flex-col justify-start" key={uniqueId()}>
            <div className="flex w-max gap-2">
              <PrivateNavLink
                to={`${item.id}/versions`}
                className={(navData) =>
                  navData.isActive
                    ? `${activeCssStyles} ${defaultCssName}`
                    : defaultCssName
                }
                key={uniqueId()}
                allowedAppRoles={appRoles.IsFileSetupReader}
                onNavLinkClick={() => {
                  setCurrentFPT(item.fileProcessType);
                  setCurrentFPST(item.fileProcessSubType);
                }}
              >
                {item.fileProcessType} - {item.fileProcessSubType}
              </PrivateNavLink>
            </div>
          </div>
        );
      });
    } else if (!isEditing) {
      return <div>No Mappings</div>;
    } else {
      return (
        <div className="text-base font-semibold leading-5 text-blue-03">
          Currently Editing: {currentFPT} - {currentFPST}
        </div>
      );
    }
  };

  return (
    <>
      <div className="flex flex-col">
        <div className="flex gap-5">
          {renderCreateMapping()}
          <div className="flex flex-col">
            <p className="pb-3 text-base font-semibold leading-5 text-blue-03">
              {isEditing ? "" : "View Mappings"}
            </p>
            <div className="flex gap-5">{renderMappings()}</div>
          </div>
        </div>
      </div>
      <div className="pt-3">
        <Routes>
          <Route
            element={
              <PrivateRoutes allowedAppRoles={appRoles.IsFileSetupReader} />
            }
          >
            <Route
              exact
              path=":configId/versions/*"
              element={<MappingVersions />}
            />
            <Route
              element={
                <PrivateRoutes allowedAppRoles={appRoles.IsFileSetupReader} />
              }
            >
              <Route
                exact
                path=":configId/versions/:versionId/*"
                element={<DisplayMappings />}
              />
            </Route>
          </Route>
          <Route
            element={
              <PrivateRoutes allowedAppRoles={appRoles.IsFileSetupWriter} />
            }
          >
            <Route exact path="create" element={<CreateMappings />}></Route>
          </Route>
        </Routes>
        <Outlet />
      </div>
    </>
  );
};

export default FileMappings;
