import React from "react";
import Table from "components/Table";

const ConfigViewer = ({
  srcJson,
  contenteditable,
  handleEditChange,
  isDelete,
  handleDelete,
}) => {
  // This needs refactored
  const renderJsonObject = () => {
    if (Object.keys(srcJson).length > 0 && "ConfigLogic" in srcJson) {
      if (isDelete) {
        const headers = [
          {
            header: "ORDER",
            dataKey: "ExecutionOrder",
            additionalCssClass: "px-4 text-left",
            contenteditable: false,
          },
          {
            header: "HEADER",
            dataKey: "ColumnHeader",
            additionalCssClass: "px-14 text-left",
            contenteditable: contenteditable && true,
          },
          {
            header: "LOGIC",
            dataKey: "Logic",
            additionalCssClass: "px-14 text-left",
            contenteditable: contenteditable && true,
          },
          {
            header: "REMOVE",
            dataKey: "button",
            additionalCssClass: "px-4 text-center",
            contenteditable: false,
          },
        ];
        return (
          <div className="">
            <Table
              tableAdditionalClassName={"w-[955px]"}
              headers={headers}
              data={srcJson}
              contenteditable={contenteditable}
              handleEditChange={handleEditChange}
              buttonFunction={handleDelete}
              buttonLabel={"Remove"}
              buttonLabelClassName={"text-blue-03 font-semibold"}
              buttonClassName="mx-auto my-0 flex cursor-pointer items-center gap-1 rounded-md border border-solid border-blue-01 bg-white px-3 py-2"
            />
          </div>
        );
      } else {
        const headers = [
          {
            header: "ORDER",
            dataKey: "ExecutionOrder",
            additionalCssClass: "px-4 text-left",
            contenteditable: false,
          },
          {
            header: "HEADER",
            dataKey: "ColumnHeader",
            additionalCssClass: "px-14 text-left",
            contenteditable: contenteditable && true,
          },
          {
            header: "LOGIC",
            dataKey: "Logic",
            additionalCssClass: "px-14 text-left",
            contenteditable: contenteditable && true,
          },
        ];
        return (
          <div className="">
            <Table
              tableAdditionalClassName={"w-[955px]"}
              headers={headers}
              data={srcJson}
              contenteditable={contenteditable}
              handleEditChange={handleEditChange}
            />
          </div>
        );
      }
    } else {
      if (Array.isArray(srcJson)) {
        if (isDelete) {
          const headers = [
            {
              header: "ORDER",
              dataKey: "ExecutionOrder",
              additionalCssClass: "px-4 text-left",
              contenteditable: false,
            },
            {
              header: "HEADER",
              dataKey: "ColumnHeader",
              additionalCssClass: "px-14 text-left",
              contenteditable: contenteditable && true,
            },
            {
              header: "LOGIC",
              dataKey: "Logic",
              additionalCssClass: "px-14 text-left",
              contenteditable: contenteditable && true,
            },
            {
              header: "REMOVE",
              dataKey: "button",
              additionalCssClass: "px-4 text-center",
              contenteditable: false,
            },
          ];
          return (
            <div className="">
              <Table
                tableAdditionalClassName={"w-[955px]"}
                headers={headers}
                data={srcJson}
                contenteditable={contenteditable}
                handleEditChange={handleEditChange}
                buttonFunction={handleDelete}
                buttonLabel={"Remove"}
                buttonLabelClassName={"text-blue-03 font-semibold"}
                buttonClassName="mx-auto my-0 flex cursor-pointer items-center gap-1 rounded-md border border-solid border-blue-01 bg-white px-3 py-2"
              />
            </div>
          );
        } else {
          const headers = [
            {
              header: "ORDER",
              dataKey: "ExecutionOrder",
              additionalCssClass: "px-4 text-left",
              contenteditable: false,
            },
            {
              header: "HEADER",
              dataKey: "ColumnHeader",
              additionalCssClass: "px-14 text-left",
              contenteditable: contenteditable && true,
            },
            {
              header: "LOGIC",
              dataKey: "Logic",
              additionalCssClass: "px-14 text-left",
              contenteditable: contenteditable && true,
            },
          ];
          return (
            <div className="">
              <Table
                tableAdditionalClassName={"w-[955px]"}
                headers={headers}
                data={srcJson}
                contenteditable={contenteditable}
                handleEditChange={handleEditChange}
              />
            </div>
          );
        }
      } else {
        const headers = [
          {
            header: "KEY",
            dataKey: "key",
            additionalCssClass: "px-4 text-left",
            contenteditable: false,
          },
          {
            header: "VALUE",
            dataKey: "value",
            additionalCssClass: "px-14 text-left",
            contenteditable: true,
          },
        ];
        return (
          <div className="">
            <Table
              tableAdditionalClassName={"w-[955px]"}
              headers={headers}
              data={srcJson}
              contenteditable={contenteditable}
              handleEditChange={handleEditChange}
            />
          </div>
        );
      }
    }
  };

  return renderJsonObject();
};

export default ConfigViewer;
