import React, { useState, useEffect, useContext } from "react";
import SchoolSelectList from "./partials/SchoolSelectList";
import { ClientContext } from "../context/ClientContext";
import { AuthContext } from "context/AuthContext";
import apiRoot from "../api/apiRoot";
import appRoles from "config/appRoles";

const AssignBlobContainer = ({ defaultValue }) => {
  const [availableContainers, setAvailableContainers] = useState([]);
  const { updateBlobContainer } = useContext(ClientContext);
  const { isAuthorized } = useContext(AuthContext);
  const [selectedBlobContainer, setSelectedBlobContainer] = useState("");

  useEffect(() => {
    if (defaultValue) {
      setSelectedBlobContainer(defaultValue);
    }
  }, [defaultValue]);

  useEffect(() => {
    apiRoot
      .get("/client/containers")
      .then((res) => setAvailableContainers(res.data));
  }, [selectedBlobContainer]);

  function onSchoolSelect(value) {
    updateBlobContainer(value);
    setSelectedBlobContainer(value);
  }

  return (
    <div>
      <p className="text-gray-05 font-semibold">Blob Container</p>
      <div className="onboard-select-container">
        {isAuthorized(appRoles.IsSchoolSettingsAdmin) ? (
          <SchoolSelectList
            options={availableContainers}
            onChange={onSchoolSelect}
            defaultValue={selectedBlobContainer}
          />
        ) : (
          <p>{defaultValue}</p>
        )}
      </div>
    </div>
  );
};

export default AssignBlobContainer;
