import React from "react";
import Table from "components/Table";

const MappingViewer = ({
  srcJson,
  contenteditable,
  handleEditChange,
  isDelete,
  handleDelete,
  fileProcessType,
  fileProcessSubType,
}) => {
  const renderJsonObject = () => {
    if (isDelete) {
      const headers = [
        {
          header: "STANDARDIZED",
          dataKey: "key",
          additionalCssClass: "px-4 text-left",
          contenteditable: contenteditable && true,
        },
        {
          header: "CLIENT",
          dataKey: "value",
          additionalCssClass: "px-14 text-left",
          contenteditable: contenteditable && true,
        },
        {
          header: "REMOVE",
          dataKey: "button",
          additionalCssClass: "px-4 text-center",
          contenteditable: false,
        },
      ];
      return (
        <div className="">
          <Table
            tableAdditionalClassName={"w-[955px]"}
            headers={headers}
            data={srcJson}
            contenteditable={contenteditable}
            handleEditChange={handleEditChange}
            buttonFunction={handleDelete}
            buttonLabel={"Remove"}
            buttonLabelClassName={"text-blue-03 font-semibold"}
            buttonClassName="mx-auto my-0 flex cursor-pointer items-center gap-1 rounded-md border border-solid border-blue-01 bg-white px-3 py-2"
            fileProcessType={fileProcessType}
            fileProcessSubType={fileProcessSubType}
          />
        </div>
      );
    } else {
      const headers = [
        {
          header: "STANDARDIZED",
          dataKey: "key",
          additionalCssClass: "px-4 text-left",
          contenteditable: contenteditable && true,
        },
        {
          header: "CLIENT",
          dataKey: "value",
          additionalCssClass: "px-14 text-left",
          contenteditable: contenteditable && true,
        },
      ];
      return (
        <div className="">
          <Table
            tableAdditionalClassName={"w-[955px]"}
            headers={headers}
            data={srcJson}
            contenteditable={contenteditable}
            handleEditChange={handleEditChange}
            fileProcessType={fileProcessType}
            fileProcessSubType={fileProcessSubType}
          />
        </div>
      );
    }
  };

  return renderJsonObject();
};

export default MappingViewer;
