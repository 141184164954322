import React from "react";

import { BATCHES_STATUSES } from "pages/list-plus/batch/batch.constants";

import Icons from "assets/icons";

export const getStatusLabel = (batchStatuses, status) => {
  const batchStatus = batchStatuses.find((el) => el.name === status);

  const defaultIconProps = {
    width: "18px",
    height: "100%",
    className: "pb-0.5",
  };

  const commonStyles =
    "flex items-center text-xs font-semibold py-1.5 px-2 rounded-md w-fit";

  const commonValueStyles = "pl-1 first-letter:uppercase";

  switch (status) {
    case BATCHES_STATUSES.CONFIGURING:
      return (
        <div className={`${commonStyles} bg-gray-06 text-gray-05`}>
          <Icons.Configuring
            aria-label="Configuring status"
            {...defaultIconProps}
          />
          <span className={commonValueStyles}>{batchStatus?.description}</span>
        </div>
      );
    case BATCHES_STATUSES.RECONFIGURING:
      return (
        <div className={`${commonStyles} bg-gray-06 text-gray-05`}>
          <Icons.Configuring
            aria-label="Reconfiguring status"
            {...defaultIconProps}
          />
          <span className={commonValueStyles}>{batchStatus?.description}</span>
        </div>
      );
    case BATCHES_STATUSES.PROCESSING:
    case BATCHES_STATUSES.PUBLISHING:
      return (
        <div className={`${commonStyles} bg-yellow-03 text-yellow-02`}>
          <Icons.Processing
            aria-label="Procesing status"
            {...defaultIconProps}
          />
          <span className={commonValueStyles}>{batchStatus?.description}</span>
        </div>
      );
    case BATCHES_STATUSES.READY_FOR_QA:
      return (
        <div className={`${commonStyles} bg-blue-04 text-blue-05`}>
          <Icons.ReadyForQA
            aria-label="Ready for QA status"
            {...defaultIconProps}
          />
          <span className={commonValueStyles}>{batchStatus?.description}</span>
        </div>
      );
    case BATCHES_STATUSES.PROCESSING_FAILED:
    case BATCHES_STATUSES.PUBLISHING_FAILED:
      return (
        <div className={`${commonStyles} bg-red-02 text-red-01`}>
          <Icons.Warning
            aria-label="Procesing failed status"
            {...defaultIconProps}
          />
          <span className={commonValueStyles}>{batchStatus?.description}</span>
        </div>
      );
    case BATCHES_STATUSES.PUBLISHED:
      return (
        <div className={`${commonStyles} bg-green-02 text-green-01`}>
          <Icons.Success aria-label="Published status" {...defaultIconProps} />
          <span className={commonValueStyles}>{batchStatus?.description}</span>
        </div>
      );
    default:
      return;
  }
};
