import React, { useContext, useRef } from "react";
import { useEffect } from "react";
import apiRoot from "api/apiRoot";
import { ClientContext } from "context/ClientContext";

const SearchFile = ({ label, setSearchResults, searchTerm, setSearchTerm }) => {
  const timeout = useRef();
  const { currentClientId } = useContext(ClientContext);

  useEffect(() => {
    if (currentClientId && !searchTerm) {
      apiRoot
        .get(`/files?clientId=${currentClientId}`)
        .then((res) => setSearchResults(res.data));
    }
    // eslint-disable-next-line
  }, [currentClientId, searchTerm]);

  function handleFileSearch(e) {
    e.preventDefault();
    setSearchTerm(e.target.value);
    clearTimeout(timeout.current);
    timeout.current = setTimeout(() => {
      if (e.target.value && currentClientId) {
        apiRoot
          .get(
            `/files/search?term=${encodeURIComponent(
              e.target.value
            )}&clientId=${currentClientId}`
          )
          .then((res) => setSearchResults(res.data));
      }
    }, 500);
  }

  return (
    <div className="flex flex-col">
      <div className="pb-4 text-xl font-bold leading-6 text-blue-03">
        {label}
      </div>
      <input
        type="text"
        value={searchTerm}
        onChange={(e) => handleFileSearch(e)}
        className="h-10 w-[547px] rounded-md border border-solid border-gray-02 bg-white px-3 py-2 focus:outline-none focus-visible:outline-none"
        placeholder="Search for file..."
      />
    </div>
  );
};

export default SearchFile;
