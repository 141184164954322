import React, { useState, useContext, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import apiRoot from "api/apiRoot";
import { ClientContext } from "context/ClientContext";
import Icons from "assets/icons";
import FileSearch from "components/FileSearch";
import TabBar from "components/partials/TabBar";
import Table from "components/Table";
import appRoles from "config/appRoles";

const MoveFiles = () => {
  const [selectedEnv, setSelectedEnv] = useState("UAT");
  const { currentClientId } = useContext(ClientContext);
  const [searchResults, setSearchResults] = useState([]);
  const [searchTerm, setSearchTerm] = useState();
  const types = ["Demographic", "Financials", "Funds", "Term", "Clearinghouse"];

  useEffect(() => {
    if (!searchTerm) {
      setSearchResults([]);
    }
  }, [searchTerm]);

  function handleMoveFile(item) {
    // This is gross but TLDR; With SFTP 3 migration the file path is now /{blobcontainer}/sftp-{blobcontainer}/uploads/{filename}
    // We need to split just the first two instances of the / to get the blobcontainer and then the file name being sftp ->
    const fileName = item.filePath.replace(/^\/[^/]+\/[^/]+\//, "");

    const blobContainer = item.filePath.split("/", 2)[1];

    toast.promise(
      apiRoot.post("/files/move", {
        fileName: fileName,
        selectedEnv: selectedEnv,
        client: blobContainer,
      }),
      {
        pending: "Attempting to move file " + blobContainer + "/" + fileName,
        success:
          "Success! " +
          blobContainer +
          "/" +
          fileName +
          " moved to " +
          selectedEnv,
        error:
          "Failed to move " +
          blobContainer +
          "/" +
          fileName +
          " to " +
          selectedEnv,
      }
    );
  }

  function onLatestFileButtonClick(type) {
    toast.promise(
      apiRoot.get(
        `/files/moveLatest?fileProcessType=${type}&clientId=${currentClientId}&env=${selectedEnv}`
      ),
      {
        pending: "Moving Latest Files for " + type + " File Masks",
        success: "Moved Latest Files for " + type + " File Masks!",
        error: "Failed to find Latest Files for " + type + " File Masks",
      }
    );
  }

  const renderSearchResults = () => {
    if (searchResults.length > 0) {
      const headers = [
        {
          header: "BLOB CONTAINER",
          dataKey: "blobContainer",
          additionalCssClass: "px-4 text-center",
        },
        {
          header: "FILE PATH",
          dataKey: "filePath",
          additionalCssClass: "px-14 text-left",
        },
        {
          header: "UPLOAD DATETIME",
          dataKey: "insertDateTime",
          additionalCssClass: "px-14 text-left",
        },
        {
          header: "MOVE",
          dataKey: "button",
          additionalCssClass: "px-4 text-center",
        },
      ];

      return (
        <div className="pt-4">
          <p className="text-blue-03 pb-1 text-base font-bold leading-5">
            {searchTerm
              ? `Search results for "${searchTerm}"`
              : `Recent Uploads`}
          </p>
          <Table
            tableAdditionalClassName={"w-[calc(100vw -300px)] max-w-[1750px]"}
            headers={headers}
            data={searchResults}
            buttonFunction={handleMoveFile}
            buttonIconLeft={<Icons.SplitFile />}
            buttonLabel={"Move"}
            buttonLabelClassName={"text-blue-03 font-semibold"}
            buttonClassName="mx-auto my-0 flex cursor-pointer items-center gap-1 rounded-md border border-solid border-blue-01 bg-white px-3 py-2"
            buttonAllowedAppRoles={appRoles.IsFileMoverWriter}
          />
        </div>
      );
    } else {
      return "";
    }
  };

  return (
    <>
      <ToastContainer
        style={{ width: "450px", fontSize: "16px" }}
        progressStyle={{ backgroundColor: "#b6de3a" }}
      />
      <div className="flex gap-8">
        <TabBar
          values={["DEV", "UAT"]}
          label={"Environment"}
          selectedValue={selectedEnv}
          onValueChange={setSelectedEnv}
        />
        <FileSearch
          setSearchResults={setSearchResults}
          searchLabel={"Search for file..."}
          lastestButtonsLabel={"Move Latest"}
          setSearchTerm={setSearchTerm}
          searchTerm={searchTerm}
          latestFileTypes={types}
          onLatestFileButtonClick={onLatestFileButtonClick}
          allowAppRoles={appRoles.IsFileMoverWriter}
        />
      </div>
      {renderSearchResults()}
    </>
  );
};

export default MoveFiles;
