import React from "react";

const TabBar = ({ values, label, selectedValue, onValueChange }) => {
  function renderTabBar() {
    const defaultCSSName = "flex font-semibold text-base leading-6 px-4 py-2";
    const activeCSSName =
      "bg-white shadow-bs-secondary rounded-md h-10 items-center";

    return values.map((value) => {
      return (
        <div
          className={`${
            value === selectedValue ? activeCSSName : ""
          } ${defaultCSSName}`}
          onClick={() => onValueChange(value)}
          key={value}
        >
          {value}
        </div>
      );
    });
  }

  return (
    <div className="flex flex-col">
      <div className="text-blue-03 pb-2 text-xl font-bold leading-6">
        {label}
      </div>
      <div className="bg-yellow-01 flex h-12 cursor-pointer items-center justify-center gap-1 rounded-md p-1">
        {renderTabBar()}
      </div>
    </div>
  );
};

export default TabBar;
