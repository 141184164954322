import React, { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FileMasksContext } from "context/FileMasksContext";
import SchoolSelectList from "components/partials/SchoolSelectList";
import { ClientContext } from "context/ClientContext";
import Button from "components/partials/Button";
import apiRoot from "api/apiRoot";
import { toast } from "react-toastify";

const CreateMask = ({ handleCancel }) => {
  const { handleFileMaskCreate } = useContext(FileMasksContext);
  const { currentClientId } = useContext(ClientContext);
  const navigate = useNavigate();
  const [selectedFPT, setSelectedFPT] = useState();
  const [selectedFPST, setSelectedFPST] = useState();
  const [selectedMappingVersion, setSelectedMappingVersions] = useState();
  const [mappingVersionFound, setMappingVersionFound] = useState(false);

  const fileProcessTypes = [
    "demographic",
    "funds",
    "financials",
    "term",
    "clearinghouse",
  ];

  const fileProcessSubTypes = {
    demographic: ["inquiry", "application", "prospect", "singlefile"],
    financials: ["normal"],
    funds: ["pivoted", "unpivoted"],
    term: ["pivoted", "unpivoted"],
    clearinghouse: ["normal"],
  };

  useEffect(() => {
    if ((currentClientId, selectedFPT, selectedFPST)) {
      fetchPublishedMappings();
    }
    // eslint-disable-next-line
  }, [currentClientId, selectedFPT, selectedFPST]);

  const fetchPublishedMappings = async () => {
    const toastMessages = {
      pending: `Fetching Published ${selectedFPT} ${selectedFPST} Mappings`,
      success: `SUCCESS - Checked for published ${selectedFPT} ${selectedFPST} Mappings `,
      error: "FAILED - Please Reach out to Support for Assistance",
    };

    const promise = apiRoot.get(
      `/filesetup/filemappings/publishedVersionsByType?fileProcessType=${selectedFPT}&clientId=${currentClientId}&fileProcessSubType=${selectedFPST}`
    );
    const res = await toast.promise(promise, toastMessages);
    if (res.data.versionId) {
      setSelectedMappingVersions(res.data.versionId);
      setMappingVersionFound(true);
    } else {
      setMappingVersionFound(false);
    }
  };

  const handleFPTSelect = (value) => {
    setSelectedFPT(value);
    setSelectedFPST();
    setSelectedMappingVersions();
  };

  const handleFPSTSelect = (value) => {
    setSelectedFPST(value);
    setSelectedMappingVersions();
  };

  const renderCreateButton = () => {
    if (selectedFPT && selectedFPST && mappingVersionFound) {
      return (
        <Button
          label={"Create"}
          className="rounded-md bg-yellow-01 px-4 py-1 text-blue-03"
          onClick={() => {
            handleFileMaskCreate(
              selectedFPT,
              selectedFPST,
              selectedMappingVersion
            );
            handleCancel();
            navigate(`/clients/${currentClientId}/file-masks`);
          }}
        />
      );
    } else if (selectedFPT && selectedFPST && !mappingVersionFound) {
      return (
        <p className="text-lg text-red-700">
          Please Publish mapping for {selectedFPT} - {selectedFPST}
        </p>
      );
    }
  };

  return (
    <div className="flex flex-col gap-5">
      <div className="flex w-[900px] gap-5">
        <div className="flex flex-col justify-center gap-1">
          <p>File Process Type</p>
          <SchoolSelectList
            options={fileProcessTypes.filter((item) => item !== selectedFPT)}
            onChange={handleFPTSelect}
            defaultValue={selectedFPT}
          />
        </div>
        {selectedFPT ? (
          <div className="flex flex-col gap-1">
            <p>File Process Sub Type</p>
            <SchoolSelectList
              options={fileProcessSubTypes[selectedFPT].filter(
                (item) => item !== selectedFPST
              )}
              onChange={handleFPSTSelect}
              defaultValue={selectedFPST}
            />
          </div>
        ) : (
          <></>
        )}
        {selectedFPT && selectedFPST && selectedMappingVersion ? (
          <div className="flex w-[300px] flex-col gap-1">
            <p>Mapping ID</p>
            <p>{selectedMappingVersion}</p>
          </div>
        ) : (
          <></>
        )}
        {renderCreateButton()}
        <Button
          label={"Cancel"}
          className="rounded-md bg-yellow-01 px-4 py-1 text-blue-03"
          onClick={handleCancel}
        />
      </div>
    </div>
  );
};

export default CreateMask;
