import ReactDOM from "react-dom";

import FormModal from "components/partials/form-modal/form-modal";
import ConfirmationModal from "components/partials/confirmation-modal/confirmation-modal";

const MODAL_CONTAINER = document.getElementById("modal-container");

export const renderFormModal = (props) => {
  ReactDOM.render(<FormModal {...props} />, MODAL_CONTAINER);
};

export const renderConfirmationModal = (props) => {
  ReactDOM.render(<ConfirmationModal {...props} />, MODAL_CONTAINER);
};
