import React from "react";
import Icons from "../assets/icons";
import { Link } from "react-router-dom";
import TagDisplay from "../components/partials/TagDisplay";

const ClientCard = ({
  client,
  handleBookmarkClick,
  handleCardClick,
  bookmarked = true,
}) => {
  return (
    <Link
      to={`/clients/${client.id}`}
      className="flex flex-col justify-between gap-4 rounded-md border border-solid border-gray-02 p-5 text-lg font-bold text-blue-03 no-underline shadow-bs-secondary active:border-2 active:border-yellow-01"
      key={client.id}
      onClick={() => handleCardClick(client.id)}
    >
      <div className="flex items-center gap-2">
        <Icons.Profile />
        <p></p>
        {client.institutionName}
        <div
          className="ml-auto"
          onClick={(e) => handleBookmarkClick(e, bookmarked, client)}
        >
          {bookmarked ? <Icons.BookmarkedIcon /> : <Icons.UnbookmarkedIcon />}
        </div>
      </div>
      <div className="clients-start flex gap-4">
        <div>
          <div className="text-xs font-medium text-blue-01">BLOB CONTAINER</div>
          <div className="text-base font-semibold">{client.blobContainer}</div>
        </div>
        <div className="client-card-subtext">
          <div className="text-xs font-medium text-blue-01">SCHOOL KEY</div>
          <div className="text-base font-semibold">{client.schoolKey}</div>
        </div>
        <div className="client-card-subtext">
          <div className="text-xs font-medium text-blue-01">STATE</div>
          <div className="text-base font-semibold">{client.state}</div>
        </div>
      </div>
      <div className="grid grid-cols-4 gap-2">
        {client?.productOfferings ? (
          <TagDisplay
            options={client.productOfferings}
            additionalCssNames="text-sm"
          />
        ) : (
          ""
        )}
      </div>
    </Link>
  );
};

export default ClientCard;
