import React from "react";

const NotificationPoint = ({ num }) => {
  return (
    <div className={`flex h-5 w-5 items-center justify-center rounded-full bg-red-600`}>
      <span className="mb-px text-xs text-white ">{num < 10 ? num : "9+"}</span>
    </div>
  );
};

export default NotificationPoint;
