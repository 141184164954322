import React from "react";
import classNames from "classnames";

import CustomButton from "components/partials/custom-button/custom-button";

const ActionMenuItem = ({ label, icon, isDelete, onClick }) => (
  <CustomButton
    data-testid="action-menu-item"
    customClass={classNames(
      "action-menu-item font-normal hover:bg-gray-01 transition duration-500 pr-0 pl-2 !justify-start w-full",
      {
        "text-gray-03 hover:text-gray-03": !isDelete,
        "text-red-03 hover:text-red-03": isDelete,
      }
    )}
    variant="text"
    iconBefore={icon}
    onClick={onClick}
  >
    {label}
  </CustomButton>
);

export default ActionMenuItem;
