import React from "react";
import AuthenticatedApp from "./pages/authenticated-app";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import UnAuthenticatedApp from "./pages/unauthenticated-app";
import WithAxios from "api/WithAxios";

const App = () => {
  return (
    // WithAxios feels a little hacky but for now need to make sure the JWT doesn't expire
    // As I don't have a good way of handling it so on each response we will acquire a new token
    <WithAxios>
      <AuthenticatedTemplate>
        <AuthenticatedApp />
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <UnAuthenticatedApp />
      </UnauthenticatedTemplate>
    </WithAxios>
  );
};

export default App;
