import { BATCHES_STATUSES } from "pages/list-plus/batch/batch.constants";

export const getMessageTitle = (
  processName,
  status,
  isAfterSuccessScenario,
  date,
  data
) => {
  const reconfigureMessageOnSuccess = `${data.name} with ID ${data.id} was processed on ${date}.`;
  const reconfigureMessageOnFail = `The ${processName} of the batch was failed on ${date}.`;

  if (status === BATCHES_STATUSES.RECONFIGURING) {
    return isAfterSuccessScenario
      ? reconfigureMessageOnSuccess
      : reconfigureMessageOnFail;
  }

  return `The ${processName} of the batch has failed.`;
};

export const getIsBatchMessageShown = (data) => {
  return [
    BATCHES_STATUSES.RECONFIGURING,
    BATCHES_STATUSES.PROCESSING_FAILED,
    BATCHES_STATUSES.PUBLISHING_FAILED,
  ].includes(data.status);
};
