import React, { useState, useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { ClientContext } from "context/ClientContext";
import { ConfigContext } from "context/ConfigContext";
import Table from "components/Table";
import apiRoot from "api/apiRoot";

const ConfigVersions = () => {
  const { currentClientId } = useContext(ClientContext);
  const { setJsonConfig, setVersionId } = useContext(ConfigContext);
  const { configId } = useParams();
  const [configVersions, setConfigVersions] = useState([]);

  useEffect(() => {
    if (currentClientId && configId) {
      setJsonConfig({});
      setVersionId();
      fetchConfigVersions();
    }
    // eslint-disable-next-line
  }, [currentClientId, configId]);

  const fetchConfigVersions = async () => {
    const toastMessages = {
      pending: `Fetching Config versions`,
      success: `SUCCESS - Config Versions Found`,
      error: "FAILED - Please Reach out to Support for Assistance",
    };

    const promise = apiRoot.get(`/config/versions?parentConfigId=${configId}`);
    const res = await toast.promise(promise, toastMessages);

    setConfigVersions(res.data);
  };

  const renderConfigVersions = () => {
    if (configVersions.length > 0) {
      const headers = [
        { header: "ID", dataKey: "id", additionalCssClass: "px-4 text-left" },
        {
          header: "STATUS",
          dataKey: "versionStatus",
          additionalCssClass: "px-4 text-center",
        },
        {
          header: "CREATED BY",
          dataKey: "versionCreatedBy",
          additionalCssClass: "px-4 text-center",
        },
        {
          header: "CREATE DATETIME",
          dataKey: "versionCreateDateTime",
          additionalCssClass: "px-4 text-center",
        },
        {
          header: "APPROVED BY",
          dataKey: "versionApprovedBy",
          additionalCssClass: "px-4 text-center",
        },
        {
          header: "APPROVED DATETIME",
          dataKey: "versionApprovedDateTime",
          additionalCssClass: "px-4 text-center",
        },
        {
          header: "LAST UPDATED BY",
          dataKey: "versionLastUpdatedBy",
          additionalCssClass: "px-4 text-center",
        },
        {
          header: "LAST UPDATE DATETIME",
          dataKey: "versionLastUpdateDateTime",
          additionalCssClass: "px-4 text-center",
        },
        {
          header: "VIEW",
          dataKey: "button",
          additionalCssClass: "px-4 text-center",
        },
      ];

      return (
        <Table
          tableAdditionalClassName={"w-[calc(100vw -300px)] max-w-[2000px]"}
          headers={headers}
          data={configVersions}
          buttonLabel={"VIEW"}
          buttonLabelClassName={"text-blue-03 font-normal"}
          buttonClassName="flex rounded-md bg-yellow-01 py-1 px-4 text-blue-03"
          buttonLinkTo={`/clients/${currentClientId}/config/${configId}/versions/{id}/`}
        />
      );
    } else {
      return "";
    }
  };

  return (
    <>
      <ToastContainer
        style={{ width: "500px", fontSize: "16px" }}
        progressStyle={{ backgroundColor: "#b6de3a" }}
      />
      <div>{renderConfigVersions()}</div>
    </>
  );
};

export default ConfigVersions;
