import React, { useState, useContext, useEffect } from "react";
import { useSelector } from "react-redux";

import ProcessedFilesTable from "pages/list-plus/batch/processed-files/processed-files-table/processed-files-table";

import { ClientContext } from "context/ClientContext";

import { handleCopyClick } from "helpers/apply-actions";
import { formatDate } from "helpers/dates-formatter";
import { getMessageTitle } from "pages/list-plus/batch/batch-message/batch-message.helper";
import { getProcessedFilesTextToCopy } from "pages/list-plus/batch/processed-files/processed-files.helper";

import { BATCHES_STATUSES } from "pages/list-plus/batch/batch.constants";

import Icons from "assets/icons";

const BatchMessage = ({ children }) => {
  const { batchData } = useSelector((state) => state?.batch);

  const { currentClientData } = useContext(ClientContext);

  const [isExpanded, setIsExpanded] = useState(false);
  const [isMessageCopied, setIsMessageCopied] = useState(false);
  const [stringToCopy, setStringToCopy] = useState("");

  const processingErr = batchData?.processedError;
  const processedFiles = batchData?.processedFiles;

  const publishingErr = batchData?.publishedError;

  const messageData = {
    timestamp:
      processingErr?.timestamp ||
      publishingErr?.timestamp ||
      processedFiles?.timestamp,
    name: batchData?.name,
    id: batchData?.id,
    message: processingErr?.errorMessage || publishingErr?.errorMessage,
  };

  const date = formatDate(messageData.timestamp, "MMM D, YYYY h:mm A");

  const processName = processingErr?.errorMessage ? "processing" : "publishing";

  const isSuccessReconfigureScenario =
    !!processedFiles && batchData.status === BATCHES_STATUSES.RECONFIGURING;

  const errMessage = `When: ${date}
    Batch Name: ${messageData?.name}
    Batch ID: ${messageData?.id}
    File ${processName} error message: ${messageData?.message}`;

  const messageSettings = {
    borderColor: isSuccessReconfigureScenario
      ? "border-l-gray-05"
      : "border-l-red-04",
    textColor: isSuccessReconfigureScenario ? "text-gray-05" : "text-red-04",
  };

  useEffect(() => {
    const string = isSuccessReconfigureScenario
      ? getProcessedFilesTextToCopy(batchData, currentClientData)
      : errMessage;

    setStringToCopy(string);
    // eslint-disable-next-line
  }, [batchData]);

  return (
    <div
      className={`my-2.5 flex rounded-md border border-l-[6px] p-4 shadow-sm ${messageSettings.borderColor}`}
    >
      <Icons.Warning
        aria-label="Procesing failed icon"
        customClass={`w-5 h-5 mr-2 not-sr-only ${messageSettings.textColor}`}
      />
      <div className="relative flex w-full flex-col ">
        <div className="flex w-full justify-between">
          <span className={`font-semibold ${messageSettings.textColor}`}>
            {getMessageTitle(
              processName,
              batchData.status,
              isSuccessReconfigureScenario,
              date,
              messageData
            )}
          </span>
          <Icons.Arrow
            title="Copy error message"
            customClass={`${
              isExpanded && "rotate-90"
            } w-4 h-4 mt-1 not-sr-only cursor-pointer ${
              messageSettings.textColor
            }`}
            onClick={() => setIsExpanded(!isExpanded)}
          />
        </div>
        {isExpanded && (
          <>
            {isSuccessReconfigureScenario ? (
              <ProcessedFilesTable />
            ) : (
              <span className="whitespace-pre-line pt-2 text-gray-03">
                {errMessage}
              </span>
            )}
            {isMessageCopied ? (
              <span className="absolute right-0 top-2/4 text-xs text-gray-03">
                Copied
              </span>
            ) : (
              <Icons.Copy
                title="Copy error message"
                customClass="w-4 absolute top-2/4 right-0 h-4 not-sr-only text-gray-03 cursor-pointer"
                onClick={() =>
                  handleCopyClick(stringToCopy, setIsMessageCopied)
                }
              />
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default BatchMessage;
