import React, { useContext, useState, useEffect } from "react";
import { useSelector } from "react-redux";

import Heading from "components/partials/heading/heading";
import CustomButton from "components/partials/custom-button/custom-button";
import ProcessedFilesTable from "pages/list-plus/batch/processed-files/processed-files-table/processed-files-table";

import { ClientContext } from "context/ClientContext";

import { handleCopyClick } from "helpers/apply-actions";
import { formatDate } from "helpers/dates-formatter";
import { getProcessedFilesTextToCopy } from "pages/list-plus/batch/processed-files/processed-files.helper";

import Icons from "assets/icons";

const ProcessedFiles = () => {
  const { batchData } = useSelector((state) => state?.batch);

  const { currentClientData } = useContext(ClientContext);

  const [isBatchCompletedDataCopied, setIsBatchCompletedDataCopied] =
    useState(false);
  const [stringToCopy, setStringToCopy] = useState("");

  useEffect(() => {
    const string = getProcessedFilesTextToCopy(batchData, currentClientData);

    setStringToCopy(string);
    // eslint-disable-next-line
  }, [batchData]);

  return (
    <div className="flex h-full w-full flex-col">
      <div className="mt-8 flex justify-between">
        <Heading variant="h2" customClass="font-semibold">
          Processed file(s)
        </Heading>
        <CustomButton
          variant="secondary"
          size="sm"
          onClick={() =>
            handleCopyClick(stringToCopy, setIsBatchCompletedDataCopied)
          }
          iconBefore={
            <Icons.Copy
              aria-label="Procesing failed icon"
              customClass="w-4 h-4 mt-1 not-sr-only text-blue-03"
            />
          }
        >
          Copy text
        </CustomButton>
      </div>
      <div className="flex h-96 shrink grow basis-0">
        <div className="mt-2.5 flex shrink grow basis-0 flex-col gap-x-12 overflow-y-auto rounded-md border border-gray-02 p-4 custom-scrollbar">
          <span className="pb-2 text-lg font-semibold">{`${
            batchData.name
          } with ID ${batchData.id} on ${formatDate(
            batchData.createdDate,
            "MMM D, YYYY h:mm A"
          )}`}</span>
          <ProcessedFilesTable />
        </div>
      </div>
    </div>
  );
};

export default ProcessedFiles;
