import { uniqueId } from "lodash";
import React, { useContext } from "react";
import Button from "./partials/Button";
import JsonViewer from "./partials/JsonViewer";
import { FileMappingsContext } from "context/FileMappingsContext";

const Table = ({
  tableAdditionalClassName,
  headers,
  data,
  buttonFunction,
  buttonIconLeft,
  buttonIconRight,
  buttonLabel,
  buttonClassName,
  buttonLabelClassName,
  buttonLinkTo,
  buttonAllowedAppRoles,
  contenteditable,
  handleEditChange,
  fileProcessType,
}) => {
  const { standardizedMappingHeaders } = useContext(FileMappingsContext);

  function renderTableData(header, row) {
    if (header.dataKey === "button") {
      let newButtonLink;
      if (buttonLinkTo) {
        newButtonLink = buttonLinkTo;

        Object.keys(row).forEach((key) => {
          if (newButtonLink.includes(key)) {
            newButtonLink = newButtonLink.replace(
              `{${key}}`,
              row[key].toLowerCase()
            );
          }
        });
      }

      return (
        <Button
          className={buttonClassName}
          onClick={() => buttonFunction(row)}
          label={buttonLabel}
          iconLeft={buttonIconLeft}
          iconRight={buttonIconRight}
          labelClassName={buttonLabelClassName}
          to={newButtonLink}
          allowedAppRoles={buttonAllowedAppRoles}
        />
      );
    } else if (typeof header.dataKey === "function") {
      return header.dataKey(row);
    } else if (Array.isArray(row[header.dataKey])) {
      return row[header.dataKey].join(", ");
    } else if (header.dataKey === "key") {
      return row;
    } else if (header.dataKey === "value") {
      if (Array.isArray(data[row])) {
        if (typeof data[row][0] === "string") {
          return data[row].join(", ");
        }
        return data[row].map((item) => {
          return <JsonViewer srcJson={item} />;
        });
      } else if (typeof data[row] === "object") {
        return <JsonViewer srcJson={data[row]} />;
      } else {
        return data[row].toString();
      }
    } else {
      if (
        header.dataKey.toLowerCase().includes("datetime") &&
        row[header.dataKey]
      ) {
        return new Date(row[header.dataKey])
          .toISOString()
          .replace("T", " ")
          .replace("Z", "")
          .slice(0, 19);
      }

      if (typeof row[header.dataKey] === "object" && header.nestedKey) {
        return row[header.dataKey][header.nestedKey];
      }
      return row[header.dataKey];
    }
  }

  function renderMappingDropdown(header, row) {
    if (header.dataKey === "button") {
      let newButtonLink;
      if (buttonLinkTo) {
        newButtonLink = buttonLinkTo;

        Object.keys(row).forEach((key) => {
          if (newButtonLink.includes(key)) {
            newButtonLink = newButtonLink.replace(
              `{${key}}`,
              row[key].toLowerCase()
            );
          }
        });
      }

      return (
        <Button
          className={buttonClassName}
          onClick={() => buttonFunction(row)}
          label={buttonLabel}
          iconLeft={buttonIconLeft}
          iconRight={buttonIconRight}
          labelClassName={buttonLabelClassName}
          to={newButtonLink}
          allowedAppRoles={buttonAllowedAppRoles}
        />
      );
    } else if (header.header === "STANDARDIZED" && contenteditable) {
      // let availableMappings = STANDARDIZED_COLUMN_HEADERS[fileProcessType][fileProcessSubType].filter((item) => !Object.keys(data).includes(item));
      return (
        <td
          key={uniqueId()}
          className={`text-gray-03 overflow-hidden text-ellipsis break-words bg-white py-2 text-base font-normal leading-6 ${header.additionalCssClass}`}
        >
          <select
            value={row}
            onChange={(e) =>
              handleEditChange(row, e.target.value, header.dataKey)
            }
          >
            {standardizedMappingHeaders.map((item) => {
              return (
                <option key={item} value={item}>
                  {item}
                </option>
              );
            })}
          </select>
        </td>
      );
    } else if (header.header === "CLIENT") {
      return (
        <td
          key={uniqueId()}
          className={`text-gray-03 overflow-hidden text-ellipsis break-words bg-white py-2 text-base font-normal leading-6 ${header.additionalCssClass}`}
          contentEditable={header.contenteditable && contenteditable}
          suppressContentEditableWarning={true}
          onBlur={(e) =>
            handleEditChange(row, e.currentTarget.textContent, header.dataKey)
          }
        >
          {data[row]}
        </td>
      );
    } else {
      return (
        <td
          key={uniqueId()}
          className={`text-gray-03 overflow-hidden text-ellipsis break-words bg-white py-2 text-base font-normal leading-6 ${header.additionalCssClass}`}
          contentEditable={false}
          suppressContentEditableWarning={true}
        >
          {row}
        </td>
      );
    }
  }

  function renderTableBody() {
    if (fileProcessType) {
      return Object.keys(data).map((key) => {
        if (
          key[0] !== "_" &&
          key !== "FullRowHashCols" &&
          key !== "SupplementalFiles" &&
          key !== "DecisionHistory" &&
          key !== "id"
        ) {
          return (
            <tr
              key={uniqueId()}
              className="border-gray-02 border-b border-solid last:border-none"
            >
              {headers.map((header) => {
                return renderMappingDropdown(header, key);
              })}
            </tr>
          );
        }
      });
    } else if (!Array.isArray(data)) {
      return Object.keys(data).map((key) => {
        if (
          key[0] !== "_" &&
          key !== "FullRowHashCols" &&
          key !== "SupplementalFiles" &&
          key !== "DecisionHistory" &&
          key !== "id"
        ) {
          return (
            <tr
              key={uniqueId()}
              className="border-gray-02 border-b border-solid last:border-none"
            >
              {headers.map((header) => {
                return (
                  <td
                    key={uniqueId()}
                    className={`text-gray-03 overflow-hidden text-ellipsis break-words bg-white py-2 text-base font-normal leading-6 ${header.additionalCssClass}`}
                    contentEditable={header.contenteditable && contenteditable}
                    suppressContentEditableWarning={true}
                    onBlur={(e) =>
                      handleEditChange(
                        key,
                        e.currentTarget.textContent,
                        header.dataKey
                      )
                    }
                  >
                    {renderTableData(header, key)}
                  </td>
                );
              })}
            </tr>
          );
        }
      });
    } else {
      return data.map((row) => {
        return (
          <tr
            key={uniqueId()}
            className="border-gray-02 border-b border-solid last:border-none"
          >
            {headers.map((header) => {
              return (
                <td
                  key={uniqueId()}
                  className={`text-gray-03 overflow-hidden text-ellipsis break-words bg-white py-2 text-base font-normal leading-6 ${header.additionalCssClass}`}
                  contentEditable={header.contenteditable && contenteditable}
                  suppressContentEditableWarning={true}
                  onBlur={(e) =>
                    handleEditChange(
                      row,
                      e.currentTarget.textContent,
                      header.dataKey
                    )
                  }
                >
                  {renderTableData(header, row)}
                </td>
              );
            })}
          </tr>
        );
      });
    }
  }

  return (
    <table
      className={`table table-auto border-collapse ${tableAdditionalClassName}`}
    >
      <thead className="after:block after:leading-[8px] after:text-transparent after:content-['-']">
        <tr key={uniqueId()}>
          {headers.map((header) => {
            return (
              <th
                className={`text-blue-01 bg-gray-01 h-10 py-1 text-xs font-semibold leading-3 tracking-widest ${header.additionalCssClass}`}
                key={header.header}
              >
                {header.header}
              </th>
            );
          })}
        </tr>
      </thead>
      <tbody className="border-gray-02 rounded-md border border-solid">
        {renderTableBody()}
      </tbody>
    </table>
  );
};

export default Table;
