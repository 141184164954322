import React, { useMemo } from "react";

export const ShowingRecordsText = ({
  table,
  pageIndex,
  pageSize,
  totalRecords,
  entityType = "",
}) => {
  //define showingFrom and showingTo records depending on pageSize and pagination
  const { showingFrom, showingTo } = useMemo(() => {
    const currentRecordsAmount = table.getRowModel().rows.length;

    const showingFrom = (pageIndex + 1) * pageSize - (pageSize - 1);
    const showingTo =
      (pageIndex + 1) * pageSize - (pageSize - currentRecordsAmount);

    return { showingFrom, showingTo };
    // eslint-disable-next-line
  }, [pageIndex, pageSize, table.getRowModel().rows.length]);

  return (
    <span className="py-1.5">
      {`${showingFrom}-${showingTo} of ${totalRecords} ${entityType}`}
    </span>
  );
};
