import { toast } from "react-toastify";

import apiRoot from "api/apiRoot";

import {
  setFilesLoading,
  setUploadedFiles,
  setIsUploadAreaShown,
  setIsActionAppliedOnFile,
  setTableHasUnsavedChanges,
  setTotalRecords,
} from "state/files/files.slice";

import store from "state/store";

export const useFilesThunk = () => {
  return {
    getAllFiles: async (pageIndex) => {
      store.dispatch(setFilesLoading(true));

      const { batchData } = store.getState().batch;

      apiRoot
        .get(
          `/api/clients/${batchData?.clientId}/file-batches/${batchData?.id}/files?size=50&page=${pageIndex}&sort=createdDate,asc`
        )
        .then((res) => {
          store.dispatch(setUploadedFiles(res?.data?.content));
          store.dispatch(setIsUploadAreaShown(!res?.data?.content?.length));
          store.dispatch(setTotalRecords(res.data.totalElements));
        })
        .catch((err) => console.error("Get files request failed", err))
        .finally(() => {
          store.dispatch(setIsActionAppliedOnFile(false));
          store.dispatch(setFilesLoading(false));
        });
    },
    uploadFile: async (files) => {
      store.dispatch(setFilesLoading(true));

      const { selectedRadioOption } = store.getState().files;
      const { batchData } = store.getState().batch;

      if (!!files?.length) {
        let uploadPromises = [];

        for (let i = 0; i < files.length; i++) {
          //collect formData and prepare all promises
          const formData = new FormData();
          formData.append("file", files[i]);
          formData.append(
            "request",
            new Blob([JSON.stringify({ listSource: selectedRadioOption })], {
              type: "application/json",
            })
          );

          //return promise for every file
          const promise = await apiRoot.post(
            `/api/clients/${batchData?.clientId}/file-batches/${batchData?.id}/files`,
            formData,
            {
              //as Axios doesn't take 400 response as an error we need to toast it here
              transformResponse: [
                (response) => {
                  const data = JSON.parse(response);

                  if (data.status !== 200) {
                    toast(data.detail);
                  }
                },
              ],
            }
          );
          uploadPromises.push(promise);
        }

        //invoke all promises
        await Promise.allSettled(uploadPromises).finally(() => {
          store.dispatch(setIsActionAppliedOnFile(true));
          store.dispatch(setIsUploadAreaShown(false));
          store.dispatch(setFilesLoading(false));
        });
      }
    },
    removeFile: async (file) => {
      store.dispatch(setFilesLoading(true));

      const { batchData } = store.getState().batch;

      apiRoot
        .delete(
          `/api/clients/${batchData?.clientId}/file-batches/${batchData?.id}/files/${file?.id}`
        )
        .catch((err) => console.error("Remove file request failed", err))
        .finally(() => {
          store.dispatch(setUploadedFiles([]));
          store.dispatch(setIsActionAppliedOnFile(true));
          store.dispatch(setFilesLoading(false));
        });
    },
    saveTableConfiguration: async (files) => {
      store.dispatch(setFilesLoading(true));

      const { batchData } = store.getState().batch;

      const saveConfigPromises = [];

      for (let i = 0; i < files.length; i++) {
        //return promise for every file
        const promise = await apiRoot
          .patch(
            `/api/clients/${batchData?.clientId}/file-batches/${batchData?.id}/files/${files[i].id}`,
            files[i].data
          )
          .catch((err) => {
            toast(err.details);
          });

        saveConfigPromises.push(promise);
      }

      //invoke all promises
      await Promise.all(saveConfigPromises).finally(() => {
        store.dispatch(setFilesLoading(false));
        store.dispatch(setIsActionAppliedOnFile(true));
        store.dispatch(setTableHasUnsavedChanges(false));
      });
    },
  };
};
