import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useForm, useWatch, FormProvider } from "react-hook-form";

import UploadedFilesTable from "pages/list-plus/batch/uploaded-files/uploaded-files-table/uploaded-files-table";

import Heading from "components/partials/heading/heading";
import Loader from "components/partials/loader/loader";
import FileUploader from "components/partials/file-uploader/file-uploader";
import CustomButton from "components/partials/custom-button/custom-button";

import store from "state/store";

import {
  setIsUploadAreaShown,
  setTableHasUnsavedChanges,
  setSelectedRadioOption,
} from "state/files/files.slice";
import { useFilesThunk } from "state/files/files.thunk";

import {
  getFilesFormValidationSchema,
  getDefaultValues,
} from "pages/list-plus/batch/uploaded-files/uploaded-files-table/uploaded-files-table.constants";

import { getTouchedFieldsWithIds } from "helpers/fomat-data";
import { getIsAnyFileChanged } from "pages/list-plus/batch/uploaded-files/uploaded-files.helper";

import { BATCHES_STATUSES } from "pages/list-plus/batch/batch.constants";

import Icons from "assets/icons";

const UploadedFiles = ({ heading, subHeading }) => {
  const { getAllFiles, uploadFile, saveTableConfiguration } = useFilesThunk();
  const {
    filesLoading,
    uploadedFiles,
    isUploadAreaShown,
    isActionAppliedOnFile,
    tableHasUnsavedChanges,
    pageSize,
    totalRecords,
  } = useSelector((state) => state?.files);
  const { batchData } = useSelector((state) => state?.batch);
  const { batchListSources, segmentGroup } = useSelector(
    (state) => state?.batch
  );
  const [isReadOnlyView, setIsReadOnlyView] = useState(false);
  const [pageIndex, setPageIndex] = useState(0);

  //form
  const {
    control,
    handleSubmit,
    getFieldState,
    formState: { errors },
    reset,
  } = useForm({
    mode: "onChange",
    defaultValues: getDefaultValues(uploadedFiles),
    resolver: getFilesFormValidationSchema(),
  });

  const changedFiles = useWatch({
    control,
    name: "filesData",
  });

  const onSaveChanges = (data) => {
    const touchedFieldsWithIds = getTouchedFieldsWithIds({
      data,
      uploadedFiles,
      getFieldState,
    });

    saveTableConfiguration(touchedFieldsWithIds);
  };

  useEffect(() => {
    if (!!batchData?.name) {
      //reset of data not to cache it form the next file
      reset({
        filesData: [],
      });
      getAllFiles(pageIndex);
    }
    // eslint-disable-next-line
  }, [batchData, isActionAppliedOnFile, pageIndex]);

  useEffect(() => {
    setIsReadOnlyView(
      batchData.status !== BATCHES_STATUSES.CONFIGURING &&
        batchData.status !== BATCHES_STATUSES.RECONFIGURING
    );
    // eslint-disable-next-line
  }, [batchData.status]);

  useEffect(() => {
    //for setting label about table has unsaved changes
    if (!!changedFiles.length) {
      const tableHasChanges = getIsAnyFileChanged(changedFiles, uploadedFiles);

      //for setting label about table has unsaved changes
      store.dispatch(setTableHasUnsavedChanges(tableHasChanges));
    }
    // eslint-disable-next-line
  }, [changedFiles]);

  return (
    <FormProvider {...{ control, errors, reset }}>
      <form
        onSubmit={handleSubmit(onSaveChanges)}
        className="flex h-full w-full flex-col"
      >
        <div className="mt-8 flex justify-between">
          <Heading variant="h2" customClass="font-semibold">
            {heading}
          </Heading>
          {!isReadOnlyView && !!uploadedFiles?.length && (
            <div className="flex gap-x-3">
              <CustomButton
                variant="secondary"
                size="sm"
                disabled={isUploadAreaShown}
                onClick={() => {
                  store.dispatch(setIsUploadAreaShown(true));
                  store.dispatch(setSelectedRadioOption("COLLEGE_BOARD"));
                }}
                iconBefore={
                  <Icons.Plus
                    aria-label="Warning saved changes status"
                    customClass="w-4 h-full"
                  />
                }
              >
                Add files
              </CustomButton>
              <CustomButton
                variant="primary"
                size="sm"
                type="submit"
                disabled={
                  !tableHasUnsavedChanges || !!Object.keys(errors)?.length
                }
              >
                Save changes
              </CustomButton>
            </div>
          )}
        </div>
        {!filesLoading ? (
          <>
            {(isUploadAreaShown || !uploadedFiles?.length) && (
              <FileUploader
                label="Upload file(s)"
                onChange={(files) => uploadFile(files)}
                isCloseBtnShown={!!uploadedFiles?.length}
                helperText="The file must be either .zip, .csv or .xlsx and must be less than 50 MB"
              />
            )}
            {!!uploadedFiles?.length && (
              <>
                <div className="relative mt-2.5 flex items-center">
                  <Heading
                    variant="h3"
                    customClass="text-gray-05 font-semibold mt-2.5 mb-2 mr-2.5 absolute top-0"
                  >
                    {subHeading}
                  </Heading>
                  {tableHasUnsavedChanges && (
                    <div className="absolute left-52 top-2.5 flex h-fit items-center rounded bg-yellow-03 px-2 py-1 text-xs text-yellow-04">
                      <Icons.Warning
                        aria-label="Warning Icon"
                        customClass="w-4 h-4 mr-1 not-sr-only"
                      />
                      <span>The table has unsaved changes.</span>
                    </div>
                  )}
                </div>
                <UploadedFilesTable
                  {...{
                    uploadedFiles,
                    batchListSources,
                    segmentGroup,
                    isReadOnlyView,
                    pageSize,
                    totalRecords,
                    pageIndex,
                    setPageIndex,
                  }}
                />
              </>
            )}
          </>
        ) : (
          <Loader />
        )}
      </form>
    </FormProvider>
  );
};

export default UploadedFiles;
