import React, { useState, useContext, useEffect } from "react";
import AsyncSelect from "react-select/async";
import { ToastContainer } from "react-toastify";
import debounce from "lodash.debounce";

import FileUploader from "components/partials/file-uploader/file-uploader";
import Table from "components/Table";
import CustomButton from "components/partials/custom-button/custom-button";

import apiRoot from "api/apiRoot";

import { TranscriptSummarizationContext } from "context/TranscriptSummarizationContext";
import { ClientContext } from "context/ClientContext";
import { AuthContext } from "context/AuthContext";

import { handleCopyClick } from "helpers/apply-actions";

import appRoles from "config/appRoles";

import Icons from "assets/icons";

const TranscriptSummarization = ({ isClientLevel = false }) => {
  const [inputValue, setValue] = useState("");
  const [isCopied, setIsCopied] = useState(false);

  const {
    handleTranscriptFileUpload,
    transcriptUploads,
    onTranscriptFileRemove,
    onTranscriptFileUpload,
    updateLinkedClient,
    currentTranscriptSummary,
    setLinkedClient,
    isSummarizing,
  } = useContext(TranscriptSummarizationContext);
  const { isAuthorized } = useContext(AuthContext);
  const { currentClientData } = useContext(ClientContext);

  useEffect(() => {
    onTranscriptFileRemove();
    if (!isClientLevel) {
      setLinkedClient("");
    } else if (isClientLevel && currentClientData) {
      setLinkedClient(currentClientData);
    }
    // eslint-disable-next-line
  }, [isClientLevel, currentClientData]);

  const _loadOptions = (queryTerm, callback) => {
    apiRoot
      .get(`/client/search?searchTerm=${queryTerm}`)
      .then((res) => callback(res.data));
  };

  const loadOptions = debounce(_loadOptions, 500);

  const handleChange = (value) => {
    setValue(value);
    updateLinkedClient(value);
  };

  function renderTranscriptUpload() {
    const headers = [
      {
        header: "FILE NAME",
        dataKey: "FileName",
        additionalCssClass: "px-14 text-left",
      },
      {
        header: "LINKED CLIENT",
        dataKey: "LinkedClient",
        nestedKey: "institutionName",
        additionalCssClass: "px-14 text-left",
      },
      {
        header: "FILE SIZE",
        dataKey: "FileSize",
        additionalCssClass: "px-4 text-center",
      },
      {
        header: "REMOVE FILE",
        dataKey: "button",
        additionalCssClass: "px-4 text-center",
      },
    ];
    return (
      <div className="flex max-w-[1000px] flex-col gap-4">
        <p className="pb-1 text-base font-semibold leading-5 text-blue-03">
          Current Transcript File
        </p>
        <Table
          headers={headers}
          data={transcriptUploads}
          buttonFunction={onTranscriptFileRemove}
          buttonLabel={"Remove"}
          buttonLabelClassName={"text-blue-03 font-semibold"}
          buttonClassName="mx-auto my-0 flex cursor-pointer items-center gap-1 rounded-md border border-solid border-blue-01 bg-white px-3 py-2"
        />
        <div className="flex flex-grow flex-col gap-2">
          <CustomButton
            variant="primary"
            customClass="bg-yellow-01"
            onClick={handleTranscriptFileUpload}
            disabled={
              !isAuthorized(appRoles.IsAiTranscriptSummarizationWriter) ||
              isSummarizing
            }
          >
            Summarize Transcript File
          </CustomButton>
          {!isAuthorized(appRoles.IsAiTranscriptSummarizationWriter) ? (
            <p className="text-sm text-red-600">
              Not Authorized to Summarize Transcript
            </p>
          ) : (
            ""
          )}
        </div>
      </div>
    );
  }

  function renderTranscriptAssistantParagraph() {
    return (
      <div>
        <p className="pb-3 text-base font-semibold leading-5 text-blue-03">
          Transcript Summarization
        </p>
        <p className="whitespace-pre-wrap">
          Welcome to your AI transcript summarization assistant!<br></br>
          {!isClientLevel ? (
            <span>
              <br></br>If this was a client call (current or prospective){" "}
              <strong>please</strong> link them using the search bar
              <strong> below</strong> then upload your transcript file.
              Otherwise feel free to ignore the institution link<br></br>
            </span>
          ) : (
            <span>
              <br></br>This transcript will be linked to this institution if the
              transcript does not belong to this institution please switch to
              the institution using the search bar<strong> above</strong> then
              upload your transcript file. <br></br>
            </span>
          )}
          <br></br>Please only upload transcripts in the <strong>VTT</strong>{" "}
          format. This is the standard format downloaded from teams, zoom, etc.
          Your transcript will be stored for future use. The transcript will be
          parsed, digested, and understood by an AI, afterwards a summarized
          response will be returned to you. Depending on the size of the
          transcript
          <strong> this could take a while. </strong>
        </p>
      </div>
    );
  }

  function renderTranscriptInstitutionLink() {
    return (
      <div>
        {!isClientLevel ? (
          <>
            <p className="pb-3 text-base font-semibold leading-5 text-blue-03">
              Link Transcript to Institution
            </p>
            <div className="flex flex-grow gap-2">
              <div className="w-[580px]">
                <div className="pr-3">
                  <AsyncSelect
                    placeholder="Search for Institution"
                    value={inputValue}
                    getOptionLabel={(e) =>
                      `${e.institutionName} - ${e.state} - ${e.schoolKey} - ${e.blobContainer}`
                    }
                    getOptionValue={(e) => e.id}
                    loadOptions={loadOptions}
                    onChange={handleChange}
                  />
                </div>
              </div>
              {inputValue ? (
                <CustomButton
                  variant="primary"
                  customClass="bg-yellow-01"
                  onClick={() => {
                    setValue("");
                    updateLinkedClient("");
                  }}
                >
                  Reset Value
                </CustomButton>
              ) : (
                <> </>
              )}
            </div>
          </>
        ) : (
          <></>
        )}
      </div>
    );
  }

  function renderTranscriptSummary() {
    return (
      <div className="flex flex-col gap-2 py-2 pr-2">
        <div className="flex items-center gap-4">
          <p className="text-base font-semibold leading-5 text-blue-03">
            Transcript Summary
          </p>
          <Icons.Copy
            className="w-5 hover:cursor-pointer"
            onClick={() =>
              handleCopyClick(currentTranscriptSummary, setIsCopied)
            }
          />
          {isCopied && (
            <div className="text-base font-semibold text-yellow-01">
              Copied!
            </div>
          )}
        </div>
        <p className="whitespace-pre-wrap text-base">
          {currentTranscriptSummary}
        </p>
      </div>
    );
  }

  return (
    <>
      <ToastContainer
        style={{ width: "500px", fontSize: "16px" }}
        autoClose={true}
      />
      <div className="flex flex-grow-0 gap-5">
        <div className="flex flex-col gap-4 p-2">
          {renderTranscriptAssistantParagraph()}
          {renderTranscriptInstitutionLink()}
          <div>
            {transcriptUploads?.length > 0 ? (
              renderTranscriptUpload()
            ) : (
              <FileUploader
                label={`Transcript Files`}
                multiple={false}
                onChange={onTranscriptFileUpload}
                acceptableFiles={[".docx", ".vtt"]}
              />
            )}
          </div>
        </div>
        <div>{currentTranscriptSummary ? renderTranscriptSummary() : ""}</div>
      </div>
    </>
  );
};

export default TranscriptSummarization;
