import { renderConfirmationModal } from "helpers/render-modal";
import { getIsReconfiguringAvailable } from "pages/list-plus/batch/batch.helper";

import Icons from "assets/icons";

import { BATCHES_STATUSES } from "pages/list-plus/batch/batch.constants";

export const getBatchDetailsActionMenuItems = ({
  processOrPublishBatch,
  removeBatch,
  navigate,
  status,
}) =>
  [
    getIsReconfiguringAvailable(status) && {
      label: `Reconfigure files`,
      icon: <Icons.Undo customClass="mr-2" />,
      onClick: () =>
        renderConfirmationModal({
          headerText: `Are you sure you want to reconfigure files?`,
          text: `You won't be able to undo this action`,
          onConfirmCallback: () =>
            processOrPublishBatch(BATCHES_STATUSES.RECONFIGURING),
          onConfirmButtonLabel: "Reconfigure files",
        }),
    },
    {
      label: "Remove batch",
      icon: <Icons.Delete customClass="text-red-03 mr-2" />,
      isDelete: true,
      onClick: () =>
        renderConfirmationModal({
          headerText: `Are you sure you want to remove this batch?`,
          onConfirmCallback: () => {
            removeBatch(navigate);
          },
          onConfirmButtonLabel: "Yes, remove this batch",
        }),
    },
  ].filter((item) => item);
