import appRoles from "config/appRoles";
import React, { createContext, useState, useContext, useEffect } from "react";
import apiRoot from "../api/apiRoot";
import { AuthContext } from "./AuthContext";
import { ClientContext } from "./ClientContext";
import { InstitutionContext } from "./InstitutionContext";
import { toast } from "react-toastify";

export const ConfigContext = createContext();

export const ConfigStore = ({ children }) => {
  const { user, acquireToken, isAuthorized } = useContext(AuthContext);
  const { currentClientId } = useContext(ClientContext);
  const { fetchQueuedConfigs } = useContext(InstitutionContext);
  const [clientConfigs, setClientConfigs] = useState([]);
  const [versionId, setVersionId] = useState();
  const [jsonConfig, setJsonConfig] = useState({});
  const [isEditing, setIsEditing] = useState(false);
  const [newJsonConfig, setNewJsonConfig] = useState({});
  const [isEditable, setIsEditable] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isPublished, setIsPublished] = useState(false);

  useEffect(() => {
    acquireToken();
  });

  useEffect(() => {
    if (currentClientId && isAuthorized(appRoles.IsConfigReader)) {
      apiRoot
        .get(`/config/configs?clientId=${currentClientId}`)
        .then((res) => setClientConfigs(res.data));
    }
  }, [currentClientId, versionId]);

  useEffect(() => {
    if (currentClientId && versionId && isAuthorized(appRoles.IsConfigReader)) {
      fetchConfigVersion();
    }
  }, [currentClientId, versionId]);

  const fetchConfigVersion = async () => {
    const toastMessages = {
      pending: `Fetching Config`,
      success: `SUCCESS - Config Found`,
      error: "FAILED - Please Reach out to Support for Assistance",
    };

    const promise = apiRoot.get(`/config/version/${versionId}`);
    const res = await toast.promise(promise, toastMessages);
    setIsEditable(
      !["PUBLISHED", "ARCHIVED", "QUEUED"].includes(res.data.status)
    );
    setJsonConfig(JSON.parse(res.data.jsonObject));
    setNewJsonConfig(JSON.parse(res.data.jsonObject));
    setIsPublished(res.data.status === "PUBLISHED");
  };

  const handleConfigChange = (row, value, header) => {
    if (header === "Logic") {
      setJsonConfig(updateKVP({ ...jsonConfig }, row.ColumnHeader, value));
    } else if (header === "ColumnHeader") {
      setJsonConfig(
        updateConfigKey({ ...jsonConfig }, row.ColumnHeader, value)
      );
    }
  };

  const handleConfigEdit = () => {
    setIsEditing(true);
  };

  const handleConfigSave = async () => {
    setIsEditing(false);

    const toastMessages = {
      pending: `Saving Config`,
      success: `SUCCESS - Config Saves`,
      error: "FAILED - Please Reach out to Support for Assistance",
    };

    const promise = apiRoot.post(
      `/config/version/${versionId}/${user.id}`,
      jsonConfig
    );
    const res = await toast.promise(promise, toastMessages);

    setJsonConfig(JSON.parse(res.data.jsonObject));
    setNewJsonConfig(JSON.parse(res.data.jsonObject));
  };

  const updateKVP = (obj, key, updatedValue) => {
    if (typeof obj === "object") {
      Object.keys(obj).forEach((k) => {
        if (typeof obj[k] === "object") {
          if (obj[k].ColumnHeader === key) {
            obj[k].Logic = updatedValue;
            return obj;
          } else {
            const result = updateKVP(obj[k], key, updatedValue);
            if (result !== null) return result;
          }
        }
      });
    }

    return obj;
  };

  const updateConfigKey = (obj, key, updatedValue) => {
    if (typeof obj === "object") {
      Object.keys(obj).forEach((k) => {
        if (typeof obj[k] === "object") {
          if (obj[k].ColumnHeader === key) {
            obj[k].ColumnHeader = updatedValue;
            return obj;
          } else {
            const result = updateConfigKey(obj[k], key, updatedValue);
            if (result !== null) return result;
          }
        }
      });
    }

    return obj;
  };

  const handleAddConfig = (bucket) => {
    const configUpdate = {
      ...jsonConfig.ConfigLogic,
      ...{
        [bucket]: [
          ...jsonConfig.ConfigLogic[bucket],
          {
            Logic: `REPLACE_ME_${jsonConfig.ConfigLogic[bucket].length + 1}`,
            ColumnHeader: `REPLACE_ME_${
              jsonConfig.ConfigLogic[bucket].length + 1
            }`,
            ExecutionOrder: jsonConfig.ConfigLogic[bucket].length + 1,
          },
        ],
      },
    };

    setJsonConfig({ ...jsonConfig, ConfigLogic: { ...configUpdate } });
  };

  const removeKVP = (obj, keyToRemove) => {
    if (typeof obj === "object") {
      Object.keys(obj).forEach((k, index) => {
        if (typeof obj[k] === "object") {
          if (obj[k].ColumnHeader === keyToRemove) {
            return obj.splice(index, 1);
          } else {
            const result = removeKVP(obj[k], keyToRemove);
            if (result !== null) {
              if (result.length >= 1) {
                result.map((item, i) => {
                  if (typeof item === "object") {
                    item.ExecutionOrder = i + 1;
                  }
                });
              }
              return result;
            }
          }
        }
      });
    }

    return obj;
  };

  const handleRemoveConfig = (key) => {
    const filteredConfig = removeKVP(
      { ...jsonConfig.ConfigLogic },
      key.ColumnHeader
    );

    setJsonConfig({ ...jsonConfig, ConfigLogic: { ...filteredConfig } });
    setIsDeleting(false);
  };

  const handleConfigDelete = () => {
    setIsDeleting(!isDeleting);
  };

  const handleConfigCancel = () => {
    setIsEditing(false);
    setIsDeleting(false);
    setJsonConfig(newJsonConfig);
  };

  const handleConfigPublish = async () => {
    const toastMessages = {
      pending: `Publishing Config`,
      success: `SUCCESS - Config Published`,
      error: "FAILED - Please Reach out to Support for Assistance",
    };

    const promise = apiRoot.post(
      `/config/version/${versionId}/publish/${user.id}`
    );
    const res = await toast.promise(promise, toastMessages);

    setJsonConfig(JSON.parse(res.data.jsonObject));
    setNewJsonConfig(JSON.parse(res.data.jsonObject));
    setIsEditable(false);
    setIsPublished(res.data.status === "PUBLISHED");
    fetchQueuedConfigs();
  };

  const handleConfigPublishQueue = async () => {
    const toastMessages = {
      pending: `Publishing Config to Approval Queue`,
      success: `SUCCESS - Config Published to Queue`,
      error: "FAILED - Please Reach out to Support for Assistance",
    };

    const promise = apiRoot.post(
      `/config/version/${versionId}/queue/${user.id}`
    );
    const res = await toast.promise(promise, toastMessages);

    setJsonConfig(JSON.parse(res.data.jsonObject));
    setNewJsonConfig(JSON.parse(res.data.jsonObject));
    setIsEditable(false);
    setIsPublished(res.data.status === "PUBLISHED");
  };

  const value = {
    handleConfigChange: handleConfigChange,
    handleConfigEdit: handleConfigEdit,
    handleConfigSave: handleConfigSave,
    handleConfigCancel: handleConfigCancel,
    handleConfigDelete: handleConfigDelete,
    handleConfigPublish: handleConfigPublish,
    handleConfigPublishQueue: handleConfigPublishQueue,
    handleAddConfig: handleAddConfig,
    handleRemoveConfig: handleRemoveConfig,
    jsonConfig: jsonConfig,
    setJsonConfig: setJsonConfig,
    newJsonConfig: newJsonConfig,
    isEditing: isEditing,
    setVersionId: setVersionId,
    isEditable: isEditable,
    clientConfigs: clientConfigs,
    isDeleting: isDeleting,
    isPublished: isPublished,
  };

  return (
    <ConfigContext.Provider value={value}>{children}</ConfigContext.Provider>
  );
};
