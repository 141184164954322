import React, { createContext, useState, useEffect, useContext } from "react";
import fileSplitApi from "../api/fileSplitApi";
import { v4 as uuidv4 } from "uuid";
import { ClientContext } from "./ClientContext";

export const FilesplitContext = createContext();

export const FilesplitStore = ({ children }) => {
  const [clients, setClients] = useState([]);
  const [selectedSchool, setSelectedSchool] = useState([]);
  const [queuedFiles, setQueuedFiles] = useState([]);
  const [splittingFile, setSplittingFile] = useState([]);
  const [completedFiles, setCompletedFiles] = useState([]);
  const [isSplitting, setIsSplitting] = useState(false);
  const { currentClientId } = useContext(ClientContext);

  useEffect(() => {
    setCompletedFiles([]);
    setSplittingFile([]);
    setQueuedFiles([]);
  }, [currentClientId]);

  function splitFile(file) {
    fileSplitApi
      .post("/http-splitfile", {
        override_env: "dev",
        event_subject: file.filePath.substring(1),
      })
      .then((response) => {
        const newCompletedFiles = [
          ...completedFiles,
          { ...file, status: "Success", colsRemoved: response.data },
        ];
        setIsSplitting(false);
        setCompletedFiles(newCompletedFiles);
        setSplittingFile([]);
      })
      .catch((response) => {
        const newCompletedFiles = [
          ...completedFiles,
          { ...file, status: "Failed", colsRemoved: [] },
        ];
        setIsSplitting(false);
        setCompletedFiles(newCompletedFiles);
        setSplittingFile([]);
      });
  }

  useEffect(() => {
    if (splittingFile.length === 0) {
      pollQueuedFiles();
    }
    // eslint-disable-next-line
  }, [queuedFiles, completedFiles, splittingFile]);

  function pollQueuedFiles() {
    if (!isSplitting && queuedFiles.length > 0) {
      const newQueuedFiles = [...queuedFiles];
      const fileToSplit = newQueuedFiles.shift();
      setQueuedFiles(newQueuedFiles);
      setIsSplitting(true);
      setSplittingFile([
        { ...fileToSplit, status: "Splitting", colsRemoved: [] },
      ]);
      splitFile(fileToSplit);
    }
  }

  const addFile = (file) => {
    setQueuedFiles([
      ...queuedFiles,
      { ...file, status: "Queued", id: uuidv4(), colsRemoved: [] },
    ]);
  };

  const addFileArray = (files) => {
    const newFiles = [...queuedFiles];
    files.map((file) => {
      newFiles.push({
        ...file,
        status: "Queued",
        id: uuidv4(),
        colsRemoved: [],
      });
    });
    setQueuedFiles(newFiles);
  };

  const value = {
    clients: clients,
    setClients: setClients,
    queuedFiles: queuedFiles,
    setQueuedFiles: setQueuedFiles,
    selectedSchool: selectedSchool,
    setSelectedSchool: setSelectedSchool,
    addFile: addFile,
    addFileArray: addFileArray,
    completedFiles: completedFiles,
    splittingFile: splittingFile,
  };
  return (
    <FilesplitContext.Provider value={value}>
      {children}
    </FilesplitContext.Provider>
  );
};
