import React from "react";
import Switch from "react-switch";

const ToggleSwitch = ({ label, labelCssName, onChange, checked }) => {
  return (
    <div className="flex items-center gap-1">
      <span className={labelCssName}>{label}</span>
      <Switch
        checked={checked}
        onChange={onChange}
        onColor="#b6de3a"
        onHandleColor="#fff"
        handleDiameter={18}
        uncheckedIcon={false}
        checkedIcon={false}
        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
        height={20}
        width={36}
        className="react-switch"
        id="material-switch"
      />
    </div>
  );
};

export default ToggleSwitch;
