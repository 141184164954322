import React, { forwardRef } from "react";
import classNames from "classnames";

import Icons from "assets/icons";

const Textarea = forwardRef(
  (
    {
      textareaWrapperStyles = "",
      textareaStyles = "",
      label,
      placeholder,
      limit = 250,
      onChange,
      disabled,
      value = "",
      id = "",
      errorsNames = {},
      required = false,
      charactersUsedShown = false,
    },
    ref
  ) => {
    const isValid = !Object.keys(errorsNames).length;

    const handleChangeValue = (e) => {
      onChange(e.target.value);
    };

    const baseWrapperStyles = `w-full flex flex-col ${textareaWrapperStyles}`;
    const baseStyles = classNames(
      `w-full h-full border focus-visible:outline-0 rounded-md py-2 px-3 ${textareaStyles}`,
      {
        "bg-gray-06": disabled,
        "border-red-03": !isValid,
        "border-gray-02": isValid,
      }
    );

    return (
      <div className={baseWrapperStyles}>
        {label && (
          <label htmlFor={id} className="mb-2 flex font-semibold">
            {label}
            {required && (
              <Icons.RequiredIndicator customClass="w-[7px] h-[7px] ml-1 mt-1" />
            )}
          </label>
        )}
        <textarea
          ref={ref}
          className={baseStyles}
          placeholder={placeholder}
          value={value}
          onChange={handleChangeValue}
          maxLength={limit}
          disabled={disabled}
          id={id}
        />
        {!!charactersUsedShown && (
          <span className="mt-1 text-sm">
            {value.length} of {limit} characters used
          </span>
        )}
        {!isValid && (
          <span className="font-sm bg-white pt-1 text-red-03">
            {errorsNames.message}
          </span>
        )}
      </div>
    );
  }
);

export default Textarea;
