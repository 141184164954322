import React, { useContext } from "react";
import { useSelector } from "react-redux";
import uniqid from "uniqid";

import { ClientContext } from "context/ClientContext";

const ProcessedFilesTable = () => {
  const { batchData } = useSelector((state) => state?.batch);

  const { currentClientData } = useContext(ClientContext);

  return (
    <>
      <span>{`The following files are ready for publishing to ${currentClientData?.institutionName}:`}</span>
      {batchData.processedFiles.files.map((file) => (
        <React.Fragment key={uniqid()}>
          <span className="w-full pt-2 font-semibold">{file.fileName}</span>
          <table className="max-w-[450px] text-gray-05">
            <thead>
              <tr className="font-medium text-gray-08">
                <td className="w-2">File Count</td>
                <td className="w-10">List Source Provider</td>
                <td className="w-14">Intended Term</td>
              </tr>
            </thead>
            <tbody>
              {file.stats.map((stat) => (
                <tr key={uniqid()}>
                  <td>{stat.fileRowCount}</td>
                  <td>{stat.listSourceProvider}</td>
                  <td>{stat.intendedTerm}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </React.Fragment>
      ))}
    </>
  );
};

export default ProcessedFilesTable;
