import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { PublicClientApplication } from "@azure/msal-browser";
import * as FullStory from "@fullstory/browser";

import App from "app";
import store from "state/store";

import { msalConfig } from "./config/authConfig";
import { MsalProvider } from "@azure/msal-react";
import { AuthStore } from "./context/AuthContext";
import "./styles/index.css";

FullStory.init({ orgId: "o-1CBCVA-na1", namespace: "am" });

const msalInstance = new PublicClientApplication(msalConfig);

const container = document.querySelector("#root");

const root = createRoot(container);

/* 
  For the brave souls who enter. This entire application could use a refactor. It was pieced together starting from scratch for me to learn
  how to use react and javascript in 2021. Since then I have come a decent way and hopefully you can notice glimpses of better practices and 
  what not. I would love to pause this application here and rewrite from the ground up using typescript and implement more control and better
  practices. But for now it works!
*/
root.render(
  <MsalProvider instance={msalInstance}>
    <Provider store={store}>
      <AuthStore>
        <App />
      </AuthStore>
    </Provider>
  </MsalProvider>
);
