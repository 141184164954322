import React, { useContext } from "react";
import { NavLink } from "react-router-dom";
import { AuthContext } from "context/AuthContext";

const Button = ({ label, onClick, className, labelClassName, iconLeft, iconRight, to, disabled, allowedAppRoles }) => {
  const { isAuthorized } = useContext(AuthContext);

  const renderButton = () => {
    if (to) {
      if (allowedAppRoles) {
        if (isAuthorized(allowedAppRoles)) {
          return (
            <NavLink className={className} to={`${to}`}>
              {iconLeft ? iconLeft : ""} <p className={labelClassName}>{label}</p> {iconRight ? iconRight : ""}
            </NavLink>
          );
        }
      }
      return (
        <NavLink className={className} to={`${to}`}>
          {iconLeft ? iconLeft : ""} <p className={labelClassName}>{label}</p> {iconRight ? iconRight : ""}
        </NavLink>
      );
    } else {
      let isAuth = true;
      let disabledCss = "";
      if (allowedAppRoles) {
        isAuth = isAuthorized(allowedAppRoles);
        disabledCss = " disabled:opacity-50 disabled:cursor-not-allowed";
      }
      return (
        <button className={className + disabledCss} onClick={onClick} disabled={disabled || !isAuth}>
          {iconLeft ? iconLeft : ""} <p className={labelClassName}>{label}</p> {iconRight ? iconRight : ""}
        </button>
      );
    }
  };

  return renderButton();
};

export default Button;
