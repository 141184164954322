import React, { useContext } from "react";
import { AuthContext } from "../context/AuthContext";
import { NavLink } from "react-router-dom";

const PrivateNavLink = ({ allowedAppRoles, to, className, children, onNavLinkClick }) => {
  const { isAuthorized } = useContext(AuthContext);

  const renderPrivateNavLink = () => {
    if (isAuthorized(allowedAppRoles)) {
      return (
        <NavLink to={to} className={className} onClick={onNavLinkClick}>
          {children}
        </NavLink>
      );
    }

    return null;
  };

  return renderPrivateNavLink();
};

export default PrivateNavLink;
