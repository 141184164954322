import React, { forwardRef } from "react";
import classNames from "classnames";

import Icons from "assets/icons";

const CustomTextInput = forwardRef(
  (
    {
      customClass = "",
      label = "",
      id = "",
      errorsNames = {},
      isErrorMessageHidden,
      helperText = "",
      domainText = "",
      type = "text",
      placeholder = "Type...",
      disabled,
      disabledClassName,
      inputCustomClass = "",
      inputWrapperCustomClass = "",
      labelInTop,
      disabledBgColor,
      required,
      ...rest
    },
    ref
  ) => {
    const isValid = Object.keys(errorsNames).length === 0;

    const inputStyles = classNames(
      "border border-solid rounded-md p-2.5 focus:outline-none",
      {
        "border-red-04": !isValid,
        "border-gray-02": isValid,
      },
      disabledClassName
        ? `disabled:${disabledClassName}`
        : "disabled:bg-transparent",
      inputCustomClass
    );

    const labelStyles = classNames("font-family font-semibold", customClass, {
      "w-32 mr-14 mb-5": !labelInTop,
      "flex mb-1 mt-3": labelInTop,
    });

    return (
      <div className="flex w-full flex-col">
        <div
          className={classNames("flex", {
            "flex-col items-start": labelInTop,
            "items-center": !labelInTop,
          })}
        >
          {label && (
            <label htmlFor={id} className={labelStyles}>
              {label}
              {required ? (
                <Icons.RequiredIndicator customClass="w-[7px] h-[7px] ml-1 mt-1" />
              ) : null}
            </label>
          )}
          <div className={`flex w-full flex-col ${inputWrapperCustomClass}`}>
            <input
              id={id}
              className={inputStyles}
              ref={ref}
              type={type}
              placeholder={placeholder}
              disabled={disabled}
              {...rest}
            />
            {!isValid && !disabled && !isErrorMessageHidden && (
              <span className="font-sm bg-white pt-1 text-red-03">
                {errorsNames.message}
              </span>
            )}
            {helperText && !disabled && isValid && (
              <span id="custom-input" className="font-sm bg-white pt-1">
                {`${helperText} ${domainText}`}
              </span>
            )}
          </div>
        </div>
      </div>
    );
  }
);

export default CustomTextInput;
