import React, { useContext } from "react";
import { AuthContext } from "context/AuthContext";

const LatestFiles = ({ types, onButtonClick, label, allowAppRoles }) => {
  const { isAuthorized } = useContext(AuthContext);

  const defaultCssName =
    "cursor-pointer rounded-md border border-solid border-gray-02 bg-white px-4 py-2 text-base leading-6 text-gray-04 no-underline shadow-bs-secondary active:border-yellow-01 active:font-semibold disabled:opacity-50 disabled:cursor-not-allowed";

  function renderLinks() {
    return types.map((type) => {
      return (
        <button
          className={defaultCssName}
          key={type}
          onClick={() => onButtonClick(type)}
          disabled={!isAuthorized(allowAppRoles)}
        >
          {type}
        </button>
      );
    });
  }

  return (
    <div className="flex flex-col">
      <div className="text-blue-03 pb-4 text-xl font-bold leading-6">
        {label}
      </div>
      <div className="flex gap-3"> {renderLinks()}</div>
    </div>
  );
};

export default LatestFiles;
