import React, { useContext } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import * as FullStory from "@fullstory/browser";

import AppHeader from "components/AppHeader";
import PrivateRoutes from "components/PrivateRoutes";

import Home from "pages/home";
import ClientHomePage from "pages/client-home/client-home";
import Approve from "pages/approve/Approve";
import TranscriptSummarization from "pages/transcript-summarization/transcript-summarization";

import { InstitutionStore } from "context/InstitutionContext";

import { ClientStore } from "context/ClientContext";
import { FileMappingsStore } from "context/FileMappingsContext";
import { FileMaskStore } from "context/FileMasksContext";
import { ListPlusStore } from "context/ListPlusContext";
import { AuthContext } from "context/AuthContext";
import { ConfigStore } from "context/ConfigContext";
import { FilesplitStore } from "context/FilesplitContext";
import { TranscriptSummarizationStore } from "context/TranscriptSummarizationContext";

import appRoles, {
  AI_TRANSCRIPT_SUMMARIZATION_WRITER,
  AI_TRANSCRIPT_SUMMARIZATION_READER,
} from "config/appRoles";

const AuthenticatedApp = () => {
  const { onlyAuthorized, user } = useContext(AuthContext);

  FullStory.identify(user?.email);

  return (
    <InstitutionStore>
      <ClientStore>
        <FilesplitStore>
          <ConfigStore>
            <FileMaskStore>
              <FileMappingsStore>
                <ListPlusStore>
                  <TranscriptSummarizationStore>
                    <BrowserRouter>
                      <AppHeader />
                      <Routes>
                        <Route
                          exact
                          path="/"
                          element={
                            onlyAuthorized(
                              AI_TRANSCRIPT_SUMMARIZATION_READER
                            ) ||
                            onlyAuthorized(
                              AI_TRANSCRIPT_SUMMARIZATION_WRITER
                            ) ? (
                              <TranscriptSummarization />
                            ) : (
                              <Home />
                            )
                          }
                        />
                        <Route
                          exact
                          path="clients/:clientid/*"
                          element={<ClientHomePage />}
                        />
                        <Route
                          element={
                            <PrivateRoutes
                              allowedAppRoles={appRoles.IsConfigApprover}
                            />
                          }
                        >
                          <Route
                            exact
                            path="/approve/*"
                            element={<Approve />}
                          ></Route>
                        </Route>
                        <Route
                          element={
                            <PrivateRoutes
                              allowedAppRoles={
                                appRoles.IsAiTranscriptSummarizationReader
                              }
                            />
                          }
                        >
                          <Route
                            exact
                            path="/summarize/*"
                            element={<TranscriptSummarization />}
                          />
                        </Route>
                      </Routes>
                    </BrowserRouter>
                  </TranscriptSummarizationStore>
                </ListPlusStore>
              </FileMappingsStore>
            </FileMaskStore>
          </ConfigStore>
        </FilesplitStore>
      </ClientStore>
    </InstitutionStore>
  );
};

export default AuthenticatedApp;
