import React, { createContext, useState, useEffect } from "react";
import apiRoot from "../api/apiRoot";
import { useMsal } from "@azure/msal-react";
import jwt_decode from "jwt-decode";

export const AuthContext = createContext();

export const AuthStore = ({ children }) => {
  const { instance, accounts } = useMsal();
  const [userId, setUserId] = useState(null);
  const [user, setUser] = useState([]);
  const [userName, setUserName] = useState([]);
  const [appRoles, setAppRoles] = useState([]);
  const [jwt, setJwt] = useState(null);

  useEffect(() => {
    if (accounts[0]) {
      setUserId(accounts[0].localAccountId);
      setAppRoles(accounts[0].idTokenClaims.roles);
      setUserName(accounts[0].name);
      console.log(accounts[0].name);
      console.log(accounts[0].idTokenClaims.roles);
      fetchUser();
    }
  }, [accounts]);

  useEffect(() => {
    if (jwt) {
      localStorage.setItem("jwt", jwt);
    }
  }, [jwt]);

  async function acquireToken() {
    // This method gets called on each response from axios interceptor and will check the jwt. We add 5 minutes to the jwt to
    // try and prevent any timing errors on when the jwt expires
    if (accounts[0]) {
      let token = localStorage.getItem("jwt");
      let accessTokenRequest;
      if (token) {
        let decodedToken = jwt_decode(token);
        let currentDate = new Date();
        currentDate.setMinutes(currentDate.getMinutes() + 5);

        accessTokenRequest = {
          scopes: ["user.read"],
          account: accounts[0],

          forceRefresh: decodedToken.exp * 1000 < currentDate.getTime(),
        };
      } else {
        accessTokenRequest = {
          scopes: ["user.read"],
          account: accounts[0],
          forceRefresh: true,
        };
      }
      const accessTokenResponse = await instance.acquireTokenSilent(accessTokenRequest);
      setJwt(accessTokenResponse.idToken);
    }
  }

  const fetchUser = () => {
    if (accounts[0]) {
      apiRoot
        .post(`/auth/authenticate`, {
          userId: accounts[0].localAccountId,
          name: accounts[0].name,
          email: accounts[0].username,
        })
        .then((res) => {
          setUser(res.data);
        });
    }
    acquireToken();
  };

  function logOut() {
    instance.logoutPopup();
  }

  function isAuthorized(allowedAppRoles) {
    if (!appRoles) {
      return false;
    }

    return allowedAppRoles.filter((allowedAppRole) => appRoles.includes(allowedAppRole)).length > 0;
  }

  function onlyAuthorized(allowedAppRole) {
    if (!appRoles || appRoles.length > 1) {
      return false;
    }

    return appRoles[0] === allowedAppRole;
  }

  const value = {
    user: user,
    userId: userId,
    logOut: logOut,
    fetchUser: fetchUser,
    setUser: setUser,
    setJwt: setJwt,
    isAuthorized: isAuthorized,
    acquireToken: acquireToken,
    instance: instance,
    userName: userName,
    onlyAuthorized: onlyAuthorized,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
