import React, { useState, useContext, useEffect } from "react";
import { AuthContext } from "context/AuthContext";
import TextInput from "components/partials/TextInput";
import Icons from "assets/icons";
import apiRoot from "api/apiRoot";
import appRoles from "config/appRoles";

const FtpFolderName = ({ currentClientId, currentFtpFolderName }) => {
  const [finalFTPFolderName, setFinalFTPFolderName] = useState("");
  const [ftpFolderName, setFTPFolderName] = useState("");
  const [isEditing, setIsEditing] = useState(false);

  const { isAuthorized } = useContext(AuthContext);

  useEffect(() => {
    if (currentFtpFolderName) {
      setFTPFolderName(currentFtpFolderName);
      setFinalFTPFolderName(currentFtpFolderName);
    }
  }, [currentFtpFolderName]);

  const handleFTPFolderName = (folderName) => {
    setFTPFolderName(folderName);
  };

  const saveFTPFolderName = () => {
    setFinalFTPFolderName(ftpFolderName);
    apiRoot.post(
      `/client/ftp-folder?clientId=${currentClientId}&ftpFolderName=${ftpFolderName}`
    );
  };

  const renderFTPFolderName = () => {
    if (isEditing) {
      return (
        <div className="flex flex-col gap-2 ">
          <TextInput
            label="FTP Folder Name"
            term={ftpFolderName}
            setTerm={handleFTPFolderName}
            labelClasses="font-semibold text-gray-05"
            inputClasses={
              "h-16 rounded border border-solid border-black bg-white px-3 py-2 focus:outline-none focus-visible:outline-none"
            }
            placeholder={"FTP Folder Name..."}
          />
          <div className="flex items-center justify-end gap-2">
            <button
              className="rounded-md bg-red-400 px-3 py-1 font-semibold "
              onClick={() => {
                setFTPFolderName(finalFTPFolderName);
                setIsEditing(false);
              }}
            >
              Cancel
            </button>
            <button
              className="text-blue-03 bg-yellow-01 rounded-md px-3 py-1 font-semibold"
              onClick={() => {
                saveFTPFolderName();
                setIsEditing(false);
              }}
            >
              Save
            </button>
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <p>{finalFTPFolderName}</p>
        </div>
      );
    }
  };

  return (
    <div className="flex flex-col gap-1">
      <div className="flex flex-row gap-5">
        <p className="text-gray-05 font-semibold">FTP Folder Name</p>
        {isAuthorized(appRoles.IsSchoolSettingsAdmin) && (
          <div className="flex flex-col gap-2">
            <div
              className="flex cursor-pointer items-center gap-1"
              onClick={() => setIsEditing(true)}
            >
              <Icons.Edit />
              <div className="text-blue-03 font-semibold">Edit</div>
            </div>
          </div>
        )}
      </div>

      {renderFTPFolderName()}
    </div>
  );
};

export default FtpFolderName;
