import axios from "axios";
import { env } from "env";

// axios instance for making requests
const axiosInstance = axios.create({
  baseURL: env.REACT_APP_ATOM_API_ENDPOINT,
});

// request interceptor for adding token
axiosInstance.interceptors.request.use((config) => {
  // add token to request headers
  config.headers["Authorization"] = `Bearer ${localStorage.getItem("jwt")}`;

  return config;
});

export default axiosInstance;
