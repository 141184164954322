import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import SchoolSelectList from "components/partials/SchoolSelectList";
import Button from "components/partials/Button";
import { ClientContext } from "context/ClientContext";
import { AuthContext } from "context/AuthContext";
import { FileMappingsContext } from "context/FileMappingsContext";
import apiRoot from "api/apiRoot";

const CreateMappings = () => {
  const fileProcessTypes = [
    "demographic",
    "funds",
    "financials",
    "term",
    "clearinghouse",
  ];
  const [selectedFPT, setSelectedFPT] = useState();
  const [selectedFPST, setSelectedFPST] = useState();

  const fileProcessSubTypes = {
    demographic: ["inquiry", "application", "prospect", "singlefile"],
    financials: ["normal"],
    funds: ["pivoted", "unpivoted"],
    term: ["pivoted", "unpivoted"],
    clearinghouse: ["normal"],
  };

  const { currentClientId } = useContext(ClientContext);
  const { user } = useContext(AuthContext);
  const { fetchMappings } = useContext(FileMappingsContext);
  const navigate = useNavigate();
  const [mappingVersions, setMappingVersions] = useState([]);
  const [selectedMappingVersion, setSelectedMappingVersions] = useState("");

  useEffect(() => {
    if ((currentClientId, selectedFPT, selectedFPST)) {
      apiRoot
        .get(
          `/filesetup/filemappings/versionsByType?fileProcessType=${selectedFPT}&clientId=${currentClientId}&fileProcessSubType=${selectedFPST}`
        )
        .then((res) => {
          setMappingVersions(res.data.map((item) => item.id));
          setSelectedMappingVersions(res.data.map((item) => item.id)[0]);
        });
    }
  }, [currentClientId, selectedFPT, selectedFPST]);

  const handleFPTSelect = (value) => {
    setSelectedFPT(value);
  };

  const handleFPSTSelect = (value) => {
    setSelectedFPST(value);
  };

  const handleCreate = () => {
    let createMappingsPromise = apiRoot.post(`/filesetup/filemappings/create`, {
      clientId: currentClientId,
      fileProcessType: selectedFPT,
      fileProcessSubType: selectedFPST,
      userId: user.id,
      versionId: selectedMappingVersion,
    });

    createMappingsPromise.then((res) => {
      navigate(
        `/clients/${currentClientId}/file-mappings/${res.data.parentConfigId}/versions/${res.data.id}`
      );
    });

    createMappingsPromise.then(() => {
      fetchMappings();
    });
  };

  const handleVersionSelect = (value) => {
    setSelectedMappingVersions(value);
  };

  return (
    <div className="flex flex-col gap-5">
      <div className="mt-5 flex w-[900px] gap-5">
        <div className="flex flex-col gap-1">
          <p>File Process Type</p>
          <SchoolSelectList
            options={fileProcessTypes.filter((item) => item !== selectedFPT)}
            onChange={handleFPTSelect}
            defaultValue={selectedFPT}
          />
        </div>
        {selectedFPT ? (
          <div className="flex flex-col gap-1">
            <p>File Process Sub Type</p>
            <SchoolSelectList
              options={fileProcessSubTypes[selectedFPT].filter(
                (item) => item !== selectedFPST
              )}
              onChange={handleFPSTSelect}
              defaultValue={selectedFPST}
            />
          </div>
        ) : (
          <></>
        )}
        {selectedFPT && selectedFPST && mappingVersions ? (
          <div className="flex w-[250px] flex-col gap-1">
            <p>Based On Version</p>
            <SchoolSelectList
              options={mappingVersions.filter(
                (item) => item !== selectedMappingVersion
              )}
              onChange={handleVersionSelect}
              defaultValue={selectedMappingVersion}
            />
          </div>
        ) : (
          <></>
        )}
      </div>
      <Button
        label={"Create"}
        className="rounded-md bg-yellow-01 px-4 py-1 text-blue-03"
        onClick={handleCreate}
      />
    </div>
  );
};

export default CreateMappings;
