export const BATCHES_STATUSES = {
  CONFIGURING: "CONFIGURING",
  RECONFIGURING: "RECONFIGURING",
  PROCESSING: "PROCESSING",
  PROCESSING_FAILED: "PROCESSING_FAILED",
  READY_FOR_QA: "READY_FOR_QA",
  PUBLISHING: "PUBLISHING",
  PUBLISHING_FAILED: "PUBLISHING_FAILED",
  PUBLISHED: "PUBLISHED",
  DELETED: "DELETED",
};
