import React, { createContext, useContext, useState, useEffect } from "react";
import apiRoot from "../api/apiRoot";
import { AuthContext } from "./AuthContext";

export const InstitutionContext = createContext();

export const InstitutionStore = ({ children }) => {
  const { user } = useContext(AuthContext);
  const [bookmarkedInstitutions, setBookmarkedInstitutions] = useState([]);
  const [institutions, setInstitutions] = useState([]);
  const [queuedConfigs, setQueuedConfigs] = useState([]);

  useEffect(() => {
    if (!!user.id) {
      retrieveBookmarkedInstitutions();
      fetchQueuedConfigs();
    }
    // eslint-disable-next-line
  }, [user.id]);

  function retrieveBookmarkedInstitutions() {
    !!user.id &&
      apiRoot.get(`/user/bookmarks?userId=${user.id}`).then((res) => {
        setBookmarkedInstitutions(res.data);
      });
  }

  function fetchQueuedConfigs() {
    apiRoot.get(`/config/queued`).then((res) => {
      setQueuedConfigs(res.data);
    });
  }

  async function retrieveInstitutionsByTags(tags) {
    if (tags) {
      apiRoot
        .post(
          `/client/searchByTag?`,
          tags.map((tag) => tag.id)
        )
        .then((res) => {
          setInstitutions(
            res.data.map((item) => {
              const isBookmarked = bookmarkedInstitutions.filter((bm) => {
                return item.id === bm.id;
              });

              return { ...item, isBookmarked: isBookmarked.length > 0 };
            })
          );
        });
    }
  }

  function findInstitutionById(id) {
    return institutions.find((item) => item.id === id);
  }

  const value = {
    bookmarkedInstitutions: bookmarkedInstitutions,
    setBookmarkedInstitutions: setBookmarkedInstitutions,
    institutions: institutions,
    setInstitutions: setInstitutions,
    findInstitutionById: findInstitutionById,
    retrieveBookmarkedInstitutions: retrieveBookmarkedInstitutions,
    retrieveInstitutionsByTags: retrieveInstitutionsByTags,
    queuedConfigs: queuedConfigs,
    fetchQueuedConfigs: fetchQueuedConfigs,
  };

  return (
    <InstitutionContext.Provider value={value}>
      {children}
    </InstitutionContext.Provider>
  );
};
