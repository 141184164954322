const commonStyles = "border-r-2 border-white font-semibold";

export const summaryTableSettings = () =>
  [
    {
      id: "description",
      accessorKey: "description",
      header: () => <span>SOURCE</span>,
      cell: (info) => info.renderValue(),
      headerClassName: `${commonStyles} rounded-tl-md rounded-bl-md cursor-pointer`,
      className: "text-left pl-4 w-7/12",
    },
    {
      id: "count",
      accessorKey: "count",
      header: () => <span>RECORDS</span>,
      cell: (info) => info.renderValue(),
      headerClassName: `${commonStyles} cursor-context-menu rounded-tr-md rounded-br-md`,
      className: "pl-4 py-2 w-5/12",
    },
  ].filter((item) => item);
