import React, { createContext, useState, useEffect, useContext } from "react";
import { toast } from "react-toastify";
import apiRoot from "../api/apiRoot";
import { AuthContext } from "./AuthContext";
import { InstitutionContext } from "./InstitutionContext";
export const ClientContext = createContext();

export const ClientStore = ({ children }) => {
  const { findInstitutionById } = useContext(InstitutionContext);
  const { user } = useContext(AuthContext);

  const [currentClientId, setCurrentClientId] = useState(null);
  const [currentClientData, setCurrentClientData] = useState(null);

  useEffect(() => {
    if (user && currentClientId && (!currentClientData || currentClientId !== currentClientData.id)) {
      const institution = findInstitutionById(currentClientId);
      if (!institution) {
        fetchClient();
      } else {
        setCurrentClientData(institution);
      }
    }
  }, [currentClientId]);

  async function fetchClient() {
    apiRoot.get(`/client?clientId=${currentClientId}`).then((res) => {
      setCurrentClientData(res.data);
    });
  }

  async function updateBlobContainer(bc) {
    const toastMessages = {
      pending: `Updating Client Blob Container`,
      success: `SUCCESS - Client Blob Container updated`,
      error: "FAILED - Please Reach out to Support for Assistance",
    };
    const promise = apiRoot.post(`/client/containers?clientId=${currentClientId}&newBlobContainer=${bc}`);
    const res = await toast.promise(promise, toastMessages);
    setCurrentClientData(res.data);
  }

  async function updateAnalyst(analystUserId) {
    const toastMessages = {
      pending: `Updating Client Primary Analyst`,
      success: `SUCCESS - Client Primary Analyst updated`,
      error: "FAILED - Please Reach out to Support for Assistance",
    };
    const promise = apiRoot.post(`/client/analyst?clientId=${currentClientId}&newAnalystId=${analystUserId}`);
    const res = await toast.promise(promise, toastMessages);
    setCurrentClientData(res.data);
  }

  const value = {
    currentClientId: currentClientId,
    setCurrentClientId: setCurrentClientId,
    currentClientData: currentClientData,
    setCurrentClientData: setCurrentClientData,
    updateBlobContainer: updateBlobContainer,
    updateAnalyst: updateAnalyst,
    fetchClient: fetchClient,
  };

  return <ClientContext.Provider value={value}>{children}</ClientContext.Provider>;
};
