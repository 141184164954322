import React from "react";
import AssignBlobContainer from "./AssignBlobContainer";
import AssignAnalyst from "./AssignAnalyst";

const Onboard = () => {
  return (
    <div className="onboard-home">
      <AssignBlobContainer showClientName={false} />
      <br />
      <AssignAnalyst showClientName={false} />
    </div>
  );
};

export default Onboard;
