import React, { useRef, useEffect, useState, useCallback } from "react";
import ReactDOM from "react-dom";

import CustomButton from "components/partials/custom-button/custom-button";
import Heading from "components/partials/heading/heading";

const MODAL_CONTAINER = document.getElementById("modal-container");

const ConfirmationModal = ({
  headerText,
  onConfirmCallback,
  onConfirmButtonLabel = "OK",
  confirmButtonIcon,
  confirmButtonVariant = "primary",
  children,
  onCloseCallback,
  text,
  onCloseModal = () => {},
}) => {
  // eslint-disable-next-line
  const [isSaveBtnDisabled, setIsSaveBtnDisabled] = useState(false);

  const onConfirm = () => {
    onConfirmCallback();
    onClose();
  };

  const onClose = useCallback(() => {
    ReactDOM.unmountComponentAtNode(MODAL_CONTAINER);
    if (onCloseCallback) {
      onCloseCallback();
    }
  }, [onCloseCallback]);

  const modalWrapperRf = useRef();
  const modalInnerRf = useRef();

  const handleClickOutside = (e) => {
    if (
      !modalWrapperRf.current?.contains(e.target) &&
      e.target !== modalInnerRf.current
    ) {
      onClose();
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
    // eslint-disable-next-line
  }, []);

  return (
    <div
      className="fixed inset-0 z-[701] h-[100vh] max-h-[100vh] overflow-hidden"
      ref={modalWrapperRf}
    >
      <div className="absolute inset-0 !bg-gray-07 opacity-60" />
      <div className="absolute left-1/2 top-2/4 -translate-x-1/2 -translate-y-1/2">
        <div
          ref={modalInnerRf}
          className="flex flex-col justify-between overflow-hidden rounded-md bg-white p-8 mobile:w-[330px] tablet:w-[500px]"
        >
          <Heading
            variant="h1"
            customClass="mobile:text-center tablet:text-left font-semibold"
          >
            {headerText}
          </Heading>
          {text && (
            <p className="mt-2 text-lg mobile:text-center tablet:text-left">
              {text}
            </p>
          )}

          {children}
          <div className="mt-6 flex justify-end space-y-2 mobile:flex-col-reverse tablet:flex-row tablet:space-y-0">
            <CustomButton
              onClick={() => {
                onClose();
                onCloseModal();
              }}
            >
              <span className="text-blue-03">Cancel</span>
            </CustomButton>
            <CustomButton
              variant={confirmButtonVariant}
              disabled={isSaveBtnDisabled}
              onClick={() => onConfirm()}
            >
              {!!confirmButtonIcon && confirmButtonIcon}
              {onConfirmButtonLabel}
            </CustomButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationModal;
