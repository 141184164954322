export const getIsAnyFileChanged = (changedFiles, uploadedFiles) => {
  const filesToCheck = getCheckedFiles(changedFiles);

  //by default we don't have changes
  let isChanged = false;

  for (let i = 0; i < uploadedFiles.length; i++) {
    const uploadedFile = uploadedFiles[i];
    const changedFile = filesToCheck[i];

    //we compare the default value of code and segmentGroup fileds with those that came
    const isNotChanged =
      uploadedFile.code === changedFile.code &&
      uploadedFile.segmentGroup === changedFile.segmentGroup &&
      uploadedFile.listSource === changedFile.listSource;

    //if at least one of those conditions is false - this means we have chnages, so we return true
    if (!isNotChanged) isChanged = true;
  }
  return isChanged;
};

export const getCheckedFiles = (data) =>
  data.map((el) => {
    if (!el.code) el.code = null;
    if (!el.segmentGroup) el.segmentGroup = null;

    return el;
  });
