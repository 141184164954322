import React, { useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { AuthContext } from "context/AuthContext";
import { ConfigContext } from "context/ConfigContext";
import ConfigViewer from "./config-viewer";
import Button from "components/partials/Button";
import appRoles from "config/appRoles";

const DisplayConfigs = () => {
  const { isAuthorized } = useContext(AuthContext);
  const { versionId } = useParams();
  const {
    handleConfigChange,
    handleConfigEdit,
    handleConfigSave,
    handleConfigCancel,
    handleConfigPublish,
    handleConfigPublishQueue,
    handleAddConfig,
    jsonConfig,
    isEditing,
    setVersionId,
    isEditable,
    handleRemoveConfig,
    handleConfigDelete,
    isDeleting,
    isPublished,
  } = useContext(ConfigContext);

  useEffect(() => {
    if (versionId) {
      setVersionId(versionId);
    }
    // eslint-disable-next-line
  }, [versionId]);

  const renderConfigs = () => {
    if (jsonConfig && "ConfigLogic" in jsonConfig) {
      return Object.keys(jsonConfig.ConfigLogic).map((k) => {
        if (typeof jsonConfig.ConfigLogic[k] === "object") {
          return (
            <div className="flex flex-col gap-1 pb-3" key={k}>
              <div className="flex items-center gap-3">
                <p className="font-semibold text-blue-03">{k}</p>
                {isAuthorized(appRoles.IsConfigWriter) &&
                isEditing &&
                k !== "FunnelMetrics" ? (
                  <div className="flex gap-3">
                    <Button
                      label={"Add Logic"}
                      className="rounded-md bg-yellow-01 px-4 py-1 text-blue-03"
                      onClick={() => handleAddConfig(k)}
                    />
                    <Button
                      label={"Remove Logic"}
                      className="rounded-md bg-yellow-01 px-4 py-1 text-blue-03"
                      onClick={() => handleConfigDelete()}
                    />
                  </div>
                ) : (
                  <></>
                )}
              </div>
              <ConfigViewer
                srcJson={jsonConfig.ConfigLogic[k]}
                contenteditable={
                  isAuthorized(appRoles.IsConfigWriter) &&
                  isEditing &&
                  !isDeleting
                }
                handleEditChange={handleConfigChange}
                isDelete={
                  isDeleting &&
                  isAuthorized(appRoles.IsConfigWriter) &&
                  isEditing &&
                  k !== "FunnelMetrics"
                }
                handleDelete={handleRemoveConfig}
              />
            </div>
          );
        } else {
          return (
            <div className="flex gap-2" key={k}>
              <div className="flex flex-col gap-1 pb-3">
                <ConfigViewer
                  srcJson={{ [k]: jsonConfig.ConfigLogic[k] }}
                  contenteditable={
                    isAuthorized(appRoles.IsConfigWriter) && isEditing
                  }
                  handleEditChange={handleConfigChange}
                />
              </div>
            </div>
          );
        }
      });
    } else {
      return (
        <>
          {Object.keys(jsonConfig).length > 0 ? (
            <ConfigViewer
              srcJson={jsonConfig}
              contenteditable={
                isAuthorized(appRoles.IsConfigWriter) && isEditing
              }
              handleEditChange={handleConfigChange}
            />
          ) : (
            "Loading Config..."
          )}
        </>
      );
    }
  };

  const renderConfigPublish = () => {
    if (isAuthorized(appRoles.IsConfigApprover) && !isEditing && !isPublished) {
      return (
        <Button
          label={"Publish Config"}
          className="rounded-md bg-yellow-01 px-4 py-1 text-blue-03"
          onClick={handleConfigPublish}
        />
      );
    }
    if (
      isAuthorized(appRoles.IsConfigWriter) &&
      !isEditing &&
      !isAuthorized(appRoles.IsConfigApprover) &&
      isEditable
    ) {
      return (
        <Button
          label={"Add to Publish Queue"}
          className="rounded-md bg-yellow-01 px-4 py-1 text-blue-03"
          onClick={handleConfigPublishQueue}
        />
      );
    }
    return <></>;
  };

  return (
    <>
      <ToastContainer
        style={{ width: "500px", fontSize: "16px" }}
        progressStyle={{ backgroundColor: "#b6de3a" }}
      />
      <div className="mb-5">
        {renderConfigs()}
        <div className="flex gap-5">
          {renderConfigPublish()}
          {isAuthorized(appRoles.IsConfigWriter) && !isEditing && isEditable ? (
            <Button
              label={"Edit Config"}
              className="rounded-md bg-yellow-01 px-4 py-1 text-blue-03"
              onClick={handleConfigEdit}
            />
          ) : (
            <></>
          )}
          {isEditing ? (
            <>
              <Button
                label={"Cancel"}
                className="rounded-md bg-yellow-01 px-4 py-1 text-blue-03"
                onClick={handleConfigCancel}
              />
              <Button
                label={"Save Config"}
                className="rounded-md bg-yellow-01 px-4 py-1 text-blue-03"
                onClick={handleConfigSave}
              />
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
    </>
  );
};

export default DisplayConfigs;
