import React, { useContext, useState } from "react";
import { ToastContainer } from "react-toastify";
import { FileMasksContext } from "context/FileMasksContext";
import { AuthContext } from "context/AuthContext";
import appRoles from "config/appRoles";
import Table from "../../components/Table";
import CreateMask from "./create-mask";
import Button from "components/partials/Button";

const FileMasks = () => {
  const { isAuthorized } = useContext(AuthContext);
  const {
    fileMasks,
    handleFileMaskChange,
    handleFileMaskRemove,
    isDeleting,
    handleRemoveMask,
  } = useContext(FileMasksContext);
  const [isCreatingNew, setIsCreatingNew] = useState(false);

  const handleNewMask = () => {
    setIsCreatingNew(true);
  };

  const renderCreateFileMask = () => {
    if (isAuthorized(appRoles.IsFileSetupWriter)) {
      return (
        <Button
          label={"New Mask"}
          className="text-blue-03 bg-yellow-01 rounded-md px-4 py-1"
          onClick={handleNewMask}
        />
      );
    }
  };

  const renderRemoveFileMask = () => {
    if (isAuthorized(appRoles.IsFileSetupWriter)) {
      return (
        <Button
          label={"Remove Mask"}
          className="text-blue-03 bg-yellow-01 rounded-md px-4 py-1"
          onClick={handleRemoveMask}
        />
      );
    }
  };

  const renderFileMaskTable = () => {
    if (fileMasks) {
      let headers;
      if (isDeleting) {
        headers = [
          {
            header: "BlobContainer",
            dataKey: "blobContainer",
            additionalCssClass: "px-4 text-center",
            contenteditable: false,
          },
          {
            header: "SchoolKey",
            dataKey: "schoolKey",
            additionalCssClass: "px-4 text-center",
            contenteditable: false,
          },
          {
            header: "ProcessType",
            dataKey: "fileProcessType",
            additionalCssClass: "px-4 text-center",
            contenteditable: false,
          },
          {
            header: "SubType",
            dataKey: "fileProcessSubType",
            additionalCssClass: "px-4 text-center",
            contenteditable: false,
          },
          {
            header: "Mask",
            dataKey: "fileMask",
            additionalCssClass: "px-4 text-center m-w-[1000px]",
            contenteditable: true,
          },
          {
            header: "Extension",
            dataKey: "extension",
            additionalCssClass: "px-4 text-center",
            contenteditable: true,
          },
          {
            header: "Delimiter",
            dataKey: "delimiter",
            additionalCssClass: "px-4 text-center",
            contenteditable: true,
          },
          {
            header: "Count",
            dataKey: "expectedFileCount",
            additionalCssClass: "px-4 text-center",
            contenteditable: true,
          },
          {
            header: "MappingID",
            dataKey: "mappingId",
            additionalCssClass: "px-4 text-center w-[256px]",
            contenteditable: false,
          },
          {
            header: "REMOVE",
            dataKey: "button",
            additionalCssClass: "px-4 text-center",
            contenteditable: false,
          },
        ];
      } else {
        headers = [
          {
            header: "BlobContainer",
            dataKey: "blobContainer",
            additionalCssClass: "px-4 text-center",
            contenteditable: false,
          },
          {
            header: "SchoolKey",
            dataKey: "schoolKey",
            additionalCssClass: "px-4 text-center",
            contenteditable: false,
          },
          {
            header: "ProcessType",
            dataKey: "fileProcessType",
            additionalCssClass: "px-4 text-center",
            contenteditable: false,
          },
          {
            header: "SubType",
            dataKey: "fileProcessSubType",
            additionalCssClass: "px-4 text-center",
            contenteditable: false,
          },
          {
            header: "Mask",
            dataKey: "fileMask",
            additionalCssClass: "px-4 text-center m-w-[1000px]",
            contenteditable: true,
          },
          {
            header: "Extension",
            dataKey: "extension",
            additionalCssClass: "px-4 text-center",
            contenteditable: true,
          },
          {
            header: "Delimiter",
            dataKey: "delimiter",
            additionalCssClass: "px-4 text-center",
            contenteditable: true,
          },
          {
            header: "Count",
            dataKey: "expectedFileCount",
            additionalCssClass: "px-4 text-center",
            contenteditable: true,
          },
          {
            header: "MappingID",
            dataKey: "mappingId",
            additionalCssClass: "px-4 text-center w-[256px]",
            contenteditable: false,
          },
        ];
      }

      return (
        <div className="flex flex-col">
          <p className="text-blue-03 pb-2 text-base font-semibold leading-5">
            File Masks
          </p>
          <Table
            tableAdditionalClassName={"w-[calc(100vw -300px)] max-w-[2000px]"}
            headers={headers}
            data={fileMasks}
            contenteditable={isAuthorized(appRoles.IsFileSetupWriter)}
            handleEditChange={handleFileMaskChange}
            handleDelete={handleFileMaskRemove}
            isDelete={isDeleting && isAuthorized(appRoles.IsFileSetupWriter)}
            buttonFunction={handleFileMaskRemove}
            buttonLabel={"Remove"}
            buttonLabelClassName={"text-blue-03 font-semibold"}
            buttonClassName="mx-auto my-0 flex cursor-pointer items-center gap-1 rounded-md border border-solid border-blue-01 bg-white px-3 py-2"
          />
        </div>
      );
    }
  };

  return (
    <>
      <ToastContainer
        style={{ width: "500px", fontSize: "16px" }}
        progressStyle={{ backgroundColor: "#b6de3a" }}
      />
      <div className="flex flex-col">
        <div className="mb-5">{renderFileMaskTable()}</div>
        {isCreatingNew ? (
          <CreateMask handleCancel={() => setIsCreatingNew(false)} />
        ) : (
          <div className="flex gap-3">
            <div>{renderCreateFileMask()}</div>
            <div>{renderRemoveFileMask()}</div>
          </div>
        )}
      </div>
    </>
  );
};

export default FileMasks;
