import React, { useContext, useEffect } from "react";
import Table from "components/Table";
import { InstitutionContext } from "context/InstitutionContext";
import { ClientContext } from "context/ClientContext";
import EmptySpaceContent from "components/partials/empty-space-content/empty-space-content";
import Icons from "assets/icons";

const Approve = () => {
  const { queuedConfigs } = useContext(InstitutionContext);
  const { setCurrentClientData, setCurrentClientId } =
    useContext(ClientContext);

  useEffect(() => {
    setCurrentClientData(null);
    setCurrentClientId(null);
    // eslint-disable-next-line
  }, [queuedConfigs]);

  const renderConfigQueueTable = () => {
    const queuedHeader = [
      {
        header: "BLOB CONTAINER",
        dataKey: "blobContainer",
        additionalCssClass: "px-4 text-left",
        contenteditable: false,
      },
      {
        header: "SCHOOLKEY",
        dataKey: "schoolKey",
        additionalCssClass: "px-4 text-left",
        contenteditable: false,
      },
      {
        header: "STATUS",
        dataKey: "status",
        additionalCssClass: "px-14 text-left",
        contenteditable: false,
      },
      {
        header: "CONFIG TYPE",
        dataKey: "configType",
        additionalCssClass: "px-4 text-center",
        contenteditable: false,
      },
      {
        header: "PROCESS TYPE",
        dataKey: "fileProcessType",
        additionalCssClass: "px-14 text-left",
        contenteditable: false,
      },
      {
        header: "SUB TYPE",
        dataKey: "fileProcessSubType",
        additionalCssClass: "px-14 text-left",
        contenteditable: false,
      },
      {
        header: "QUEUED BY",
        dataKey: "queuedBy",
        additionalCssClass: "px-14 text-left",
        contenteditable: false,
      },
      {
        header: "QUEUED DATETIME",
        dataKey: "configQueueDateTime",
        additionalCssClass: "px-4 text-center",
        contenteditable: false,
      },
      {
        header: "VIEW",
        dataKey: "button",
        additionalCssClass: "px-4 text-center",
        contenteditable: false,
      },
    ];

    if (queuedConfigs.length > 0) {
      return (
        <Table
          headers={queuedHeader}
          data={queuedConfigs}
          buttonLabel={"VIEW"}
          buttonLabelClassName={"text-blue-03 font-normal"}
          buttonClassName="flex rounded-md bg-yellow-01 py-1 px-4 text-blue-03"
          buttonLinkTo={`/{clientId}/{configType}/{parentConfigId}/versions/{configVersionId}/`}
        ></Table>
      );
    } else {
      return (
        <EmptySpaceContent
          image={<Icons.EmptyImage className="h-[210px] w-[210px]" />}
          text="No Configs To Approve"
        />
      );
    }
  };

  return <div className="px-5 pt-5">{renderConfigQueueTable()}</div>;
};

export default Approve;
