import React, { useRef, useEffect, useState, useCallback } from "react";
import { useForm, FormProvider } from "react-hook-form";
import ReactDOM from "react-dom";
import classNames from "classnames";

import CustomButton from "components/partials/custom-button/custom-button";
import Heading from "components/partials/heading/heading";

import { NEW_BATCH_FORM_VALIDATION_SCHEMA } from "pages/list-plus/new-batch/new-batch-form/new-batch-form.constants";

const MODAL_CONTAINER = document.getElementById("modal-container");

const FormModal = ({
  headerText,
  onSubmitCallback,
  submitButtonLabel = "OK",
  submitButtonIcon,
  children,
  onCloseCallback,
  text,
  onCloseModal = () => {},
}) => {
  const [isSaveBtnDisabled, setIsSaveBtnDisabled] = useState(true);

  //form
  const {
    control,
    handleSubmit,
    formState: { errors, defaultValues, dirtyFields, isSubmitted },
    reset,
  } = useForm({
    mode: "onChange",
    defaultValues: {
      name: "",
      description: "",
    },
    resolver: NEW_BATCH_FORM_VALIDATION_SCHEMA,
  });

  useEffect(() => {
    setIsSaveBtnDisabled(
      Object.keys(dirtyFields).length !== Object.keys(defaultValues).length ||
        !!Object.keys(errors).length
    );
  }, [Object.keys(dirtyFields).length]);

  //close after form is submitted
  useEffect(() => {
    !!isSubmitted && onClose();
    reset();
  }, [isSubmitted]);

  //modal
  const onClose = useCallback(() => {
    ReactDOM.unmountComponentAtNode(MODAL_CONTAINER);
    if (onCloseCallback) {
      onCloseCallback();
    }
  }, [onCloseCallback]);

  const modalWrapperRf = useRef();
  const modalInnerRf = useRef();

  const handleClickOutside = (e) => {
    if (
      !modalWrapperRf.current?.contains(e.target) &&
      e.target !== modalInnerRf.current
    ) {
      onClose();
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <FormProvider {...{ control, errors }}>
      <div
        className="fixed inset-0 z-[701] h-screen max-h-screen overflow-hidden"
        ref={modalWrapperRf}
      >
        <div className="absolute inset-0 !bg-gray-07 opacity-60" />
        <div className="absolute left-1/2 top-2/4 -translate-x-1/2 -translate-y-1/2">
          <div
            ref={modalInnerRf}
            className={classNames(
              "flex flex-col justify-between overflow-hidden rounded-md bg-white p-8 mobile:w-[330px] tablet:w-[500px]"
            )}
          >
            <Heading
              variant="h1"
              customClass="mobile:text-center tablet:text-left"
            >
              {headerText}
            </Heading>
            {text && (
              <p className="mt-1 text-lg mobile:text-center tablet:text-left">
                {text}
              </p>
            )}
            <form
              onSubmit={handleSubmit(onSubmitCallback)}
              className="flex w-full flex-col"
            >
              {children}
              <div className="mt-6 flex justify-end space-y-2 mobile:flex-col-reverse tablet:flex-row tablet:space-y-0">
                <CustomButton
                  onClick={() => {
                    onClose();
                    onCloseModal();
                  }}
                >
                  <span className="text-blue-03">Cancel</span>
                </CustomButton>
                <CustomButton
                  variant="primary"
                  disabled={isSaveBtnDisabled}
                  type="submit"
                >
                  {!!submitButtonIcon && submitButtonIcon}
                  {submitButtonLabel}
                </CustomButton>
              </div>
            </form>
          </div>
        </div>
      </div>
    </FormProvider>
  );
};

export default FormModal;
