import apiRoot from "api/apiRoot";

import store from "state/store";

import {
  setBatchData,
  setBatchListSources,
  setIsBatchLoading,
  setSegmentGroup,
  setSummaryData,
} from "state/batch/batch.slice";

export const useBatchThunk = () => {
  return {
    getBatchDetails: async (batchId, clientId) => {
      store.dispatch(setIsBatchLoading(true));

      const sourcesDictionary = await apiRoot
        .get("/api/dictionary/FILE_LIST_SOURCES")
        .catch((err) =>
          console.error("List sources dictionary request failed", err)
        );

      store.dispatch(setBatchListSources(sourcesDictionary?.data));

      const segmentDictionary = await apiRoot
        .get("/api/dictionary/FILE_SEGMENT_GROUPS")
        .catch((err) =>
          console.error("Segment group dictionary request failed", err)
        );

      store.dispatch(setSegmentGroup(segmentDictionary?.data));

      const batchData = await apiRoot
        .get(`/api/clients/${clientId}/file-batches/${batchId}`)
        .catch((err) => console.error("Batch details request failed", err));

      store.dispatch(setBatchData(batchData?.data));
    },
    getSummary: async () => {
      const { batchData, batchListSources } = store.getState().batch;

      apiRoot
        .get(
          `/api/clients/${batchData?.clientId}/file-batches/${batchData?.id}/summary`
        )
        .then((res) => {
          const data = batchListSources.map((constEl) => {
            const intersectedEl = res?.data?.listSources?.find(
              (summEl) => summEl.name === constEl.name
            );

            return {
              description: constEl?.description,
              count: intersectedEl.recordCount,
            };
          });

          data.push({
            description: "Total records",
            count: res?.data?.recordCount,
          });

          store.dispatch(setSummaryData(data));
        })
        .catch((err) => console.error("Summary request failed", err))
        .finally(() => {
          store.dispatch(setIsBatchLoading(false));
        });
    },
    createBatch: async (formData, navigate, clientId) => {
      apiRoot
        .post(`/api/clients/${clientId}/file-batches`, formData)
        .then((res) => {
          store.dispatch(setBatchData(res.data));
          navigate(
            `/clients/${res?.data?.clientId}/list-plus/list-uploads/batch/${res?.data?.id}`
          );
        })
        .catch((err) => console.error("Batch creation request failed", err));
    },
    processOrPublishBatch: async (status) => {
      store.dispatch(setIsBatchLoading(true));

      const { batchData } = store.getState().batch;

      apiRoot
        .patch(
          `/api/clients/${batchData?.clientId}/file-batches/${batchData?.id}`,
          { status }
        )
        .then((res) => {
          store.dispatch(setBatchData(res.data));
        })
        .catch((err) => console.error(`${status} Batch request failed`, err));
    },
    removeBatch: async (navigate) => {
      store.dispatch(setIsBatchLoading(true));

      const { batchData } = store.getState().batch;
      apiRoot
        .delete(
          `/api/clients/${batchData?.clientId}/file-batches/${batchData?.id}`
        )
        .then((res) => {
          navigate(`/clients/${res?.data?.clientId}/list-plus/list-uploads`);
        })
        .catch((err) => console.error("Batch removing request failed", err));
    },
  };
};
