import { env } from "env";

export const msalConfig = {
  auth: {
    clientId: env.REACT_APP_AUTH_CLIENTID,
    authority: env.REACT_APP_AUTH_AUTHORITY,
    redirectUri: env.REACT_APP_AUTH_LOGIN_REDIRECT_URI,
    postLogoutRedirectUri: env.REACT_APP_AUTH_LOGOUT_REDIRECT_URI,
  },
  cache: {
    cacheLocation: "sessionStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: true, // Set this to "true" if you are having issues on IE11 or Edge
  },
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
  scopes: ["User.Read"],
};
