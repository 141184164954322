import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

export const NEW_BATCH_FORM_VALIDATION_SCHEMA = yupResolver(
  yup.object({
    name: yup.string().required("Please provide a name of a new batch"),
    description: yup
      .string()
      .required("Please provide a description for a new batch"),
  })
);
