import React, { useContext } from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../config/authConfig";
import Icons from "../assets/icons";
import Button from "../components/partials/Button";
import { AuthContext } from "context/AuthContext";

const UnAuthenticatedApp = () => {
  localStorage.clear();

  const { setJwt, fetchUser } = useContext(AuthContext);
  const { instance } = useMsal();

  function handleLogin() {
    instance.loginPopup(loginRequest).then((res) => {
      if (res?.idToken) {
        setJwt(res.idToken);
        fetchUser();
      }
    });
  }

  return (
    <div className="bg-blue-03 flex min-h-screen min-w-full flex-col items-center justify-center pb-60 text-center">
      <div className="mb-28">
        <Icons.AtomLogo className="scale-125" />
      </div>
      <Button
        label={"Log In"}
        onClick={() => handleLogin()}
        className="text-blue-03 bg-yellow-01 cursor-pointer rounded-md border-none px-24 py-5 text-2xl font-semibold"
      />
    </div>
  );
};

export default UnAuthenticatedApp;
