export const getSortingParamsQuery = (sortingParams) => {
  let finalQuery = "";

  for (let i = 0; i < sortingParams.length; i++) {
    const sortDir = sortingParams[i].desc ? "desc" : "asc";

    finalQuery += `&sort=${sortingParams[i].id},${sortDir}`;
  }

  return finalQuery;
};
