import React, { useContext, useEffect } from "react";
import { Outlet, NavLink, useParams } from "react-router-dom";
import uniqid from "uniqid";

import PrivateNavLink from "components/PrivateNavLink";

import { ClientContext } from "context/ClientContext";

import {
  getNavItemStyles,
  getSubNavItemStyles,
  getSchoolSettingsStyles,
} from "pages/client-home/helpers/get-styles";

import ClientHomeRoutes from "pages/client-home/client-home.routes";

import { SIDEBAR_ITEMS } from "pages/client-home/client-home.constants";

import Icons from "assets/icons";

const ClientHomePage = () => {
  const { clientid } = useParams();

  const { currentClientData, setCurrentClientId, currentClientId } =
    useContext(ClientContext);

  useEffect(() => {
    if (!currentClientId) {
      setCurrentClientId(clientid);
    }
    // eslint-disable-next-line
  }, []);

  const renderLinks = () => {
    if (currentClientData && currentClientData.blobContainer) {
      return (
        <>
          {SIDEBAR_ITEMS.map((app) => {
            return (
              <React.Fragment key={uniqid()}>
                <PrivateNavLink
                  to={app.route}
                  className={({ isActive }) => getNavItemStyles(isActive)}
                  key={app.value}
                  allowedAppRoles={app.allowedAppRoles}
                >
                  {app.label}
                </PrivateNavLink>
                {!!app?.subnav?.length &&
                  app.subnav.map((subNav) => (
                    <PrivateNavLink
                      to={subNav.route}
                      className={({ isActive }) =>
                        getSubNavItemStyles(isActive)
                      }
                      key={app.route}
                      allowedAppRoles={app.allowedAppRoles}
                    >
                      <Icons.UploadBatch
                        aria-label="Upload batch"
                        height="100%"
                        width="25"
                        customClass="ml-3"
                      />

                      {subNav.label}
                    </PrivateNavLink>
                  ))}
              </React.Fragment>
            );
          })}
          {renderSchoolSettings("School settings", true)}
        </>
      );
    } else if (currentClientData && !currentClientData.blobContainer) {
      return renderSchoolSettings("Assign Container", false);
    } else {
      return renderSchoolSettings("Onboard", false);
    }
  };

  const renderSchoolSettings = (label, borderTop) => (
    <NavLink
      to="school-settings"
      className={({ isActive }) => getSchoolSettingsStyles(isActive, borderTop)}
      key="schoolsettings"
    >
      <Icons.Settings />
      {label}
    </NavLink>
  );

  return (
    <>
      <div className="flex flex-col border-b border-solid border-gray-02 pb-6 pl-10 pt-4">
        <div className="text-xs leading-4 tracking-wide text-gray-04">
          CURRENTLY VIEWING
        </div>
        <div className="mt-1 text-3xl font-black leading-7 text-blue-03">
          {currentClientData
            ? `${currentClientData.institutionName} - ${currentClientData.blobContainer} - ${currentClientData.schoolKey}`
            : "Loading..."}
        </div>
      </div>
      <div className="flex w-full flex-grow">
        <div className="flex  min-w-[255px] flex-col">{renderLinks()}</div>
        <div className="flex min-h-[calc(100vh-157px)] w-full flex-col border-l border-gray-02 px-8 pb-8 pt-5">
          <ClientHomeRoutes />
          <Outlet />
        </div>
      </div>
    </>
  );
};

export default ClientHomePage;
