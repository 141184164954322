import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";

import Onboard from "components/Onboard";
import PrivateRoutes from "components/PrivateRoutes";

import FileSplit from "pages/file-split/file-split";
import MoveFiles from "pages/file-move/file-move";
import Config from "pages/config/config";
import FileMasks from "pages/file-masks/file-masks";
import FileMappings from "pages/file-mappings/file-mappings";
import ListUploads from "pages/list-plus/list-uploads/list-uploads";
import Batch from "pages/list-plus/batch/batch";
import TranscriptSummarization from "pages/transcript-summarization/transcript-summarization";
import SchoolSettings from "pages/school-settings/school-settings";

import appRoles from "config/appRoles";

const ClientHomeRoutes = () => (
  <Routes>
    <Route
      element={<PrivateRoutes allowedAppRoles={appRoles.IsFileSplitReader} />}
    >
      <Route path="file-split/*" element={<FileSplit />} />
    </Route>
    <Route
      element={<PrivateRoutes allowedAppRoles={appRoles.IsFileMoverReader} />}
    >
      <Route exact path="move-files/*" element={<MoveFiles />} />
    </Route>
    <Route
      element={<PrivateRoutes allowedAppRoles={appRoles.IsConfigReader} />}
    >
      <Route exact path="config/*" element={<Config />}></Route>
    </Route>
    <Route
      element={<PrivateRoutes allowedAppRoles={appRoles.IsFileSetupReader} />}
    >
      <Route exact path="file-masks/*" element={<FileMasks />} />
      <Route exact path="file-mappings/*" element={<FileMappings />} />
    </Route>
    <Route element={<PrivateRoutes allowedAppRoles={appRoles.IsAtomUser} />}>
      <Route exact path="onboard/*" element={<Onboard />} />
    </Route>
    <Route
      element={<PrivateRoutes allowedAppRoles={appRoles.IsListPlusReader} />}
    >
      <Route
        path="list-plus/*"
        element={<Navigate from="list-plus" to="list-uploads" />}
      />
      <Route exact path="list-plus/list-uploads/*" element={<ListUploads />} />
      <Route
        exact
        path="list-plus/list-uploads/batch/:batchId/*"
        element={<Batch />}
      />
    </Route>
    <Route
      element={
        <PrivateRoutes
          allowedAppRoles={appRoles.IsAiTranscriptSummarizationReader}
        />
      }
    >
      <Route
        exact
        path="summarize/*"
        element={<TranscriptSummarization isClientLevel={true} />}
      />
    </Route>
    <Route element={<PrivateRoutes allowedAppRoles={appRoles.IsAtomUser} />}>
      <Route exact path="school-settings/*" element={<SchoolSettings />} />
    </Route>
  </Routes>
);

export default ClientHomeRoutes;
