import React from "react";

import Icons from "assets/icons";

const Loader = () => (
  <div className="flex h-full w-full items-center justify-center">
    <Icons.Spinner customClass="w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-900" />
    <div className="loading loading:after">Loading</div>
  </div>
);

export default Loader;
